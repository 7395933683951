import React, { useContext } from 'react';
import { Form, Col } from "react-bootstrap";
import subjectContext from '../../context/subject/subjectContext';

export default function SectionSelectInput({ value, handleChange }) {
  const {subject} = useContext(subjectContext);

  return (
    <Form.Group as={Col} xs={12} lg={6} controlId="section">
      <Form.Label className="font-weight-bold">Sección</Form.Label>
      <Form.Control value={value} as="select" className="borderinput" onChange={handleChange}>
        <option value="">Seleccioná una . . .</option>
        {
          subject.all.find(s => s._id === subject.current)?.type &&
          <>
            <option value="partial1">Parcial 1</option>
            <option value="partial2">Parcial 2</option>
            <option value="partial3">Parcial 3</option>
            <option value="final">Final</option>
          </>
        }
        <option value="free">Libre</option>
      </Form.Control>
    </Form.Group>
  );
}