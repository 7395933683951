import React, { useContext } from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import subjectContext from '../../context/subject/subjectContext';

export default function SectionSelect({ title='Archivos', section, setSection }) {
  const {subject} = useContext(subjectContext);
  return (
    <Card className="shadowcards">
      <Card.Header className="m-0 pb-1 fondotitle">
        <h4 className="text-white rounded-pill">Elegi tus {title}</h4>
      </Card.Header>
      <Card.Body>
        <Row>
          {
            subject.all.find(s => s._id === subject.current)?.type &&
            <>
              <Col>
                <Button
                  variant={section === 'Parcial 1' ? "warning" : "light"}
                  onClick={() => setSection('Parcial 1')}
                  className="px-3 my-2 my-md-0 textcolor font-weight-bold"
                >
                  Parcial 1
                </Button>
              </Col>
              <Col>
                <Button
                  variant={section === 'Parcial 2' ? "warning" : "light"}
                  onClick={() => setSection('Parcial 2')}
                  className="px-3 my-2 my-md-0 textcolor font-weight-bold"
                >
                  Parcial 2
                </Button>
              </Col>
              <Col>
                <Button
                  variant={section === 'Parcial 3' ? "warning" : "light"}
                  onClick={() => setSection('Parcial 3')}
                  className="px-3 my-2 my-md-0 textcolor font-weight-bold"
                >
                  Parcial 3
                </Button>
              </Col>
              <Col>
                <Button
                  variant={section === 'Final' ? "warning" : "light"}
                  onClick={() => setSection('Final')}
                  className="px-3 my-2 my-md-0 textcolor font-weight-bold"
                >
                  Final
                </Button>
              </Col>
            </>
          }
          <Col>
            <Button
              variant={section === 'Libre' ? "warning" : "light"}
              onClick={() => setSection('Libre')}
              className="px-3 my-2 my-md-0 textcolor font-weight-bold"
            >
              Libre
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}