import React, { useReducer } from 'react';
import userContext from './userContext';
import userReducer from './userReducer';
import axios from 'axios';

export default function UserState(props) {
  const init = initialUser => {
    if (initialUser.token) {
      axios({
        method: 'post',
        url: '/users/authToken', 
        headers: { 'x-auth-token': initialUser.token },
        data: {
          token: initialUser.token,
          username: initialUser.username
        }
      }).then(response => {
        if(response.status === 200) {
          return iniciarSesion(response.data);
        } else {
          console.log('Respuesta de red OK pero respuesta HTTP no OK.', response.data);
          return {name: '', username: '', token: '', type: 0, surname: ''};
        }
      }).catch(error => console.log('Hubo un problema con la petición Fetch:' + error.message));
    }
  },

  initialState = JSON.parse(localStorage.getItem('CampusEnzoUser')) || {name: '', username: '', token: '', type: 0, surname: ''},

  // Dispatch para ejecutar las acciones
  [state, dispatch] = useReducer(userReducer, initialState, init),

  // Acciones del CRUD

  // iniciar sesión
  iniciarSesion = user => 
    dispatch({
      type: 'INICIAR_SESION',
      payload: user
    }),

  // actualizar usuario
  actualizarUsuario = user => dispatch({ type: 'ACTUALIZAR_USUARIO', payload: user }),

  // cerrar sesión
  cerrarSesion = () => dispatch({ type: 'CERRAR_SESION' });

  return (
    <userContext.Provider
      value={{ 
        user: state,
        iniciarSesion,
        actualizarUsuario,
        cerrarSesion,
      }}
    >
      { props.children }
    </userContext.Provider>
  );
}