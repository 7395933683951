import React, { useContext, useState } from "react";
import { faUser, faFileAlt, faFilePdf, faPenSquare, faCalendarCheck, faNewspaper, faEye, faBook, faClock } from "@fortawesome/free-solid-svg-icons";
import { Image, Container, Tab, Row, Nav, Col } from "react-bootstrap";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdminNotification from "../Admin/AdminNotification";
import AdminUserRequest from "../Admin/AdminUserRequest";
import userContext from '../../context/user/userContext';
import noaccessadmin from "../Images/noaccessadmin.gif";
import AdminExcercises from "../Admin/AdminExcercises";
import AdminComplement from "../Admin/AdminComplement";
import adminMobile from "../Images/adminmobile.png";
import AdminHomepage from "../Admin/AdminHomepage";
import AdminStudents from "../Admin/AdminStudents";
import AdminSlides from "../Admin/AdminSlides";
import AdminEmail from "../Admin/AdminEmail";
import AdminTests from "../Admin/AdminTests";
import AdminUsers from "../Admin/AdminUsers";
import AdminViews from "../Admin/AdminViews";
import admin from "../Images/admin.png";
import "../Admin/Admin.css";

export default function Teacher() {
  const { user } = useContext(userContext),
  { subject } = useContext(subjectContext),
  [section, setSection] = useState('Users');

  if (user && user.type === 4)
  return (
    <>
      <Image src={admin} fluid alt="" className="d-none d-md-block"/>
      <Image src={adminMobile} fluid alt="" className="d-block d-md-none" />
      <Container className="text-center text-lg-left py-5 ">
        <Tab.Container defaultActiveKey="Users">
          <Row>
            <Col xs={12} lg={3} className="mb-4 mb-md-0">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="Users" className="textblue" onClick={() => setSection("Users")}>
                    <FontAwesomeIcon icon={faUser} className="mr-2 mt-1 fa-lg" />Usuarios
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Students" className="textblue" onClick={() => setSection("Students")}>
                    <FontAwesomeIcon icon={faUser} className="mr-2 mt-1 fa-lg" />Mis Alumnos
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Subjects" onClick={() => setSection("Subjects")}>
                    <FontAwesomeIcon icon={faBook} className="mr-2 mt-1 fa-lg" />Materias
                  </Nav.Link>
                </Nav.Item>
                {
                  subject?.current &&
                  <>
                    <h5 className="my-3 mx-auto">{subject.all.find(s => s._id === subject.current)?.name}</h5>
                    <Nav.Item>
                      <Nav.Link eventKey="Slides" className="textblue" onClick={() => setSection("Slides")}>
                        <FontAwesomeIcon icon={faFilePdf} className="mr-2 mt-1 fa-lg" />Diapositivas
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Excercises" onClick={() => setSection("Excercises")}>
                        <FontAwesomeIcon icon={faPenSquare} className="mr-2 mt-1 fa-lg" />Ejercicios y Soluciones
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Complements" onClick={() => setSection("Complements")}>
                        <FontAwesomeIcon icon={faFileAlt} className="mr-2 mt-1 fa-lg" />Complementos
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Tests" onClick={() => setSection("Tests")}>
                        <FontAwesomeIcon icon={faCalendarCheck} className="mr-2 mt-1 fa-lg" />Evaluaciones
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Views" onClick={() => setSection("Views")}>
                        <FontAwesomeIcon icon={faEye} className="mr-2 mt-1 fa-lg" />Visualizaciones
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Notifications" onClick={() => setSection("Notifications")}>
                        <FontAwesomeIcon icon={faNewspaper} className="mr-2 mt-1 fa-lg" />Notificaciones
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Request" onClick={() => setSection("Request")}>
                        <FontAwesomeIcon icon={faClock} className="mr-2 mt-1 fa-lg" />Solicitudes
                      </Nav.Link>
                    </Nav.Item>
                  {/*   <Nav.Item>
                      <Nav.Link eventKey="Email" onClick={() => setSection("Email")}>
                        <FontAwesomeIcon icon={faEnvelope} className="mr-2 mt-1 fa-lg" />Email
                      </Nav.Link>
                    </Nav.Item> */}
                  </>
                }
              </Nav>
            </Col>
            <Col xs={12}  lg={9} className="text-center border-0">
              <Tab.Content>
                <Tab.Pane eventKey="Users">
                  {section === "Users" && <AdminUsers />}
                </Tab.Pane>
                <Tab.Pane eventKey="Students">
                  {section === "Students" && <AdminStudents />}
                </Tab.Pane>
                <Tab.Pane eventKey="Subjects">
                  {section === "Subjects" && <AdminHomepage />}
                </Tab.Pane>
                {
                  subject?.current &&
                  <>
                    <Tab.Pane eventKey="Slides">
                      {section === "Slides" && <AdminSlides />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Excercises">
                      {section === "Excercises" && <AdminExcercises />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Complements">
                      {section === "Complements" && <AdminComplement />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Tests">
                      {section === "Tests" && <AdminTests />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Views">
                      {section === "Views" && <AdminViews />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Notifications">
                      {section === "Notifications" && <AdminNotification />}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Request">
                      {section === "Request"  && <AdminUserRequest /> }
                    </Tab.Pane>
                    <Tab.Pane eventKey="Email">
                      {section === "Email" && <AdminEmail />}
                    </Tab.Pane>
                  </>
                }
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
  return (
    <div className="text-center paddingalert" >
      <Image src={noaccessadmin} fluid alt="" className="shadow-lg rounded" />
    </div>
  );
}