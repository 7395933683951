import React, { useState, useEffect, useContext } from "react";
import { faLongArrowAltLeft, faLongArrowAltRight, faClock, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Card, Form, Button, Image, Alert } from "react-bootstrap";
import { faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountdownTimer from "react-component-countdown-timer";
import { useParams, useHistory } from "react-router-dom";
import userContext from '../../context/user/userContext';
import noaccess from '../Images/noaccess.gif';
import Loading from '../Loading/Loading';
import Option from "./Option";
import Review from "./Review";
import axios from 'axios';
import "./Test.css";

export default function Test() {
  const [test, setTest] = useState({ title: '', section: '', questions: [{question: '', options: ['','','','']}] }),
  [result, setResult] = useState({ finished: false, result: {} }),
  [showNoAccess, setShowNoAccess] = useState(false),
  { subject } = useContext(subjectContext),
  [errorMsg, setErrorMsg] = useState(''),
  [loading, setLoading] = useState(true),
  [sended, setSended] = useState(false),
  [answers, setAnswers] = useState([]),
  { user } = useContext(userContext),
  [timer, setTimer] = useState(-1),
  [page, setPage] = useState(0),
  history = useHistory(),
  { id } = useParams(),

  getTest = () => {
    axios({
      method: 'get',
      url: `/tests/id/${id}`, 
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current }
    }).then(response => {
      setTest(response.data);
    }).catch(() => {
      setLoading(true);
      setShowNoAccess(true);
      getTest();
    });
  },

  getResult = id => {
    axios({
      method: 'get',
      url: `/history/id/${id}`, 
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current }
    }).then(response => {
      setResult({ finished: true, result: response.data });
    }).catch(() => {
      setLoading(true);
      getResult();
    });
  },

  changeAnswer = option => {
    localStorage.setItem(`answers-${id}`, JSON.stringify(answers));
    setAnswers(prev => {
      prev[page] = option;
      return prev;
    });
    setErrorMsg('');
  },

  sendTest = (validate = true) => {
    let aux = answers;
    if (sended) {setErrorMsg('Ya rendiste esta prueba'); return;}
    if (validate) {
      if (answers.length < test.questions.length) {setErrorMsg('Revisa que hayas respondido todas las preguntas antes de enviar'); return;}
      answers.forEach(a => { if (!a) {setErrorMsg('Revisa que hayas respondido todas las preguntas antes de enviar'); return;} });
    } else {
      test.questions.forEach((q, i) => {
        let b = false;
        for (let j = 0; j < 4; j++) {
          if (answers[i] === q.options[j]) {
            b = true;
            i = 4;
          }
        }
        if (!b) aux.splice(i, 0, "");
      });
    }
    setAnswers(aux);
    axios({
      method: 'post',
      url: `/history/create`, 
      headers: { 'x-auth-token': user.token },
      data: { test: id, answers: aux, subject: subject.current }
    }).then(response => {
      localStorage.removeItem(`start-${id}`);
      localStorage.removeItem(`answers-${id}`);
      getResult(response.data._id);
    }).catch(() => {
      setErrorMsg('Hubo un error. Reintentando');
      sendTest(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    if (user && user.token && user.plan) {
      if (test && test.title) {
        if (user.plan[test.section] !== 2) {
          setShowNoAccess(true);
          setLoading(false);
        } else {
          setShowNoAccess(false);
          axios({
            method: 'post',
            url: `/history/validate`, 
            headers: { 'x-auth-token': user.token },
            data: { test: id }
          }).then(response => {
            if (response.data.length > 0) {
              setSended(true);
              setErrorMsg('Ya rendiste esta prueba. Podés consultar el resultado en tu historial');
              setTimeout(() => history.push('/history'), 2000);
            }
            else {
              let localData = localStorage.getItem(`start-${id}`) || '', start;
              setAnswers(JSON.parse(localStorage.getItem(`answers-${id}`)) || []);
              if (localData) {
                start = new Date(JSON.parse(localData));
              } else {
                start = new Date();
                localStorage.setItem(`start-${id}`, JSON.stringify(start));
              }
              let diff = (new Date() - start) / 1000;
              if (diff < 3600) {
                setTimer(3600 - diff);
              } else {
                setErrorMsg('El plazo para resolver el cuestionario ya venció');
                sendTest(false);
              }  
            }
          }).catch(() => setErrorMsg('Hubo un error, intentá de nuevo'));
          setLoading(false);
        }
      } else getTest();
    }
    // eslint-disable-next-line
	}, [user, test]);

  return (
    <>
      {
        loading ? <Loading /> :
        showNoAccess ?        
        <div className="text-center">
          <Image src={noaccess} fluid alt="" className="shadow-lg rounded" />
        </div> :
        !result.finished ?
        <div className="backgroundlogin py-4">
        <Container className="py-5 text-center">
          <div className="shadowcard text-right textcolor font-weight-bold mb-0 d-inline-flex">
            <FontAwesomeIcon icon={faClock} className="textcolor mr-2 mt-1 " />
            {timer > -1 && <CountdownTimer count={timer} responsive hideDay onEnd={() => sendTest(false)} />}
          </div>
          <div className="shadowcard text-right">
            <Row className="justify-content-end mr-1 py-1 textcolor font-weight-bold mt-1"> 
              {/* Indicadores en parte superior */
                test.questions.map((q, i) => i <= page ?
                  <FontAwesomeIcon key={i} icon={faDotCircle} className="textcolor mx-1" /> :
                  <FontAwesomeIcon key={i} icon={faCircle} className="textcolor mx-1" />
                )
              }
            </Row>
          </div>
          <Card className="shadowcard shadowcards">
            <Form>
              <Card.Header as="h5" className="textcolor py-4 videobackground">
                {test.questions[page].question}
              </Card.Header>
              <Card.Body>
                {
                  test.questions.map((q, i) => <Option options={q.options} changeAnswer={changeAnswer} visible={i === page} key={i} />)
                }
                <Row className="mt-3">
                  <Col xs={6} className="text-left">
                    <Button 
                      className="text-decoration-none pb-0 pt-2 px-2 d-inline-flex"
                      variant="link" 
                      onClick={() => setPage(page - 1)}
                      disabled={page < 1}
                    >
                      <FontAwesomeIcon icon={faLongArrowAltLeft} className="textcolor mr-2 mt-1 fa-lg" />
                      <p className="textcolor font-weight-bold">Anterior</p>
                    </Button>
                  </Col>
                  <Col xs={6} className="text-right">
                    <Button 
                      className="text-decoration-none pb-0 pt-2 px-2 d-inline-flex"
                      variant="link"
                      onClick={() => setPage(page + 1)}
                      disabled={page >= test.questions.length - 1}
                    >
                      <p className="textcolor font-weight-bold">Siguiente</p>
                      <FontAwesomeIcon icon={faLongArrowAltRight} className="textcolor ml-2 mt-1" />
                    </Button>
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  <Button className="textcolor text-decoration-none font-weight-bold pb-0 pt-2 px-2" variant="link" onClick={() => sendTest()}>
                    Terminar y enviar <FontAwesomeIcon icon={faPaperPlane} className="textcolor ml-2 mt-1" />
                  </Button>
                </Row>
                <Row className="justify-content-center mt-3">
                  <Alert variant="danger" dismissible show={errorMsg !== ''} onClose={() => setErrorMsg('')}>
                    {errorMsg}
                  </Alert>
                </Row>
              </Card.Body>
            </Form>
          </Card>
        </Container>
        </div> :
        <Review result={{ ...result.result, answers }} />        
      }
    </>
  );
}