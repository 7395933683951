import React, { useState, useContext } from "react";
import { faUserEdit, faTimes, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalChangesProfile({ show, setShow }) {
  const emptyForm = { password: '', newPassword: '', newPassword1: '' },
  {user, iniciarSesion} = useContext(userContext),
  [errorMsg, setErrorMsg] = useState(''),
  [form, setForm] = useState(emptyForm),
  
  handleChange = e => {
    setForm({...form, [e.target.id]: e.target.value});
  },

  handleSubmit = e => {
    e.preventDefault();
    if (!form.password || !form.newPassword || !form.newPassword1) {setErrorMsg('Complete todos los campos'); return;};
    if (form.newPassword.length < 8) {setErrorMsg('La nueva contraseña es demasiado corta'); return;};
    if (form.newPassword !== form.newPassword1) {setErrorMsg('Las contraseñas no coinciden'); return;};
    axios.post('/users/login', { username: user.username, password: form.password })
    .then(response => {
      if(response.status === 200) {
        if (form.newPassword === form.password) {setErrorMsg('La nueva contraseña no puede ser igual a la anterior'); return;};
        axios({
          method: 'post',
          url: `/users/edit/${user._id}`, 
          headers: { 'x-auth-token': user.token },
          data: { password: form.newPassword }
        }).then(response => {
          if(response.status === 200) {
            iniciarSesion({ ...user, password: form.newPassword});
            Swal.fire({
              title: 'Hecho!', 
              text: `Contraseña Actualizada!`, 
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            });
            handleClose();
          } else {
            setErrorMsg('Hubo un problema con la solicitud, intenta de nuevo más tarde');
          };
        });
      }
    }).catch(() => setErrorMsg('Contraseña anterior incorrecta'));
  },

  handleClose = () => {
    setShow(false);
    setForm(emptyForm);
  },
  handleShow = () => setShow(true);

  return (
    <>
      <Button variant="light" className="textcolor py-2 px-3 px-lg-5" onClick={handleShow}>
        <FontAwesomeIcon icon={faUserEdit} className="mr-2 mt-1 fa-lg" />Cambiar Contraseña
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="backgroundlogin textcolor"
      >
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            Cambiar Contraseña
          </Modal.Title>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="password">
                  <Form.Label className="font-weight-bold">Contraseña</Form.Label>
                  <Form.Control value={form.password} type="password" placeholder="Contraseña Actual" className="borderinput" onChange={handleChange} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="newPassword">
                  <Form.Label className="font-weight-bold">Nueva contraseña</Form.Label>
                  <Form.Control value={form.newPassword} type="password" placeholder="Ingresa tu nueva contraseña" className="borderinput" onChange={handleChange} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="newPassword1">
                  <Form.Label className="font-weight-bold">Repite tu nueva contraseña</Form.Label>
                  <Form.Control value={form.newPassword1} type="password" placeholder="Repite tu nueva contraseña" className="borderinput" onChange={handleChange} />
                </Form.Group>
              </Form.Row>
              <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
                {errorMsg}
              </Alert>
              <Row className="text-center pt-4 pb-3 justify-content-center">
                <Button variant="light" type="submit" className="mr-5 shadowcards textcolor">
                  <FontAwesomeIcon icon={faEdit} className="mr-2 textcolor" />Editar
                </Button>
                <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
                </Button>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}