import React, { useState, useEffect, useContext } from "react";
import { faCaretRight, faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button, Card, Image } from "react-bootstrap";
import userContext from '../../context/user/userContext';
import SearchBar from '../SearchBar/SearchBar';
import ModalExcercise from './ModalExcercise';
import noresult from "../Images/noresult.gif";
import SectionSelect from "./SectionSelect";
import VisibleSwitch from "./VisibleSwitch";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function AdminExcercises() {
  const [selectedItem, setSelectedItem] = useState({_id: ''}),
  [section, setSection] = useState('Libre'),
  [filtered, setFiltered] = useState([]),
  {subject} = useContext(subjectContext),
  source = axios.CancelToken.source(),
  [show, setShow] = useState(false),
  {user} = useContext(userContext),
  [files, setFiles] = useState([]),

  translate = () => {
    switch(section) {
      case "Parcial 1": return 'partial1';
      case "Parcial 2": return 'partial2';
      case "Parcial 3": return 'partial3';
      case "Libre":     return 'free';
      default:          return 'final';
    }
  },
  
  getFiles = () => {
    axios({
      method: 'post',
      url: `/files/section/${translate()}-excercise`,
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current },
      cancelToken: source.token
    }).then(response => {
      if(response.status === 200) {
        setFiles(response.data);
      } else throw new Error(response.data.msg);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  },

  onEdit = item => {
    setSelectedItem({...item, link: item.link.slice(0, item.link.indexOf(' ')), link1: item.link.slice(item.link.indexOf(' ') + 1)});
    setShow(true);
  },
  
  onDelete = item => {
    Swal.fire({
      title: 'Seguro?',
      text: "Esta acción es irreversible",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        axios({
          method: 'post',
          url: `/files/delete/${item._id}`, 
          headers: { 'x-auth-token': user.token }
        }).then(response => {
          if(response.status === 200) {
            Swal.fire({
              title: 'Archivo Eliminado',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            });
            getFiles();
          } else {
            Swal.fire({
              title: 'No se pudo eliminar',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false
            });
            throw new Error(response.data.msg);
          };
        }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
      };
    });
  };

  useEffect(() => {
    if (user && user.token && (user.type === 1 || user.type === 4) && !show) getFiles();
    return () => source.cancel();
    // eslint-disable-next-line
	}, [user, show, section]);

  return (
    <>
      <SectionSelect title="Ejercicios y Soluciones" section={section} setSection={setSection} />
      <Row className="py-2">
        <SearchBar content={files} setFiltered={setFiltered} fields={["title"]} />
        <Col xs={12} md={3} className="py-3 py-md-0">
          <ModalExcercise show={show} setShow={setShow} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        </Col>
      </Row>
      <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">{section} Ejercicios y Soluciones</h4>
        </Card.Header>
        <Card.Body>
          {
            filtered && filtered.length > 0 ?
            filtered.map(file =>
              <Row className="mt-2 text-left" key={file._id}>
                <Col xs={12} md={4}>
                  <h5 className="pl-0">{file.title}</h5>
                </Col>
                <Col xs={6} md={5} className="mt-2 mt-md-0">
                  <Row>
                    <FontAwesomeIcon icon={faCaretRight} className="mr-2 mt-1" />
                    <a download target="_blank" rel="noopener noreferrer" href={file.link.slice(0, file.link.indexOf(' '))} className="textmaterial">
                      Enlace del Ejercicio
                    </a>
                  </Row>
                  <Row>
                    <FontAwesomeIcon icon={faCaretRight} className="mr-2 mt-1" />
                    <a download target="_blank" rel="noopener noreferrer" href={file.link.slice(file.link.indexOf(' ') + 1)} className="textmaterial">
                      Enlace de la Solución
                    </a>
                  </Row>
                </Col>
                <Col xs={6} md={3} className="text-right mt-2 mt-md-0">
                  <VisibleSwitch file={file} />
                  <Button variant="light" className="mr-auto px-md-3 bg-transparent border-0 zoomuser" onClick={() => onEdit(file)}>
                    <FontAwesomeIcon icon={faEdit} className="text-warning fa-lg" />
                  </Button>
                  <Button variant="light" className="px-md-3 bg-transparent border-0 zoomuser" onClick={() => onDelete(file)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="text-danger fa-lg" />
                  </Button>
                </Col>
              </Row>
            ) : 
            <div className="text-center" >
              <Image src={noresult} fluid alt="" className="shadow-lg rounded" />
            </div>
          }
        </Card.Body>
      </Card>
    </>
  );
}