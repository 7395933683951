import React, { useEffect } from 'react';
import { Col, Form, FormControl } from "react-bootstrap";
/* import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons"; */

export default function SearchBar({ content=[], setFiltered, fields=[] }) {
    const search = e => {
        if (e.target.value && e.target.value.trim()) {
            let str = e.target.value.trim().toLowerCase(), b = false,
            results = content.filter(c => {
                            b = false;
                            fields.forEach(prop => {
                                if (c[prop].toString().toLowerCase().includes(str)) {
                                    b = true;
                                    return;
                                }
                            });
                            return b;
                        });
            setFiltered(results);
        }
        else setFiltered(content);
    };

    useEffect(() => setFiltered(content), [content, setFiltered]);

    return (
        <Col xs={12} md={9}>
          <Form inline onSubmit={(e) => e.preventDefault()}>
            <FormControl type="text" placeholder={"Buscar"} className="mr-sm-2" onChange={search} />
            {/* <FontAwesomeIcon icon={faSearch} className="textcolor" /> */}
          </Form>
        </Col>
    )
}
