import React, { useState, useEffect, useContext } from 'react';
import { faCaretRight, faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button, Card, Image } from "react-bootstrap";
import userContext from '../../context/user/userContext';
import ModalNotification from "./ModalNotification";
import noresult from "../Images/noresult.gif";
import VisibleSwitch from './VisibleSwitch';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function AdminNotification() {
  const [notifications, setNotifications] = useState([]),
  [selectedNotification, setSelectedNotification] = useState({_id: ''}),
  {subject} = useContext(subjectContext),
  source = axios.CancelToken.source(),
  [show, setShow] = useState(false),
  {user} = useContext(userContext),

  getNotifications = () => {
    axios({
      method: 'get',
      url: `/notifications/all`, 
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current },
      cancelToken: source.token
    }).then(response => {
      setNotifications(response.data);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  },

  onEdit = u => {
    setSelectedNotification(u);
    setShow(true);
  },
  
  onDelete = u => {
    Swal.fire({
      title: 'Seguro?',
      text: "Esta acción es irreversible",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        axios({
          method: 'post',
          url: `/notifications/delete/${u._id}`, 
          headers: { 'x-auth-token': user.token }
        }).then(() => {
          Swal.fire({
            title: 'Notificación Eliminada',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false
          });
          getNotifications();
        }).catch(() => 
          Swal.fire({
            title: 'No se pudo eliminar',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false
          })
        );
      };
    });
  };

  useEffect(() => {
    if (user && user.token && (user.type === 1 || user.type === 4) && !show) getNotifications();
    return () => source.cancel();
    // eslint-disable-next-line
	}, [user, show]);

  return (
    <>
      <div className="text-right">
        <ModalNotification show={show} setShow={setShow} selectedNotification={selectedNotification} setSelectedNotification={setSelectedNotification} />
      </div>
      <Card className="shadowcards mt-2">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">Notificaciones {subject.current ? 'del curso' : 'Generales'}</h4>
        </Card.Header>
        <Card.Body>
          {
            notifications && notifications.length > 0 ?
            <>
              <Row className="mt-2 d-none d-lg-flex">
                <Col xs={3} lg={3} className="d-flex flex-row">
                  <h5 className="ml-2">Título</h5>
                </Col>
                <Col xs={4} lg={5} className="text-left">
                  <h5 className="ml-2">Mensaje</h5>
                </Col>
              </Row>
              {
              notifications.map(h => 
                <Row className='mt-2 bg-white' key={h._id}>
                  <Col lg={3} className="d-none d-lg-flex flex-row pt-3">
                    <FontAwesomeIcon icon={faCaretRight} className="ml-2 mt-1" />
                    <h5 className="ml-2">{h.title}</h5>
                  </Col>
                  <Col lg={5} className="pt-3 d-none d-lg-flex text-center">
                    <p className="ml-3">{h.content}</p>
                  </Col>
                  <Col lg={3} className="d-none d-lg-flex flex-row text-right">
                    <div className="mt-3">
                      <VisibleSwitch file={h} type='notifications' />
                    </div>
                    <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" >
                      <FontAwesomeIcon icon={faEye} className="text-warning fa-lg" onClick={() => onEdit(h)} />
                    </Button>            
                    <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" >
                      <FontAwesomeIcon icon={faTrashAlt} className="text-danger fa-lg" onClick={() => onDelete(h)} />
                    </Button>            
                  </Col>
                  <Col xs={12} className="d-inline-flex d-lg-none pt-3">
                    <h5 className="pr-2">Titulo:</h5>
                    <h5 className="ml-2">{h.title}</h5>
                  </Col>
                  <Col xs={12} md={6}  className="pt-3 d-inline-flex d-lg-none">
                    <h5 className="pr-2">Mensaje: </h5>
                    <p className="ml-2">{h.content}</p>
                  </Col>
                  <Col xs={12} md={6} className="d-inline-flex d-lg-none justify-content-end"> 
                  <VisibleSwitch file={h} type='notifications' />           
                    <Button variant="light" className=" px-3 bg-transparent border-0 zoomuser" >
                      <FontAwesomeIcon icon={faEye} className="text-warning fa-lg" onClick={() => onEdit(h)} />
                    </Button>
                    <Button variant="light" className="px-3 bg-transparent border-0 zoomuser">
                      <FontAwesomeIcon icon={faTrashAlt} className="text-danger fa-lg" onClick={() => onDelete(h)} />
                    </Button>
                  </Col>
                </Row>
              )}
            </> :
            <div className="text-center" >
              <Image src={noresult} fluid alt="" className="shadow-lg rounded" />
            </div>
          }
        </Card.Body>
      </Card>
    </>
  );
}