import React from 'react';
import { Container, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ViewItem from './ViewItem';

export default function ModalViews({ user, show, setShow, selectedUser, setSelectedUser }) {
  const handleClose = () => {
    setShow(false);
    setSelectedUser({_id: ''});
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
      className="backgroundlogin textcolor"
    >
      <Modal.Header closeButton>
        <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
          Visualizaciones de {selectedUser.surname}, {selectedUser.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          { selectedUser?.content_viewed?.map(c => <ViewItem content={c} user={user} selectedUser={selectedUser._id} key={c._id} />) }
        </Container>
        <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
        </Button>
      </Modal.Body>
    </Modal>
  );
}