import React, { useContext } from "react";
import userContext from '../../context/user/userContext';
import HomeCampus from "./HomeCampus";
import HomeUser from "./HomeUser";
import Contact from "./Contact";
import { Container } from "react-bootstrap";
import './Home.css';

export default function Home() {
  const { user } = useContext(userContext);

  if (user?.token) {
    if (user.enabled) return <HomeUser />;
    else return (
      <>
        <Container className="text-center my-5 py-5">
          <h1 className="textcolor py-4">Tu usuario fue dado de baja</h1>
          <p className="textcolor py-3 bold">Por cualquier reclamo contacta con el administador o mandanos un email. </p>
          <Contact />
        </Container>
      </>
    );
  }
  return <HomeCampus />;
}