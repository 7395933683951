import React, { useEffect, useState, useContext } from 'react';
import { Container, Form, Row, Col, Button, Alert } from "react-bootstrap";
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userContext from '../../context/user/userContext';
import Swal from 'sweetalert2';
import moment from 'moment';
import axios from 'axios';

export default function Student({ student, subjects, setSubjects, index = -1 }) {
  const emptyForm = { id: '', partial1: 0, partial2: 0, partial3: 0, final: 0, free: 0, partial1_down: '', partial2_down: '', partial3_down: '', final_down: '', free_down: '', created_at: new Date(), subject: '', name: '' },
  chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
  [errorMsg, setErrorMsg] = useState(''),
  [formMode, setFormMode] = useState(0),
  [form, setForm] = useState(emptyForm),
  {user} = useContext(userContext),

  handleChange = ({target}) => setForm({...form, [target.id.slice(0, -1)]: target.value.length === 1 ? parseInt(target.value) : target.value }),

  handleChangeSubject = ({target}) => {
    let {type, name} = subjects.available.find(s => s._id === target.value) || {type: '', name: ''};
    setForm({...form, subject: target.value, name, type });
  },

  handleRemove = () => {
    Swal.fire({
      title: 'Seguro?',
      text: "Esta acción es irreversible",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Quitar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        let data = { subject: subjects.at[index].subject, student: student._id },
        {at, available} = subjects, tmp = at.splice(index, 1);
        available.push({...tmp[0], _id: tmp[0].subject});
        axios({
          method: 'post',
          url: `/subjects/remove_student`,
          headers: { 'x-auth-token': user.token },
          data
        }).then(response => {
          if (response.status === 200) setSubjects({...subjects, at, available});
          else {
            Swal.fire({
              title: 'Hubo un error',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false
            });
            throw new Error(response.data.msg);
          };
        });
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  },

  handleSubmit = async () => {
    if (!form.subject) { setErrorMsg('Seleccione una materia'); return; }
    let subject = {...form}, {available, at} = subjects;
    if (at.find((s, i) => i !== index && s._id === form.subject)) { setErrorMsg('Alumno ya inscripto en la materia'); return; }
    if (index < 0) at.push(subject);
    else at[index] = subject;

    axios({
      method: 'post',
      url: `/subjects/${index < 0 ? 'add_student' : 'update_student'}`, 
      headers: { 'x-auth-token': user.token },
      data: subject
    }).then(response => {
      if (response.status === 200) {
        Swal.fire({
          title: 'Hecho!', 
          text: `Alumno ${index > 0 ? 'Modificado' : 'Agregado'}!`, 
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        setSubjects({ available: available.filter(a => a._id !== subject.subject), at, new: false });
      } else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  };

  useEffect(() => {
    setForm(index < 0 ? { ...emptyForm, id: student._id } : { ...emptyForm, ...subjects.at[index] });
  }, [index, subjects]); // eslint-disable-line

  useEffect(() => {
    if (!form.subject) setFormMode(0);
    else {
      if (index < 0) setFormMode(subjects.available.find(s => s._id === form.subject).type ? 1 : 2);
      else setFormMode(subjects.at[index].type ? 1 : 2);
    }
  }, [form.subject]); // eslint-disable-line

  return (
    <Container className="border mb-3 mx-2">
      <Form.Row>
        {index < 0 ?
          <Form.Group as={Col} xs={12} lg={8} controlId={`subject${chars.charAt(index+1)}`}>
            <Form.Label className="bold">Materia</Form.Label>
            <Form.Control value={form.subject} as="select" className="borderinput" onChange={handleChangeSubject}>
              <option value=''>Seleccionar una materia..</option>
              {subjects.available?.map(s => <option value={s._id} key={s._id}>{s.name}</option>)}
            </Form.Control>
          </Form.Group> :
          <>
            <Col xs={12} lg={8} className="mt-2 text-left">
              <Form.Label className="bold mr-3">Materia:</Form.Label>
              <Form.Label className="bold mr-3 mr-lg-5">{subjects.at[index].name}</Form.Label>
              <Form.Label className="bold">Fecha de alta: {moment(subjects.at[index].created_at).format('DD-MM-YYYY hh:mm')}</Form.Label>
            </Col>
            <Col xs={12} lg={4} className="mt-2">
              <Button variant="light" className="zoomcampus text-danger bold shadow ml-auto" onClick={handleRemove}><FontAwesomeIcon icon={faTimes} className="mr-1 mt-1" />Quitar de esta materia</Button>
            </Col>
          </>
        }
      </Form.Row>
      {
        formMode > 0 &&
        <Form.Row className="pt-3">
          {
            formMode === 1 &&
            <>
              <Col xs={12} lg={4}>
                <Form.Group controlId={`partial1${chars.charAt(index+1)}`}>
                  <Form.Label className="font-weight-bold mr-2">Parcial 1</Form.Label>
                  <Form.Control value={form.partial1} as="select" className="borderinput" onChange={handleChange}>
                    <option value={0}>Ninguno</option>
                    <option value={1}>Parcial</option>
                    <option value={2}>Completo</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId={`partial1_down${chars.charAt(index+1)}`}>
                  <Form.Label className="bold">Fecha de Baja Parcial 1</Form.Label>
                  <Form.Control type="date" value={form.partial1_down?.slice(0, 10)} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group controlId={`partial2${chars.charAt(index+1)}`}>
                  <Form.Label className="font-weight-bold mr-2">Parcial 2</Form.Label>
                  <Form.Control value={form.partial2} as="select" className="borderinput" onChange={handleChange}>
                    <option value={0}>Ninguno</option>
                    <option value={1}>Parcial</option>
                    <option value={2}>Completo</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId={`partial2_down${chars.charAt(index+1)}`}>
                  <Form.Label className="bold">Fecha de Baja Parcial 2</Form.Label>
                  <Form.Control type="date" value={form.partial2_down?.slice(0, 10)} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={4}>
                <Form.Group controlId={`partial3${chars.charAt(index+1)}`}>
                  <Form.Label className="font-weight-bold mr-2">Parcial 3</Form.Label>
                  <Form.Control value={form.partial3} as="select" className="borderinput" onChange={handleChange}>
                    <option value={0}>Ninguno</option>
                    <option value={1}>Parcial</option>
                    <option value={2}>Completo</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId={`partial3_down${chars.charAt(index+1)}`}>
                  <Form.Label className="bold">Fecha de Baja Parcial 3</Form.Label>
                  <Form.Control type="date" value={form.partial3_down?.slice(0, 10)} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} lg={6}>
                <Form.Group controlId={`final${chars.charAt(index+1)}`}>
                  <Form.Label className="font-weight-bold mr-2">Final</Form.Label>
                  <Form.Control value={form.final} as="select" className="borderinput" onChange={handleChange}>
                    <option value={0}>Ninguno</option>
                    <option value={1}>Parcial</option>
                    <option value={2}>Completo</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId={`final_down${chars.charAt(index+1)}`}>
                  <Form.Label className="bold">Fecha de Baja Final</Form.Label>
                  <Form.Control type="date" value={form.final_down?.slice(0, 10)} onChange={handleChange} />
                </Form.Group>
              </Col>
            </>
          }
          <Col xs={12} lg={6}>
            <Form.Group controlId={`free${chars.charAt(index+1)}`}>
              <Form.Label className="font-weight-bold mr-2">Libre</Form.Label>
              <Form.Control value={form.free} as="select" className="borderinput" onChange={handleChange}>
                <option value={0}>Ninguno</option>
                <option value={1}>Parcial</option>
                <option value={2}>Completo</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId={`free_down${chars.charAt(index+1)}`}>
              <Form.Label className="bold">Fecha de Baja Libre</Form.Label>
              <Form.Control type="date" value={form.free_down?.slice(0, 10)} onChange={handleChange} />
            </Form.Group>
          </Col>
        </Form.Row>
      }
      <Row className="justify-content-center">
        <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
          {errorMsg}
        </Alert>
      </Row>
      <Row className="py-2 justify-content-center">
        <Button variant='light' className="my-3 textcolor bold shadow zoomcampus mr-3" onClick={handleSubmit} disabled={form.subject === ''}><FontAwesomeIcon icon={faSave} className="mr-2 mt-1" />Guardar Cambios</Button>
        {index < 0 && <Button variant="light" className="my-3 text-danger" onClick={() => setSubjects({...subjects, new: false})}>Cancelar</Button>}
      </Row>
    </Container>
  );
}