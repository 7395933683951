import React, { useState } from 'react';
import { faTimes, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Form, Row, Alert, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function SignUp({ setShow }) {
  const emptyForm = { name: '', username: '', surname: '', password: '', note: '', type: 0, email: '', enabled: true, phone: '' },
  [errorMsg, setErrorMsg] = useState(''),
  [form, setForm] = useState(emptyForm),

  handleChange = ({target}) => setForm({...form, [target.id]: target.value}),

  handleClose = () => {
    setShow(false);
    setErrorMsg('');
    setForm(emptyForm);
  },

  handleSubmit = e => {
      e.preventDefault();
      if (form.username.length < 3)  {setErrorMsg('El nombre de usuario es demasiado corto'); return;}
      if (form.username.length > 15) {setErrorMsg('El nombre de usuario es demasiado largo'); return;}
      if (form.name.length < 3)  {setErrorMsg('El nombre es demasiado corto'); return;}
      if (form.name.length > 15) {setErrorMsg('El nombre es demasiado largo'); return;}
      if (form.surname.length < 3)  {setErrorMsg('El apellido es demasiado corto'); return;}
      if (form.surname.length > 15) {setErrorMsg('El apellido es demasiado largo'); return;}
      if (form.password.length < 8)  {setErrorMsg('La contraseña es demasiado corta'); return;}
      if (form.password.length > 15) {setErrorMsg('La contraseña es demasiado larga'); return;}
      if (form.phone.length < 5) {setErrorMsg('El número de teléfono es demasiado corto'); return;}
      if (form.email.length < 5) {setErrorMsg('El email es demasiado corto'); return;}
      if (form.email.length < 5) {setErrorMsg('El email es demasiado corto'); return;}
      axios({
        method: 'post',
        url: `/users/create`,
        data: form
      }).then(response => {
        if(response.status === 200) {
          Swal.fire({
            title: 'Hecho!', 
            text: `Usuario Creado!`,
            icon: 'success',
            timer: 2000,
            showConfirmButton: false
          });
          handleClose();
        } else {
          Swal.fire({
            title: 'Hubo un error',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false
          });
          throw new Error(response.data.msg);
        };
      }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
    };

  return (
    <Modal.Body>
        <Form onSubmit={handleSubmit} className="mx-1 mx-md-5">
          <Form.Row>
            <Form.Group as={Col} xs={12}  controlId="username">
              <Form.Label className="font-weight-bold">Usuario</Form.Label>
              <Form.Control
                required
                value={form.username}
                type="text"
                placeholder="Ingresa el nombre de usuario"
                className="borderinput"
                onChange={handleChange}                   
              />
            </Form.Group>
            <Form.Group as={Col} xs={12}  controlId="password">
                <Form.Label className="font-weight-bold">Contraseña</Form.Label>
                <Form.Control
                  required
                  value={form.password}
                  type="password"
                  placeholder="Ingresa tu contraseña"
                  className="borderinput"
                  onChange={handleChange}                   
                />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12}  controlId="name">
              <Form.Label className="font-weight-bold">Nombre</Form.Label>
              <Form.Control
                required
                value={form.name}
                type="text"
                placeholder="Ingresa nombre"
                className="borderinput"
                onChange={handleChange}                   
              />
            </Form.Group>
            <Form.Group as={Col} xs={12}  controlId="surname">
              <Form.Label className="font-weight-bold">Apellido</Form.Label>
              <Form.Control
                required
                value={form.surname}
                type="text"
                placeholder="Ingresa apellido"
                className="borderinput"
                onChange={handleChange}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12}  controlId="phone">
              <Form.Label className="font-weight-bold">Teléfono</Form.Label>
              <Form.Control
                required
                value={form.phone}
                type="number"
                placeholder="Ingresa el numero"
                className="borderinput"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} xs={12}  controlId="email">
              <Form.Label className="font-weight-bold">Email</Form.Label>
              <Form.Control
                required
                value={form.email}
                type="email"
                placeholder="Ingresa el Email"
                className="borderinput"
                onChange={handleChange}
              />
            </Form.Group>
          </Form.Row>
        <Alert variant="danger" show={errorMsg !== ''} onClose={() => setErrorMsg('')} dismissible>
          {errorMsg}
        </Alert>
        <Row className="text-center flex-column flex-md-row pt-4 pb-3 justify-content-center">
          <Button variant="light" type="submit" className=" mr-0 mr-md-5 shadowcards textcolor">
            <FontAwesomeIcon icon={faPencilAlt} className="mr-2 textcolor" />Registrarme
          </Button>
          <Button variant="light" className="text-danger shadowcards mt-2 mt-md-0" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
          </Button>
        </Row>
      </Form>
    </Modal.Body>
  )
}
