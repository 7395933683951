import React, { useState, useEffect, useContext } from "react";
import { faCaretRight, faEdit, faTrashAlt, faFilePdf, faVideo } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Button, Card, Image } from "react-bootstrap";
import userContext from '../../context/user/userContext';
import SearchBar from '../SearchBar/SearchBar';
import noresult from "../Images/noresult.gif";
import SectionSelect from "./SectionSelect";
import VisibleSwitch from "./VisibleSwitch";
import ModalSlide from './ModalSlide';
import Video from "../Video/Video";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function AdminSlides() {
  const [selectedItem, setSelectedItem] = useState({_id: ''}),
  [section, setSection] = useState('Libre'),
  [showVideo, setShowVideo] = useState(false),
  [filtered, setFiltered] = useState([]),
  {subject} = useContext(subjectContext),
  source = axios.CancelToken.source(),
  [show, setShow] = useState(false),
  {user} = useContext(userContext),
  [files, setFiles] = useState([]),
  [video, setVideo] = useState(''),
  handleShow = video => {
    setVideo(video);
    setShowVideo(true);
  },

  handleClose = () => {
    setShowVideo(false);
    setVideo('');
  },

  translate = () => {
    switch(section) {
      case "Parcial 1": return 'partial1';
      case "Parcial 2": return 'partial2';
      case "Parcial 3": return 'partial3';
      case "Libre":     return 'free';
      default:          return 'final';
    }
  },
  
  getFiles = () => {
    axios({
      method: 'post',
      url: `/files/section/${translate()}-slide`, 
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current },
      cancelToken: source.token
    }).then(response => {
      if(response.status === 200) {
        setFiles(response.data);
      } else throw new Error(response.data.msg);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  },

  onEdit = item => {
    setSelectedItem(item);
    setShow(true);
  },
  
  onDelete = item => {
    Swal.fire({
      title: 'Seguro?',
      text: "Esta acción es irreversible",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        axios({
          method: 'post',
          url: `/files/delete/${item._id}`, 
          headers: { 'x-auth-token': user.token }
        }).then(response => {
          if(response.status === 200) {
            Swal.fire({
              title: 'Archivo Eliminado',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            });
            getFiles();
          } else {
            Swal.fire({
              title: 'No se pudo eliminar',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false
            });
            throw new Error(response.data.msg);
          };
        }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
      }
    });
  },
  
  generateContent = (title, link) => {
    let idx = link.indexOf('video=');
    if (idx > -1) {
      let link1 = link.slice(0, idx), video = link.slice(idx + 6);
      return (
        <Col xs={8} md={6} lg={9} className="d-inline-flex mt-2">
          <h5 className="pl-0 d-none d-lg-flex">{title}</h5>
          <FontAwesomeIcon icon={faCaretRight} className="ml-4 mr-2 mt-2" />
          <a download target="_blank" rel="noopener noreferrer" href={link1} className="textmaterialdiap">
            <FontAwesomeIcon icon={faFilePdf} className="ml-2 mt-1 fa-lg" />
          </a>
          <FontAwesomeIcon icon={faVideo} className="textmaterialdiap ml-4 my-1 fa-lg toclick" onClick={() => handleShow({video, title})}/>
        </Col>
      );
    }
    return (
      <Col xs={6} md={6} lg={9} className="d-inline-flex mt-2">
        <h5 className="pl-0 d-none d-lg-flex">{title}</h5>
        <FontAwesomeIcon icon={faCaretRight} className="ml-4 mr-2 mt-2" />
        <a download target="_blank" rel="noopener noreferrer" href={link} className="textmaterialdiap">
          <FontAwesomeIcon icon={faFilePdf} className="ml-2  mt-1 fa-lg" />
        </a>
      </Col>
    );
  };

  useEffect(() => {
    if (user && user.token && (user.type === 1 || user.type === 4) && !show) getFiles();
    return () => source.cancel();
    // eslint-disable-next-line
	}, [user, show, section]);

  return (
    <>
      <SectionSelect title="Diapositivas" section={section} setSection={setSection} />
      <Row className="py-2">
        <SearchBar content={files} setFiltered={setFiltered} fields={["title"]} />
        <Col xs={12} md={3} className="py-3 py-md-0">
          <ModalSlide show={show} setShow={setShow} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        </Col>
      </Row>
      <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">{section} Diapositivas</h4>
        </Card.Header>
        <Card.Body>
          {
            filtered && filtered.length > 0 ?
            filtered.map(file =>
            <div  key={file._id}>
              <Row className="mt-2 text-left d-none d-lg-flex">
                {
                  generateContent(file.title, file.link)
                }
                <Col xs={12} md={3}>
                  <VisibleSwitch file={file} />
                  <Button variant="light" className="mr-4 px-3 bg-transparent border-0 zoomuser" onClick={() => onEdit(file)}>
                    <FontAwesomeIcon icon={faEdit} className="text-warning fa-lg" />
                  </Button>
                  <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" onClick={() => onDelete(file)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="text-danger fa-lg" />
                  </Button>
                </Col>
              </Row>

              <Row className="mt-2 text-left d-flex d-lg-none">
                  <Col xs={12}>
                    <h5 className="ml-0 pl-0 text-capitalize">{file.title}</h5>
                  </Col>
                  {
                    generateContent(file.title, file.link)
                  }
                  <Col xs={12} md={6} className="text-right d-inline-flex justify-content-end mt-2 mt-md-0">
                    <VisibleSwitch file={file} />
                    <Button variant="light" className="mr-2 px-2 bg-transparent border-0 zoomuser" onClick={() => onEdit(file)}>
                      <FontAwesomeIcon icon={faEdit} className="text-warning fa-lg" />
                    </Button>
                    <Button variant="light" className="px-2 bg-transparent border-0 zoomuser" onClick={() => onDelete(file)}>
                      <FontAwesomeIcon icon={faTrashAlt} className="text-danger fa-lg" />
                    </Button>
                  </Col>
              </Row>
            </div>
            ) : 
            <div className="text-center" >
              <Image src={noresult} fluid alt="not found files" className="shadow-lg rounded" />
            </div>
          }
        </Card.Body>
      </Card>
      <Video show={showVideo} handleClose={handleClose} file={video} />
    </>
  );
}