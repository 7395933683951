import React, { useState, useEffect, useContext } from "react";
import { faPlus, faTimes, faLongArrowAltLeft, faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { Alert, Modal, Button, Form, Row, Col, Card } from "react-bootstrap";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import SectionSelectInput from "./SectionSelectInput";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalTest({ selectedItem, setSelectedItem, show, setShow }) {
  const emptyForm = { title: '', order: 1, section: '', answers: [], questions: [{question: '', options: ['','','','']}], is_visible: false, is_available: false, qty: 1 },
  [errorMsg, setErrorMsg] = useState(''),
  {subject} = useContext(subjectContext),
  [form, setForm] = useState(emptyForm),
  { user } = useContext(userContext),
  [check, setCheck] = useState(4),
  [page, setPage] = useState(0),

  handleChange = e => setForm({...form, [e.target.id]: (e.target.id === 'is_visible' || e.target.id === 'is_available') ? e.target.checked : e.target.value}),

  handleChangeQuestion = e => {
    let x = parseInt(e.target.id.charAt(0)), questions = form.questions;
    switch (x) {
      case 0: questions[page].options[0] = e.target.value; break;
      case 1: questions[page].options[1] = e.target.value; break;
      case 2: questions[page].options[2] = e.target.value; break;
      case 3: questions[page].options[3] = e.target.value; break;
      case 4: questions[page].question = e.target.value;   break;
      default: break;
    }
    setForm({...form, questions});
  },

  handleChangeAnswer = idx => {
    setCheck(idx);
    let answers = form.answers;
    answers[page] = form.questions[page].options[idx];
    setForm({...form, answers});
  },

  handleClose = () => {
    setShow(false);
    setPage(0);
    setErrorMsg('');
    setForm(emptyForm);
    setSelectedItem({ _id: '' });
  },

  handleShow = () => setShow(true),

  handleSubmit = (e) => {
    e.preventDefault();
    if (form.title.length < 1)  {setErrorMsg('El título es demasiado corto'); return;};
    if (form.title.length > 30) {setErrorMsg('El título es demasiado largo'); return;};
    if (form.qty < 1 || form.qty > 15) {setErrorMsg('Cantidad de preguntas inválida'); return;};
    if (form.questions.length < 1) {setErrorMsg('Debe haber al menos una pregunta'); return;};
    if (form.questions.length !== form.answers.length) {setErrorMsg('Revise las respuestas'); return;};
    form.questions.forEach((q, i) => {
      if (!(q.question || q.question.length < 5)) {setErrorMsg(`Revise la pregunta n°${i + 1}`); return;}
      if (!q.options[0] || !q.options[1] || !q.options[2] || !q.options[3]) {setErrorMsg(`Revise las opciones de la pregunta n°${i + 1}`); return;}
    });
    if (!form.section) {setErrorMsg('Seleccione una sección'); return;};
    if (form.order < 1)  {setErrorMsg('Orden inválido'); return;};
    let data = form;
    delete data.qty;
    data.subject = subject.current;
    axios({
      method: 'post',
      url: `/tests/${selectedItem._id ? `edit/${selectedItem._id}` : 'create'}`, 
      headers: { 'x-auth-token': user.token },
      data
    }).then(response => {
      if(response.status === 200) {
        Swal.fire({
          title: 'Hecho!', 
          text: `Evaluación ${selectedItem._id ? 'Modificada' : 'Creada'}!`, 
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        handleClose();
      } else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  };

  useEffect(() => {
    if (show) setForm(selectedItem._id ? {...selectedItem, qty: selectedItem.questions.length} : emptyForm);
    // eslint-disable-next-line
  }, [show, selectedItem]);

  useEffect(() => {
    if (form.qty && form.qty > 0) {
      if (page >= form.qty) setPage(form.qty - 1);
      let questions = form.questions.length > form.qty ? form.questions.slice(0, form.qty) : form.questions;
      for (let i = 0; i < form.qty; i++) {
        if (!(questions[i] && questions[i].question)) 
          questions[i] = {question: '', options: ['','','','']};
      }
      setForm({...form, questions});
    }
    // eslint-disable-next-line
  }, [form.qty]);

  useEffect(() => {
    let x = 4;
    if (form.answers && form.answers[page]) {
      for (let i = 0; i < 4; i++) {
        if (form.questions[page].options[i] === form.answers[page]) {
          x = i;
          i = 5;
        }
      }
    } 
    setCheck(x);
    // eslint-disable-next-line
  }, [page, form.answers]);
  
  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} className="textcolor mr-1 fa-lg" />
        Agregar
      </Button>
      <Modal show={show} onHide={handleClose} backdrop="static" centered className="backgroundlogin textcolor" size="lg">
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            { selectedItem._id ? 'Modificar ' : 'Crear ' } Prueba
          </Modal.Title>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="title">
                  <Form.Label className="font-weight-bold">Título</Form.Label>
                  <Form.Control value={form.title} type="text" placeholder="Ingresa el título" className="borderinput" onChange={handleChange} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="qty">
                  <Form.Label className="font-weight-bold">Cantidad de Preguntas </Form.Label>
                  <Form.Control value={form.qty} type="number" placeholder="Ingresa numero" className="borderinput" onChange={handleChange} />
                </Form.Group>
                <Form.Group as={Col} xs={6} lg={3} controlId="is_visible">
                  <Form.Check 
                    type="switch"
                    className="pt-3"
                    checked={form.is_visible}
                    onChange={handleChange}
                    label="Visible"
                  />
                </Form.Group>
                <Form.Group as={Col} xs={6} lg={3} controlId="is_available">
                  <Form.Check 
                    type="switch"
                    className="pt-3"
                    checked={form.is_available}
                    onChange={handleChange}
                    label="Habilitado"
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="order">
                  <Form.Label className="font-weight-bold">Orden</Form.Label>
                  <Form.Control value={form.order} type="number" min={1} max={15} placeholder="Ingresa el orden" className="borderinput" onChange={handleChange} />
                </Form.Group>
                <SectionSelectInput value={form.section} handleChange={handleChange} />
              </Form.Row>
              <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
                {errorMsg}
              </Alert>
              <Card className="py-2">
                <Form.Group as={Col} xs={12} controlId={"4" + page}>
                  <Form.Label className="font-weight-bold">Pregunta {page + 1}</Form.Label>
                  <Form.Control value={form.questions[page].question} type="text" placeholder="Ingresa la pregunta" className="borderinput" onChange={handleChangeQuestion} />
                </Form.Group>
                <Form.Row className="mx-1">
                  <Form.Group as={Col} xs={6} controlId={"0" + page}>
                    <Form.Label className="font-weight-bold">Respuesta 1</Form.Label>
                    <Form.Control value={form.questions[page].options[0]} type="text" placeholder="Ingresa la opción" className="borderinput" onChange={handleChangeQuestion} />
                  </Form.Group>
                  <Form.Group as={Col} xs={6} controlId={"1" + page}>
                    <Form.Label className="font-weight-bold">Respuesta 2</Form.Label>
                    <Form.Control value={form.questions[page].options[1]} type="text" placeholder="Ingresa la opción" className="borderinput" onChange={handleChangeQuestion} />
                  </Form.Group>
                </Form.Row>
                <Form.Row className="mx-1">
                  <Form.Group as={Col} xs={6} controlId={"2" + page}>
                    <Form.Label className="font-weight-bold">Respuesta 3</Form.Label>
                    <Form.Control value={form.questions[page].options[2]} type="text" placeholder="Ingresa la opción" className="borderinput" onChange={handleChangeQuestion} />
                  </Form.Group>
                  <Form.Group as={Col} xs={6} controlId={"3" + page}>
                    <Form.Label className="font-weight-bold">Respuesta 4</Form.Label>
                    <Form.Control value={form.questions[page].options[3]} type="text" placeholder="Ingresa la opción" className="borderinput" onChange={handleChangeQuestion} />
                  </Form.Group>
                </Form.Row>
                <Row> 
                  <Col className="textcolor font-weight-bold">
                    Correcta:
                  </Col>
                  <Col className="d-inline-flex textcolor font-weight-bold">
                    1. <Form.Check type="radio" aria-label="Respuesta 1" onChange={() => handleChangeAnswer(0)} checked={check===0} />
                  </Col>
                  <Col className="d-inline-flex textcolor font-weight-bold">
                    2. <Form.Check type="radio" aria-label="Respuesta 2" onChange={() => handleChangeAnswer(1)} checked={check===1} />
                  </Col>
                  <Col className="d-inline-flex textcolor font-weight-bold">
                    3. <Form.Check type="radio" aria-label="Respuesta 3" onChange={() => handleChangeAnswer(2)} checked={check===2} />
                  </Col>
                  <Col className="d-inline-flex textcolor font-weight-bold">
                    4. <Form.Check type="radio" aria-label="Respuesta 4" onChange={() => handleChangeAnswer(3)} checked={check===3} />
                  </Col>
                </Row>
                <Row className="mt-3 border-top border-info mx-3">
                  <Col xs={6} className="text-left">
                    <Button
                      variant="link"
                      className="text-decoration-none pb-0 pt-2 px-2 d-inline-flex"
                      onClick={() => setPage(page - 1)}
                      disabled={page < 1}
                    >
                      <FontAwesomeIcon
                        icon={faLongArrowAltLeft}
                        className="textcolor mr-2 mt-1 fa-lg"
                      />
                      <p className="textcolor font-weight-bold">Anterior</p>
                    </Button>
                  </Col>
                  <Col xs={6} className="text-right">
                    <Button
                      variant="link"
                      className="text-decoration-none pb-0 pt-2 px-2 d-inline-flex"
                      onClick={() => setPage(page + 1)}
                      disabled={page >= form.qty - 1}
                    >
                      <p className="textcolor font-weight-bold">Siguiente</p>
                      <FontAwesomeIcon
                        icon={faLongArrowAltRight}
                        className="textcolor ml-2 mt-1 "
                      />
                    </Button>
                  </Col>
                </Row>                
              </Card>              
              <Row className="text-center pt-4 pb-3 justify-content-center">
                <Button variant="light" type="submit" className="mr-5 shadowcards textcolor">
                  <FontAwesomeIcon icon={faPlus} className="mr-2 textcolor" />{ selectedItem._id ? 'Editar' : 'Agregar' }
                </Button>
                <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
                </Button>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}