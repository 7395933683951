import React, { useState, useEffect, useContext } from 'react';
import { Alert, Modal, Button, Form, Row, Col } from "react-bootstrap";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalTeacher({ show, setShow, selectedTeacher, setSelectedTeacher }) {
  const emptyForm = { name: '', username: '', surname: '', password: '', phone: '', email: '', enabled: true, note: '', type: 4 },
  [errorMsg, setErrorMsg] = useState(''),
  [form, setForm] = useState(emptyForm),
  {user} = useContext(userContext),

  handleChange = ({target}) => setForm({...form, [target.id]: target.value}),

  handleClose = () => {
    setShow(false);
    setErrorMsg('');
    setForm(emptyForm);
    setSelectedTeacher({_id: ''});
  },

  handleShow = () => setShow(true),

  handleSubmit = (e) => {
    e.preventDefault();
    if (form.username.length < 3)  {setErrorMsg('El nombre de usuario es demasiado corto'); return;};
    if (form.username.length > 15) {setErrorMsg('El nombre de usuario es demasiado largo'); return;};
    if (form.name.length < 3)  {setErrorMsg('El nombre es demasiado corto'); return;};
    if (form.name.length > 15) {setErrorMsg('El nombre es demasiado largo'); return;};
    if (form.surname.length < 3)  {setErrorMsg('El apellido es demasiado corto'); return;};
    if (form.surname.length > 15) {setErrorMsg('El apellido es demasiado largo'); return;};
    if (form.password.length < 8)  {setErrorMsg('La contraseña es demasiado corta'); return;};
    if (form.password.length > 15) {setErrorMsg('La contraseña es demasiado larga'); return;};
    if (form.phone.length < 5) {setErrorMsg('El número de teléfono es demasiado corto'); return;};
    if (form.email.length < 5) {setErrorMsg('El email es demasiado corto'); return;};
    if (form.email.length < 5) {setErrorMsg('El email es demasiado corto'); return;};
    axios({
      method: 'post',
      url: `/users/${selectedTeacher._id ? `edit/${selectedTeacher._id}` : 'create'}`, 
      headers: { 'x-auth-token': user.token },
      data: form
    }).then(response => {
      if(response.status === 200) {
        Swal.fire({
          title: 'Hecho!', 
          text: `Profesor ${selectedTeacher._id ? 'Modificado' : 'Creado'}!`, 
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        handleClose();
      } else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  };

  useEffect(() => {
    if (show) setForm(selectedTeacher._id ? { ...selectedTeacher, password: '********' } : emptyForm);
    // eslint-disable-next-line
  }, [show, selectedTeacher]);

  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} className="textcolor mr-1 fa-lg" />
        Agregar
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        centered
        className="backgroundlogin textcolor"
      >
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            { selectedTeacher._id ? 'Modificar ' : 'Crear ' }
            Profesor
          </Modal.Title>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="username">
                  <Form.Label className="font-weight-bold">Usuario</Form.Label>
                  <Form.Control
                    required
                    value={form.username}
                    type="text"
                    placeholder="Ingresa el nombre de usuario"
                    className="borderinput"
                    onChange={handleChange}                   
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} controlId="password">
                  <Form.Label className="font-weight-bold">Contraseña</Form.Label>
                  <Form.Control
                    required
                    value={form.password}
                    type="password"
                    placeholder="Ingresa tu contraseña"
                    className="borderinput"
                    onChange={handleChange}                   
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="name">
                  <Form.Label className="font-weight-bold">Nombre</Form.Label>
                  <Form.Control
                    required
                    value={form.name}
                    type="text"
                    placeholder="Ingresa nombre"
                    className="borderinput"
                    onChange={handleChange}                   
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} controlId="surname">
                  <Form.Label className="font-weight-bold">Apellido</Form.Label>
                  <Form.Control
                    required
                    value={form.surname}
                    type="text"
                    placeholder="Ingresa apellido"
                    className="borderinput"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="phone">
                  <Form.Label className="font-weight-bold">Teléfono</Form.Label>
                  <Form.Control
                    required
                    value={form.phone}
                    type="number"
                    placeholder="Ingresa el numero"
                    className="borderinput"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} controlId="enabled">
                  <Form.Label className="bold">Habilitado</Form.Label>
                  <Form.Control value={form.enabled} as="select" className="borderinput" onChange={handleChange}>
                    <option value={true}>Habilitado</option>
                    <option value={false}>Deshabilitado</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="email">
                  <Form.Label className="font-weight-bold">Email</Form.Label>
                  <Form.Control
                    required
                    value={form.email}
                    type="email"
                    placeholder="Ingresa el Email"
                    className="borderinput"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="note">
                  <Form.Control className="bordercolor" value={form.note} as="textarea" rows="4" onChange={handleChange} />
                </Form.Group>
              </Form.Row>
              <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
                {errorMsg}
              </Alert>
              <Row className="text-center pt-4 pb-3 justify-content-center">
                <Button variant="light" type="submit" className="mr-5 shadowcards textcolor">
                  <FontAwesomeIcon icon={faPlus} className="mr-2 textcolor" />{ selectedTeacher._id ? 'Editar' : 'Agregar' }
                </Button>
                <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
                </Button>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}