import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import UserProfile from "./components/UserProfile/UserProfile";
import SubjectState from './context/subject/subjectState';
import TestResult from "./components/Admin/TestResult";
import LiveVideo from "./components/Video/LiveVideo";
import History from "./components/History/History";
import Partial from "./components/Partial/Partial";
import Subject from "./components/Subject/Subject";
import Teacher from "./components/Teacher/Teacher";
import UserState from './context/user/userState';
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import Final from "./components/Final/Final";
import Admin from "./components/Admin/Admin";
import Free from "./components/Free/Free";
import Home from "./components/Home/Home";
import Test from "./components/Test/Test";
import 'aos/dist/aos.css';
import axios from 'axios';
import AOS from 'aos';
import "./App.css";

export default function App() {
  axios.defaults.baseURL = 'https://vmiaula.herokuapp.com/';
  AOS.init();

  return (
    <UserState>
      <SubjectState>
        <BrowserRouter>
          <Header />
          <Switch>
            <Route path="/live">
              <div className="fondocolor">
                <LiveVideo />
              </div>
            </Route>
            <Route path="/parcial">
              <div className="fondocolor">
                <Partial />
              </div>
            </Route>
            <Route path="/final">
              <div className="fondocolor">
                <Final />
              </div>
            </Route>
            <Route path="/free">
              <div className="fondocolor">
                <Free />
              </div>
            </Route>
            <Route path="/admin/results/:id">
              <div className="fondocolor">
                <TestResult />
              </div>
            </Route>
            <Route path="/teacher">
              <div className="fondocolor">
                <Teacher />
              </div>
            </Route>
            <Route path="/admin">
              <div className="fondocolor">
                <Admin />
              </div>
            </Route>
            <Route path="/profile">
              <div className="fondocolor">
                <UserProfile />
              </div>
            </Route>
            <Route path="/history">
              <div className="fondocolor">
                <History />
              </div>
            </Route>
            <Route path="/test/:id">
              <Test />
            </Route>
            <Route path="/subject/:id">
              <Subject />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
          <Footer />
        </BrowserRouter>
      </SubjectState>
    </UserState>
  );
}