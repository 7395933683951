import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Row, Card, Col, Image } from "react-bootstrap";
import noresult from '../Images/noresult.gif'

export default function ExcercisesAndSolutions({ files }) {
  return (
    <>
      <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">Ejercicios y Soluciones</h4>
        </Card.Header>
        <Card.Body>
          {
            files && files.length > 0 ?  files.map(file => file.is_visible &&
            <Row className="mt-2 text-left" key={file._id}>
              <Col xs={12} md={4} lg={4} >
                <h5 className="ml-1 ml-md-4 pl-0">{file.title}</h5>
              </Col>
              <Col xs={12} md={5} lg={5} className=" ml-2 ml-lg-0 mt-2 mt-md-0">
                <Row>
                  <FontAwesomeIcon icon={faCaretRight} className="mr-2 mt-1" />
                  <a download target="_blank" rel="noopener noreferrer" href={file.link.slice(0, file.link.indexOf(' '))} className="textmaterial">
                    Ejercicio
                  </a>
                </Row>
                <Row>
                  <FontAwesomeIcon icon={faCaretRight} className="mr-2 mt-1" />
                  <a download target="_blank" rel="noopener noreferrer" href={file.link.slice(file.link.indexOf(' ') + 1)} className="textmaterial">
                    Solución
                  </a>
                </Row>
              </Col>
            </Row>
            ) : 
            <div className="text-center " >
              <Image src={noresult} fluid alt="" className="shadow-lg rounded" />
            </div>

          }         
        </Card.Body>
      </Card>
    </>
  );
}