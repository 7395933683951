import React from 'react'
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Image } from "react-bootstrap";
import principal from "../Images/Home/1.png";

export default function Begin({_ref}) {
    return (
        <Container
        ref={_ref}
        fluid
        className=" d-flex bgscreen justify-content-center align-items-center"
      >
        <Row className="flex-column text-center">
          <Image src={principal} fluid className="pb-5" data-aos="fade-down" data-aos-delay="200"/>
          <h2 className="d-none d-md-block text-white bgcolor titlecampus px-1 px-lg-5 mx-3 mx-lg-0 pt-2 pb-3" data-aos="fade-down" data-aos-delay="300">
            ¡Bienvenidos a Mi Aula!
          </h2>
          <h2 className="d-block d-md-none text-white bgcolor titlecampus px-1 px-lg-5 mx-3 mx-lg-0 pt-2 pb-3 my-3" data-aos="fade-down" data-aos-delay="300">
            ¡Bienvenidos a <br/> Mi Aula!
          </h2>
            <h3 className="textcolor px-1 px-lg-5 py-1 mx-2 mx-lg-0 my-4 my-lg-0" data-aos="fade-down" data-aos-delay="400">
             Te brindamos todo el contenido  para pasar <br /> con éxito tus materias
            </h3>
          <div className="py-1 mb-5" data-aos="fade-down" data-aos-delay="800">
            <FontAwesomeIcon icon={faChevronDown} className="fa-3x textcolor" />
          </div>
        </Row>
      </Container>
    )
}
