import React, { useState, useContext } from 'react';
import { faUserAlt, faSignInAlt, faTimes, faPencilAlt} from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Form, Row, Alert, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import Loading from '../Loading/Loading';
import SignUp from './SignUp';
import axios from 'axios';
import "./LogIn.css";

export default function LogIn() {
  const [show, setShow] = useState(false),
  [loading, setLoading] = useState(false),
  [errorMsg, setErrorMsg] = useState(''),
  [registerMethod , setRegisterMethod]= useState(false),
  { iniciarSesion } = useContext(userContext),
  [usuario, setUsuario] = useState({ username: '', password: '' }),
  handleChange = ({target}) => setUsuario({ ...usuario, [target.name]: target.value }),
  handleClose = () => setShow(false),
  handleShow = () => setShow(true),
  handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    axios.post('/users/login', {...usuario})
    .then(response => {
      if (response.status === 200) iniciarSesion(response.data);
    }).catch(() => {
      setErrorMsg('Usuario o Contraseña incorrecta')
      setLoading(false);
    });
  };

  return (
    <>
      <Button variant="link" className="text-decoration-none ml-2 border-0 textcolor" onClick={handleShow}>
        <FontAwesomeIcon icon={faUserAlt} />
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        size="md"
        className="backgroundlogin textcolor"
      >
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            {
              registerMethod === false ?  <>Iniciar Sesión</> : <>Registrase</>
            }
          </Modal.Title>
          {
            registerMethod === false ?
            <>
             <Modal.Body>
              {loading && <Loading />}
              <Form onSubmit={handleSubmit} className="mx-1 mx-md-5">
                <Form.Group>
                  <Form.Label className="font-weight-bold">Usuario</Form.Label>
                  <Form.Control type="text" placeholder="Ingresa tu usuario" className="borderinput" name="username" value={usuario.username} onChange={handleChange} disabled={loading} />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="font-weight-bold">Password</Form.Label>
                  <Form.Control type="password" placeholder="Ingresa tu contraseña" className="borderinput" name="password" value={usuario.password} onChange={handleChange} disabled={loading} />
                </Form.Group>
                <Alert variant="danger" show={errorMsg !== ''} onClose={() => setErrorMsg('')} dismissible>
                  {errorMsg}
                </Alert>
                <Row className="text-center flex-column flex-md-row pt-4 pb-3 justify-content-center align-content-center">
                  <Button variant="light" type="submit" className=" mr-0 mr-md-5 shadowcards textcolor" disabled={loading}>
                    <FontAwesomeIcon icon={faSignInAlt} className="mr-2 textcolor" />Ingresar
                  </Button>
                  <Button variant="light" className="text-danger shadowcards mt-2 mt-md-0" onClick={handleClose}>
                    <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
                  </Button>
                  <Button as={Col} xs={5}  variant="light" type="submit" className="mt-4 mb-2 shadowcards textcolor" onClick={() => setRegisterMethod(true)}>
                    <FontAwesomeIcon icon={faPencilAlt} className="mr-2 textcolor" />Registrarme
                  </Button>
                </Row>
              </Form>
            </Modal.Body>
          </>
          :
            <>
              <SignUp setShow={setShow}/>
              <Button variant="light" type="submit" as={Col} xs={5} className="mt-0 mb-4 shadowcards textcolor" onClick={() => setRegisterMethod(false)} disabled={loading}>
                <FontAwesomeIcon icon={faSignInAlt} className="mr-2 textcolor" />Iniciar Sesión
              </Button>
            </>
          }
        </div>
      </Modal>
    </>
  );
}