import React, { useContext, useEffect, useState } from "react";
import subjectContext from '../../context/subject/subjectContext';
import userContext from '../../context/user/userContext';
import { Container } from "react-bootstrap";
import loading from "../Images/loading.gif";
import intro from "../Video/Intro.mp4";
import './Video.css';

export default function LiveVideo () {
  const [status, setStatus] = useState('loading'),
  { subject } = useContext(subjectContext),
  { user } = useContext(userContext),
  [video, setVideo] = useState(null);

  useEffect(() => {
    if (user && user.token) {
      if (subject.current) {
        let sub = subject.all.find(s => s._id === subject.current);
        if (sub && sub.live) setVideo(sub.live);
        else setStatus('offline');
      } else setStatus('noSubject');
    } else setStatus('noUser');
    // eslint-disable-next-line
  }, [user?.token, subject.current]);

  useEffect(() => {
    if (video) setStatus('live');
  }, [video]);

  return (
    <div className="p-1 pb-4 bg-black">
      <Container fluid className="py-2">
        {
          (status !== "loading" && status !== "live") ?
          <div className="text-center py-2 bold textcolor">
            <h4>
            {
              status === "offline" ? 'Video Actualmente no disponible' :
              status === "noSubject" ? 'Seleccioná una materia' : "Primero iniciá sesión"
            }
            </h4>
          </div> :
          <div className="text-center pt-1 pb-2 d-flex justify-content-center ">
            {
              status === "loading" &&
              <div className="d-flex video py-2">
                <img src={loading} alt="loading" height={150}/> 
              </div>
            }
            {
              video?.includes('vimeo') ?
              <iframe title="video" src={video.replace("vimeo.com" , "player.vimeo.com/video") + "?byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} width="1920" height="1080" frameBorder="0" allowFullScreen className={status === "loading" ? "d-none" : "video"}></iframe>
              : 
              <h4 className={status === "loading" ? "d-none" : "d-flex flex-column  textcolor text-capitalize py-2"}>
                <video width="750" autoPlay muted loop preload >
                  <source src={intro} type="video/mp4"/>
                </video>
              </h4>
            }
          </div>
        }
      </Container>
    </div>
  );
}