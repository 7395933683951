import React, { useState, useEffect, useContext } from 'react';
import { faCaretRight, faDownload, faEye, faTrashAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Card, Image, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import SearchBar from '../SearchBar/SearchBar';
import noresult from "../Images/noresult.gif";
import * as FileSaver from "file-saver";
import ModalUser from "./ModalUser";
import Swal from 'sweetalert2';
import * as XLSX from "xlsx";
import Paged from './Paged';
import moment from 'moment';
import axios from 'axios';

export default function AdminUsers() {
  const [itemsPerPage, setItemsPerPage] = useState(15),
  [selectedUser, setSelectedUser] = useState({_id: ''}),
  [filtered, setFiltered] = useState([]),
  [lastPage, setLastPage] = useState(0),
  source = axios.CancelToken.source(),
  [show, setShow] = useState(false),
  {user} = useContext(userContext),
  [users, setUsers] = useState([]),
  [page, setPage] = useState(0),

  exportUsers = () => {
    axios({
      method: 'get',
      url: `/users/export`, 
      headers: { 'x-auth-token': user.token },
      cancelToken: source.token
    }).then(response => {
      if(response.status === 200) {
        const ws = XLSX.utils.json_to_sheet(response.data),
        wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Usuarios");
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" }),
        output = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });

        FileSaver.saveAs(output, `Usuarios Campus - ${moment().format("DD-MM-YYYY")}.xlsx`);
      } else throw new Error(response.data.msg);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  },

  getUsers = () => {
    axios({
      method: 'get',
      url: `/users/all`, 
      headers: { 'x-auth-token': user.token },
      cancelToken: source.token
    }).then(response => {
      if(response.status === 200) {
        setUsers(response.data.filter(u => !u.is_deleted));
      } else throw new Error(response.data.msg);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  },

  onEdit = u => {
    setSelectedUser(u);
    setShow(true);
  },

  onSuspend = u => {
    let enabled = !u.enabled,
    props = enabled ? 
      {title: 'Habilitar este usuario?', confirmButtonText: 'Habilitar', confirmButtonColor: "#28a745"} :
      {title: 'Inhabilitar este usuario?', confirmButtonText: 'Inhabilitar', confirmButtonColor: '#d33'}
    Swal.fire({
      ...props,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        axios({
          method: 'post',
          url: `/users/edit/${u._id}`,
          headers: { 'x-auth-token': user.token },
          data: { enabled }
        }).then(response => {
          if(response.status === 200) {
            Swal.fire({
              title: 'Hecho',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            });
            getUsers();
          } else {
            Swal.fire({
              title: 'Hubo un error',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false
            });
            throw new Error(response.data.msg);
          };
        }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
      };
    });
  },

  onDelete = u => {
    Swal.fire({
      title: 'Seguro?',
      text: "Esta acción es irreversible",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        axios({
          method: 'post',
          url: `/users/delete/${u._id}`, 
          headers: { 'x-auth-token': user.token }
        }).then(response => {
          if(response.status === 200) {
            Swal.fire({
              title: 'Usuario Eliminado',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            });
            getUsers();
          } else {
            Swal.fire({
              title: 'No se pudo eliminar',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false
            });
            throw new Error(response.data.msg);
          };
        }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
      };
    });
  };

  useEffect(() => {
		if (user && user.token && (user.type === 1 || user.type === 4) && !show) getUsers();
    return () => source.cancel();
    // eslint-disable-next-line
	}, [user, show]);

  useEffect(() => {
    setPage(0);
		if (filtered && filtered.length > 0) setLastPage(Math.floor(filtered.length / itemsPerPage));
    else setLastPage(-1);
    // eslint-disable-next-line
	}, [filtered, itemsPerPage]);

  return (
    <>
      {
        user && user.type === 1 &&
        <Row>
          <Button variant="light textcolor bold ml-3" onClick={exportUsers}><FontAwesomeIcon icon={faDownload} className="fa-lg mr-2" />Exportar usuarios a excel</Button>
        </Row>
      }
      <Row className="py-2">
        <SearchBar content={users} setFiltered={setFiltered} fields={["name", "surname", "phone"]} />
        <Col xs={12} md={3} className="py-3 py-md-0">
          <ModalUser show={show} setShow={setShow} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </Col>
      </Row>
      <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">Usuarios</h4>
        </Card.Header>
        <Card.Body>
        <Row className="mt-2 d-none d-lg-flex">
          <Col xs={5} lg={4} className="d-flex flex-row">
            <h5 className="ml-2">Nombre</h5>
          </Col>
          <Col xs={4} lg={4}>
            <h5 className="ml-2">Teléfono</h5>
          </Col>
          <Form.Group as={Col} xs={3} lg={4} controlId="item" className="d-inline-flex">
            <Form.Label>Usuarios por página</Form.Label>
            <Form.Control value={itemsPerPage} as="select" className="borderinput" onChange={({target}) => setItemsPerPage(target.value)}>
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Form.Control>
          </Form.Group>
        </Row>
        {
          filtered && filtered.length > 0 ?
          <>
            {
            filtered.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map(_user =>
              <Row key={_user.username} className={_user.is_deleted ? 'mt-2 bg-light' : "mt-2"}>
                <Col lg={5} className="d-none d-lg-flex flex-row pt-3">
                  <FontAwesomeIcon icon={faCaretRight} className="ml-2 mt-1" />
                  <h5 className="ml-2">{`${_user.surname}, ${_user.name}`}</h5>
                </Col>
                <Col lg={3} className="pt-3 d-none d-lg-flex text-center">
                  <p className="ml-3">{_user.phone}</p>
                </Col>
                <Col lg={4} className="d-none d-lg-flex flex-row text-right">
                  <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" onClick={() => onEdit(_user)}>
                    <FontAwesomeIcon icon={faEye} className="text-warning fa-lg" />
                  </Button>
                  { user._id !== _user._id &&
                    <>
                      <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" onClick={() => onDelete(_user)}>
                        <FontAwesomeIcon icon={faTrashAlt} className="text-danger fa-lg" />
                      </Button>
                      <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" onClick={() => onSuspend(_user)}>
                        <FontAwesomeIcon icon={faUser} className={`${_user.enabled ? "text-success" : "text-danger"} fa-lg`} />
                      </Button>
                    </>
                  }
                </Col>
                <Col xs={12} className="d-inline-flex d-lg-none  pt-3">
                  <h5 className="pr-2">Nombre:</h5>
                  <FontAwesomeIcon icon={faCaretRight} className="ml-2 mt-1" />
                  <h5 className="ml-2">{`${_user.surname}, ${_user.name}`}</h5>
                </Col>
                <Col xs={12} md={6}  className="pt-3 d-inline-flex d-lg-none">
                  <h5 className="pr-2">Teléfono: </h5>
                  <p className="ml-2">{_user.phone}</p>
                </Col>
                <Col xs={12} md={6} className="d-inline-flex d-lg-none justify-content-end">
                  <Button variant="light" className=" px-3 bg-transparent border-0 zoomuser" onClick={() => onEdit(_user)}>
                      <FontAwesomeIcon icon={faEye} className="text-warning fa-lg" />
                  </Button>
                { user._id !== _user._id && 
                  <>
                    <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" onClick={() => onDelete(_user)}>
                      <FontAwesomeIcon icon={faTrashAlt} className="text-danger fa-lg" />
                    </Button>
                    <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" onClick={() => onSuspend(_user)}>
                      <FontAwesomeIcon icon={faUser} className={`${_user.enabled ? "text-success" : "text-danger"} fa-lg`} />
                    </Button>
                  </>
                }
                </Col>
              </Row>
            )}
            <Paged page={page} setPage={setPage} lastPage={lastPage} />
          </> :  
          <div className="text-center">
            <Image src={noresult} fluid alt="" className="shadow-lg rounded" />
          </div>
        }
        </Card.Body>
      </Card>
    </>
  );
}