import React, { useState, useEffect, useContext } from "react";
import { Alert, Modal, Button, Form, Row, Col } from "react-bootstrap";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import SectionSelectInput from "./SectionSelectInput";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalComplement({ selectedItem, setSelectedItem, show, setShow }) {
  const emptyForm = { title: '', link: '', order: 1, section: '', type: 'complement', is_visible: false },
  {subject} = useContext(subjectContext),
  [errorMsg, setErrorMsg] = useState(''),
  [form, setForm] = useState(emptyForm),
  {user} = useContext(userContext),

  handleChange = (e) => {
    setForm({...form, [e.target.id]: e.target.id === 'is_visible' ? e.target.checked : e.target.value});
  },

  handleClose = () => {
    setShow(false);
    setErrorMsg('');
    setForm(emptyForm);
    setSelectedItem({_id: ''});
  },

  handleShow = () => setShow(true),

  handleSubmit = (e) => {
    e.preventDefault();
    if (form.title.length < 1)  {setErrorMsg('El título es demasiado corto'); return;};
    if (form.title.length > 30) {setErrorMsg('El título es demasiado largo'); return;};
    if (form.link.length < 5) {setErrorMsg('El link es demasiado corto'); return;};
    if (form.order < 1)  {setErrorMsg('Orden inválido'); return;};
    if (!form.section) {setErrorMsg('Seleccione una sección'); return;};
    axios({
      method: 'post',
      url: `/files/${selectedItem._id ? `edit/${selectedItem._id}` : 'create'}`, 
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current, ...form }
    }).then(response => {
      if(response.status === 200) {
        Swal.fire({
          title: 'Hecho!', 
          text: `Archivo ${selectedItem._id ? 'Modificado' : 'Creado'}!`, 
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        handleClose();
      } else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  };

  useEffect(() => {
    if (show) setForm(selectedItem._id ? selectedItem : emptyForm);
    // eslint-disable-next-line
  }, [show, selectedItem]);
  
  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} className="textcolor mr-1 fa-lg" />
        Agregar
      </Button>
      <Modal
        backdrop="static"
        show={show}
        onHide={handleClose}
        centered
        className="backgroundlogin textcolor"
      >
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            { selectedItem._id ? 'Modificar ' : 'Crear ' } Diapositiva
          </Modal.Title>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="title">
                  <Form.Label className="font-weight-bold">Título</Form.Label>
                  <Form.Control value={form.title} type="text" placeholder="Ingresa el título" className="borderinput" onChange={handleChange} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="link">
                  <Form.Label className="font-weight-bold">Link</Form.Label>
                  <Form.Control value={form.link} type="text" placeholder="Ingresa el link" className="borderinput" onChange={handleChange} />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="order">
                  <Form.Label className="font-weight-bold">Orden</Form.Label>
                  <Form.Control value={form.order} type="number" min={1} placeholder="Ingresa el orden" className="borderinput" onChange={handleChange} />
                </Form.Group>
                <SectionSelectInput value={form.section} handleChange={handleChange} />
              </Form.Row>
              <Form.Row className="my-3 justify-content-center">
                <Form.Check 
                  type="switch"
                  checked={form.is_visible}
                  onChange={handleChange}
                  id="is_visible"
                  label="Visible"
                />
              </Form.Row>
              <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
                {errorMsg}
              </Alert>
              <Row className="text-center pt-4 pb-3 justify-content-center">
                <Button variant="light" type="submit" className="mr-5 shadowcards textcolor">
                  <FontAwesomeIcon icon={faPlus} className="mr-2 textcolor" />{ selectedItem._id ? 'Editar' : 'Agregar' }
                </Button>
                <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
                </Button>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}