import React, { useState, useContext, useEffect } from 'react';
import { faBook, faBookOpen, faExclamationCircle,  faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Button, Row, Col, Container } from "react-bootstrap";
import userContext from '../../context/user/userContext';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalRequestedSubjects() {
  const [show, setShow] = useState(false),
  [subjects, setSubjects] = useState([]),
  source = axios.CancelToken.source(),
  handleClose = () => setShow(false),
  handleShow = () => setShow(true),
  { user } = useContext(userContext),

  getSubjects = () => {
    axios({
      method: 'post',
      url: `/subjects/student_data`,
      headers: { 'x-auth-token': user.token },
      data: { user: user._id },
      cancelToken: source.token
    }).then(response => {
      if (response.status === 200) setSubjects(response.data.pending);
      else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        handleClose();
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  },

  cancel = id => {
    axios({
      method: 'post',
      url: `/subjects/request/cancel`,
      headers: { 'x-auth-token': user.token },
      data: { subject: id, student: user._id },
      cancelToken: source.token
    }).then(response => {
      if (response.status === 200) {
        Swal.fire({
          title: 'Solicitud Cancelada!',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        setSubjects(subjects.filter(s => s._id !== id));
      } else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  };

  useEffect(() => {
    getSubjects();

    return () => source.cancel();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Button variant="light" onClick={handleShow} className="borderhome zoomcampus shadow px-5 py-2 bg-white">
        <FontAwesomeIcon icon={faBookOpen} className="textcolor mr-2 fa-lg" />
        Ver
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        centered
        className="backgroundlogin textcolor"
      >
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            Solicitudes Enviadas (click para cancelar la solicitud)
          </Modal.Title>
          <Modal.Body>
            <Row>
              {
                subjects && subjects.length > 0 ?
                subjects.map(s =>
                  <Col xs={12} md={4} key={s._id} className="mt-4 text-center">
                    <Button className="btn text-decoration-none shadow borderhome zoomcampus bg-white px-4 pt-2 pb-1" onClick={() => cancel(s._id)}>
                      <p className="mb-0 textcolor d-inline-flex bold">
                        <FontAwesomeIcon icon={faBook} className="fa-lg font-weigth-bold mr-2 mt-1" />
                        {s.name}
                      </p>
                    </Button>
                  </Col>
                ) :
                <Container className="text-center">
                  <FontAwesomeIcon icon={faExclamationCircle} className="text-warning mt-3 mb-4 fa-3x"/>
                  <p className="font-weight-bold ">No solicitaste ninguna inscripción</p>
                </Container>
              }
            </Row>
            <Button variant="light" onClick={handleClose} className="text-danger my-5 px-4 bg-white zoomcampus border-danger"> <FontAwesomeIcon icon={faTimes} className="fa-lg font-weigth-bold mr-2" />Cerrar</Button>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}