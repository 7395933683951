import React, { useContext, useEffect, useState } from 'react';
import { faChevronDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Image, Card } from "react-bootstrap";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import aulavirtual2 from "../Images/Home/aulavirtual2.png";
import aulavirtual6 from "../Images/Home/aulavirtual6.png";
import aulavirtual5 from "../Images/Home/aulavirtual5.png";
import TeacherDefault from '../Images/TeacherDefault.jpg';
import userContext from '../../context/user/userContext';
import prof from '../Images/ProfesorProfile/prof.png';
import whastapp from "../Images/whatsapp.png";
import { useParams } from "react-router-dom";
import axios from 'axios';
import './Subject.css';

export default function Subject() {
  const { id } = useParams(),
  [link, setLink] = useState(''),
  { user } = useContext(userContext),
  [content, setContent] = useState({}),
  { subject, update } = useContext(subjectContext),
  images = [aulavirtual2, aulavirtual5, aulavirtual6];

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user && user.token && id !== subject.current) update({ current: id }); 
    axios({
      method: 'get',
      url: `/subjects/id/${id}`,
    }).then(response => {
      if(response.status === 200) {
        setContent(response.data);
        setLink(`https://api.whatsapp.com/send?phone=${response.data.teacher.phone}&text=Para%20consultarte%20por%20...`);
      } else throw new Error(response.data.msg);
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
    // eslint-disable-next-line
  }, [user?.token, subject.current, id]);

  return (
    <>
      <Container fluid className="portadaProf d-block">
        <Row className="justify-content-center align-items-center">
          <Col xs={12} className="text-center text-lg-center ">
            <Image src={prof} fluid className="profp" alt="" />
          </Col>
          <Col xs={12} lg={6}>
            <div className="text-center">
              <h1 className="titleprof textcolor">¡Bienvenidos!</h1>
              <div className="px-0 px-lg-5 mx-0">
                <h1 className="text-white bgcolor subprof px-1 px-lg-3 py-3 text-capitalize">
                  {content.name}
                </h1>
              </div>
              <div className="text-center d-block d-lg-none">
                <FontAwesomeIcon icon={faChevronDown} className="fa-2x textcolor mt-5 "/>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="fondocolor pb-5">
        <Row className="justify-content-center">
          <Col xs={12} className="p-0">
            <Container className="text-center justify-content-center align-content-center bg-transparent border-0 " >
              <Row>
                <Col xs={12} lg={6}>
                  <div className="d-flex justify-content-center py-4 py-lg-0">
                    <Image className="imgprofile" fluid src={content.teacher?.picture || TeacherDefault} />
                  </div>
                </Col>
                <Col xs={12} lg={6} className="d-flex flex-column justify-content-center ">
                  <p className="bordera text-capitalize">{`${content.teacher?.name} ${content.teacher?.surname}`}</p>
                  <p className="borderab ">{content.category}</p>
                  <p className="font-weight-bold pt-2 spacewords">
                    {content.description}
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12} className="backgroundimage text-left">
            {
              content.sections?.map((s, i) =>
                <Container key={i} className="px-1 px-lg-0 py-3 mt-5">
                  <Card className="mt-4 shadowcards" data-aos="fade-down" data-aos-delay="200">
                    <Card.Header className="m-0 pb-1 fondotitle text-center">
                      <h1 className="text-white  rounded-pill">{s.section}</h1>
                    </Card.Header>
                    <Card.Body>
                      <Row className="justify-content-center align-items-center">
                        {
                          i % 2 === 0 &&
                          <Col xs={12} md={6}>
                            <Image src={images[i]} fluid alt="aulavirtual" />
                          </Col>
                        }
                        <Col xs={12} md={6}>
                          {s.text.split('-').map((l, i) => l.trim().length > 0 && <p key={i}><FontAwesomeIcon icon={faCheck} className="mr-2" />{l}</p>)}
                          <Container fluid className="p-0 m-0">
                            <Row className="justify-content-center">
                              <Col className="text-left">
                                <p className=" font-weight-bold">
                                  Para consultas:
                                </p>
                              </Col>
                              <Col className="text-center">
                                <a href={link} target="_blank" rel="noopener noreferrer" className="rounded">
                                  <Image src={whastapp} width="120" className="zoomwhastapp" alt="whastapp logo" />
                                </a>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                        {
                          i % 2 !== 0 &&
                          <Col xs={12} md={6}>
                            <Image src={images[i]} fluid alt="aulavirtual" />
                          </Col>
                        }
                      </Row>
                    </Card.Body>
                  </Card>
                </Container>
              )
            }
          </Col>
        </Row>
      </Container>
    </>
  );
}