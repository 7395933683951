import React, { useState, useContext, useEffect } from 'react';
import { Container, Form, Row, Col, Button, Alert, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import userContext from '../../context/user/userContext';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalUserRequest({show, setShow, selectedUser, setSelectedUser, subject, type }) {
  const emptyForm = { id: '', partial1: 0, partial2: 0, partial3: 0, final: 0, free: 0, partial1_down: '', partial2_down: '', partial3_down: '', final_down: '', free_down: '', created_at: new Date(), subject: '', name: '', phone: '', email: '' },
  [errorMsg, setErrorMsg] = useState(''),
  [form, setForm] = useState(emptyForm),
  {user} = useContext(userContext),

  handleClose = () => {
    setShow(false);
    setSelectedUser({ _id: '' });
  },

  handleChange = ({target}) => setForm({...form, [target.id]: target.value.length === 1 ? parseInt(target.value) : target.value }),

  handleSubmit = () => {
    let data = {...form, subject };
    axios({
      method: 'post',
      url: `/subjects/add_student`, 
      headers: { 'x-auth-token': user.token },
      data
    }).then(response => {
      if (response.status === 200) {
        Swal.fire({
          title: 'Hecho!', 
          text: `Alumno Agregado!`, 
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        handleClose();
      } else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  };

  useEffect(() => {
    setForm(selectedUser._id ? {...selectedUser, id: selectedUser._id, subject} : emptyForm);
    // eslint-disable-next-line
  }, [selectedUser]);
  
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      backdrop="static"
      centered
      className="backgroundlogin textcolor"
    >
      <div className="text-center container">
        <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
          Usuario
        </Modal.Title>
        <Modal.Body>
          <Container className="mb-3 mx-2">
            <Form.Row>
              <Form.Group as={Col} xs={12} lg={6} controlId="username">
                <Form.Label className="font-weight-bold">Usuario</Form.Label>
                <Form.Control
                  value={form.username}
                  type="text"
                  placeholder="Ingresa el nombre de usuario"
                  className="borderinput"
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} lg={6} controlId="name">
                <Form.Label className="font-weight-bold">Nombre y Apellido</Form.Label>
                <Form.Control
                  value={form.name}
                  type="text"
                  placeholder="Ingresa nombre"
                  className="borderinput"
                  disabled
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12} lg={6} controlId="phone">
                <Form.Label className="font-weight-bold">Teléfono</Form.Label>
                <Form.Control
                  value={form.phone}
                  type="number"
                  placeholder="Ingresa el numero"
                  className="borderinput"
                  disabled
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} lg={6} controlId="email">
                <Form.Label className="font-weight-bold">Email</Form.Label>
                <Form.Control
                  value={form.email}
                  type="email"
                  placeholder="Ingresa el Email"
                  className="borderinput"
                  disabled
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className="pt-3">
              {
                type &&
                <>
                  <Col xs={12} lg={4}>
                    <Form.Group controlId='partial1'>
                      <Form.Label className="font-weight-bold mr-2">Parcial 1</Form.Label>
                      <Form.Control value={form.partial1} as="select" className="borderinput" onChange={handleChange}>
                        <option value={0}>Ninguno</option>
                        <option value={1}>Parcial</option>
                        <option value={2}>Completo</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='partial1_down'>
                      <Form.Label className="bold">Fecha de Baja Parcial 1</Form.Label>
                      <Form.Control type="date" value={form.partial1_down?.slice(0, 10)} onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={4}>
                    <Form.Group controlId='partial2'>
                      <Form.Label className="font-weight-bold mr-2">Parcial 2</Form.Label>
                      <Form.Control value={form.partial2} as="select" className="borderinput" onChange={handleChange}>
                        <option value={0}>Ninguno</option>
                        <option value={1}>Parcial</option>
                        <option value={2}>Completo</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='partial2_down'>
                      <Form.Label className="bold">Fecha de Baja Parcial 2</Form.Label>
                      <Form.Control type="date" value={form.partial2_down?.slice(0, 10)} onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={4}>
                    <Form.Group controlId='partial3'>
                      <Form.Label className="font-weight-bold mr-2">Parcial 3</Form.Label>
                      <Form.Control value={form.partial3} as="select" className="borderinput" onChange={handleChange}>
                        <option value={0}>Ninguno</option>
                        <option value={1}>Parcial</option>
                        <option value={2}>Completo</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='partial3_down'>
                      <Form.Label className="bold">Fecha de Baja Parcial 3</Form.Label>
                      <Form.Control type="date" value={form.partial3_down?.slice(0, 10)} onChange={handleChange} />
                    </Form.Group>
                  </Col>
                  <Col xs={12} lg={6}>
                    <Form.Group controlId='final'>
                      <Form.Label className="font-weight-bold mr-2">Final</Form.Label>
                      <Form.Control value={form.final} as="select" className="borderinput" onChange={handleChange}>
                        <option value={0}>Ninguno</option>
                        <option value={1}>Parcial</option>
                        <option value={2}>Completo</option>
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId='final_down'>
                      <Form.Label className="bold">Fecha de Baja Final</Form.Label>
                      <Form.Control type="date" value={form.final_down?.slice(0, 10)} onChange={handleChange} />
                    </Form.Group>
                  </Col>
                </>
              }
              <Col xs={12} lg={6}>
                <Form.Group controlId='free'>
                  <Form.Label className="font-weight-bold mr-2">Libre</Form.Label>
                  <Form.Control value={form.free} as="select" className="borderinput" onChange={handleChange}>
                    <option value={0}>Ninguno</option>
                    <option value={1}>Parcial</option>
                    <option value={2}>Completo</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId='free_down'>
                  <Form.Label className="bold">Fecha de Baja Libre</Form.Label>
                  <Form.Control type="date" value={form.free_down?.slice(0, 10)} onChange={handleChange} />
                </Form.Group>
              </Col>
            </Form.Row>

            <Row className="justify-content-center">
              <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
                {errorMsg}
              </Alert>
            </Row>
            <Row className="py-2 justify-content-center">
              <Button variant='light' className="my-3 textcolor bold shadow zoomcampus mr-3" onClick={handleSubmit}><FontAwesomeIcon icon={faSave} className="mr-2 mt-1" />Guardar Cambios</Button>
              <Button variant="light" className="my-3 text-danger" onClick={handleClose}><FontAwesomeIcon icon={faTimes} className="mr-2 mt-1" />Cancelar</Button>
            </Row>
          </Container>
        </Modal.Body>
      </div>
    </Modal>
  );
}