import React, { useState, useContext } from 'react';
import { Container, Row, Col, Image, Form, Card } from "react-bootstrap";
import userContext from '../../context/user/userContext';
import profileMobile from "../Images/profilemobile.png";
import ModalChangesProfile from './ModalChangesProfile';
import ModalChangesImage from './ModalChangesImage';
import student from "../Images/student.gif";
import profile from "../Images/Perfil.png";
import "./UserProfile.css";

export default function UserProfile() {
  const [show, setShow] = useState(false),
  {user} = useContext(userContext);

  return (
    <>
      <Image src={profile} fluid alt="profile-picture" className="d-none d-md-block" />
      <Image src={profileMobile} fluid alt="profile-picture" className="d-block d-md-none"/>
      <Container fluid className="mt-4 pb-4 paddinguser">
        <Card className="py-4 shadowcard">
          <Row className="no-gutters">
            <Col xs={12} lg={5} className="pt-2 pb-3 pb-lg-0 pt-lg-3 text-center">
              <Row className="d-flex flex-column">
                <Col xs={12}>
                  <Image src={(user && user.picture) ||student} className="imgProfile" alt="" />
                </Col> 
                <Col xs={12} className="pt-2 pt-lg-4 mt-3">
                  <ModalChangesImage user={user} />
                </Col>
              </Row>              
            </Col>
            <Col xs={12} lg={7}>
              <Row className="d-flex justify-content-center mx-3 mx-lg-0">
                <Form>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label className="font-weight-bold">
                        Nombre de Usuario
                      </Form.Label>
                      <Form.Control type="text" value={user ? user.username : 'cargando. . .'}  disabled />
                    </Form.Group>
                  </Form.Row>

                  <Form.Row>
                    <Form.Group as={Col} xs={12} lg={6}>
                      <Form.Label className="font-weight-bold">
                        Nombre
                      </Form.Label>
                      <Form.Control type="name" value={user ? user.name : 'cargando. . .'}  disabled />
                    </Form.Group>
                    <Form.Group as={Col} xs={12} lg={6}>
                      <Form.Label className="font-weight-bold">
                        Apellido
                      </Form.Label>
                      <Form.Control type="text" value={user ? user.surname : 'cargando. . .'}  disabled />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label className="font-weight-bold">
                        Teléfono
                      </Form.Label>
                      <Form.Control type="text" value={user ? user.phone : 'cargando. . .'}  disabled />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label className="font-weight-bold">
                        Contraseña
                      </Form.Label>
                      <Form.Control type="password" value={user ? '********' : 'cargando. . .'} disabled  />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Col className="text-center mt-3">
                      <ModalChangesProfile user={user} show={show} setShow={setShow} />
                    </Col>
                  </Form.Row>
                </Form>
              </Row>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}
