import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Image, Card, Accordion, Button, Alert } from "react-bootstrap";
import { faFileAlt, faBook } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalRequestedSubjects from "./ModalRequestedSubjects";
import userContext from '../../context/user/userContext';
import ModalRequestSubject from "./ModalRequestSubject";
import HisAcProMob from "../Images/hisacpromob.png";
import ParProMob from "../Images/parpromob.png";
import FinProMob from "../Images/finpromob.png";
import PerProMob from "../Images/perpromob.png";
import HisAcPro from "../Images/hisacpro.png";
import student from "../Images/student.gif";
import ParPro from "../Images/parpro.png";
import FinPro from "../Images/finpro.png";
import PerPro from "../Images/perpro.png";
import { Link } from "react-router-dom";
import axios from 'axios';
import "./HomeAlt.css";

export default function HomeUser() {
  const [news, setNews] = useState(0),
  [show, setShow] = useState(false),
  { user } = useContext(userContext),
  source = axios.CancelToken.source(),
  [subjects, setSubjects] = useState([]),
  { subject } = useContext(subjectContext),
  [notifications, setNotifications] = useState([]),

  getData = () => {
    axios({
      method: 'get',
      url: `/notifications/all`,
      headers: { 'x-auth-token': user.token },
      cancelToken: source.token
    }).then(({ data }) => setNotifications(data.reverse()))
      .catch(error => console.log('Hubo un problema con la petición: ' + error.message));

    axios({
      method: 'get',
      url: `/subjects/all/auth`,
      headers: { 'x-auth-token': user.token }
    }).then(({ data }) => setSubjects(data))
      .catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  },

  readNotifications = () => {
    if (!show)
      axios({
        method: 'post',
        url: `/users/read_notifications`,
        headers: { 'x-auth-token': user.token }
      }).then(() => setNews(0))
        .catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  },

  userType = () => {
    switch (user.type) {
      case 1: return 'Admin';
      case 2: return 'CPN';
      case 3: return 'LIC';
      case 4: return 'Profesor';
      default: return '';
    }
  };

  useEffect(() => {
    if (user && user.token) getData();

    return () => source.cancel();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (notifications && notifications.length > 0) {
      let unread = 0, last_read = new Date(user.notification_read);
      for (let i = 0; i < notifications.length; i++) if (new Date(notifications[i].created_at) > last_read) unread++;
      setNews(unread);
    }
    // eslint-disable-next-line
  }, [notifications]);

  return (
    <>
      <div className="bgprofile py-2">
        <Container fluid className="pb-3">
          <h1 className="text-center  d-block d-lg-none textcolor pb-5 pt-2 mt-4 mb-2"> Bienvenido {user && user.name}!</h1>
          <Row className="justify-content-center">
            <Col s={12} md={12} lg={3} className="text-center">
              <Card className="text-center ml-2 bg-transparent border-0 ">
                <Card.Body className="text-center mx-2">
                  <Image src={(user && user.picture) || student} roundedCircle width="250" height="250" className="mt-2 mb-2 px-1 py-1 borderprofile" />
                  <p className="bordera textcolor font-weight-bold bg-sm-white bg-md-transparent py-2 py-lg-0 pt-0 pt-lg-3 mt-4 borderhomeprofilemob">
                    {user && `${userType()} ${user.name} ${user.surname}`}
                  </p>
                  <div className="bordera "></div>
                  {
                    user.type !== 1 && user.type !== 4 &&
                    <div className="zoom bg-sm-white bg-md-transparent py-2 borderhomeprofilemob">
                      <Link to="/history" className=" textcolor font-weight-bold py-3 text-decoration-none">
                        <FontAwesomeIcon icon={faFileAlt} /> Historial Académico
                      </Link>
                    </div>
                  }
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={12} lg={6} className="text-center d-none d-lg-block mt-4">
              <h1 className="text-white  d-none d-lg-block bgcolor py-3 mx-5 mt-5 mb-2"> Bienvenido {user && user.name}!</h1>
              <Accordion defaultActiveKey="0">
                <Card className="border-0 bg-transparent mt-5">
                  <div className="d-flex justify-content-center">
                    <div className="bordernotification ">
                      <h4 className="newsnotification m-0">{news}</h4>
                    </div>
                  </div>
                  <Card.Header className="bg-transparent border-0 mt-0 pt-0 ">
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      className="borderhomehistory px-3 zoom textcolor font-weight-bold text-decoration-none bg-white"
                      onClick={() => { setShow(p => !p); readNotifications(); }}
                      eventKey="show"
                    >
                      Noticias
                    </Accordion.Toggle>
                  </Card.Header>
                  {
                    news > 0 &&
                    <h4 className="textcolor mt-4 py-2 borderhomehistory bg-white">
                      No te olvides de revisar las ultimas noticias !!
                    </h4>
                  }

                  <Accordion.Collapse eventKey="show">
                    <Row className="px-1 py-1 mx-1 bg-white borderhomehistory d-flex flex-column justify-content-center text">
                      {
                        notifications && notifications.length > 0 ? notifications.map(n =>
                          <Col xs={12} className="text-center" key={n._id}>
                            <Alert variant="info" className="my-1 d-inline-flex">
                              <p className="m-0">
                                <span className="font-weight-bold mr-1">
                                  {n.title}
                                </span>
                                {n.content}
                              </p>
                            </Alert>
                          </Col>) :
                          <Col xs={12} className="text-center">
                            <h4 className="textcolor py-2">
                              No hay noticias recientes !!
                          </h4>
                          </Col>
                      }
                    </Row>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid className="pb-1">
        <Row className="justify-content-center">
          <Col xs={12} className="text-center d-block d-lg-none">
            <Accordion defaultActiveKey="0">
              <Card className="border-0 bg-transparent">
                <Card.Header className="bg-transparent border-0 mt-4 pt-0 ">
                  <div className="d-flex justify-content-center">
                    <div className="bordernotification ">
                      <h4 className="newsnotification m-0">{news}</h4>
                    </div>
                  </div>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    className="borderhomehistory px-3 textcolor font-weight-bold text-decoration-none bg-white"
                    eventKey="1"
                  >
                    Noticias
                  </Accordion.Toggle>
                </Card.Header>
                <h4 className="textcolor mt-4 py-2 borderhomehistory bg-white d-none d-lg-block">
                  No te olvides de revisar las ultimas noticias !!
                </h4>
                <h5 className="textcolor mt-4 mx-0 py-2  borderhomehistory bg-white d-block d-lg-none">
                  No te olvides de revisar las ultimas noticias !!
                </h5>

                <Accordion.Collapse eventKey="1">
                  <Row className="px-1 py-1 mx-1 bg-white borderhomehistory d-flex flex-column justify-content-center text">
                    {
                      notifications && notifications.length > 0 && notifications.map(n =>
                        <Col xs={12} className="text-center" key={n._id}>
                          <Alert variant="info" className="my-1 d-inline-flex">
                            <p className="m-0">
                              <span className="font-weight-bold mr-1">
                                {n.title}
                              </span>
                              {n.content}
                            </p>
                          </Alert>
                        </Col>)
                    }
                  </Row>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Container className="py-2">
        <div className="text-center my-4" data-aos="fade-down" data-aos-delay="200">
          <h1 className="textcolor bold">Tus Materias</h1>
        </div>
        <Row className="justify-content-center align-items-center">
          {
            subjects.map(s =>
              <Col key={s._id} xs={12} md={4} className="mt-4 text-center">
                <Link to={`/subject/${s._id}`} className="btn text-decoration-none shadow borderhome zoomcampus px-4 pt-2 pb-1">
                  <p className="mb-0 textcolor d-inline-flex bold">
                    <FontAwesomeIcon icon={faBook} className="fa-lg mr-2 mt-1" />
                    {s.name}
                  </p>
                </Link>
              </Col>
            )
          }

        </Row>
      </Container>
      <Container className="py-2">
        {
          user && user !== 1 && user !== 4 &&
          <Col xs={12} md={12} className="mt-4 text-center">
            <ModalRequestSubject />
          </Col>
        }
      </Container>
      <Container className="py-4">
        {
          user && user !== 1 && user !== 4 &&
          <>
            <div className="text-center my-4" data-aos="fade-down" data-aos-delay="200">
              <h1 className="textcolor bold">Tus Solicitudes</h1>
            </div>
            <Col xs={12} md={12} className="mt-4 text-center">
              <ModalRequestedSubjects />
            </Col>
          </>
        }
      </Container>
      <Container className="py-1 mt-0 pt-0">
        <Row className=" text-center mt-5 justify-content-center">
          {
            subject.current &&
            <>
              <Col xs={12} data-aos="fade-down" data-aos-delay="200">
                <Link to="/parcial" className="textcolor mr-3 text-decoration-none">
                  <Image src={ParPro} fluid alt="banner" className="d-none d-md-block zoom" />
                  <Image src={ParProMob} fluid alt="banner" className="d-block d-md-none zoom" />
                </Link>
              </Col>
              <Col xs={12} data-aos="fade-down" data-aos-delay="300">
                <Link to="/final" className="textcolor mr-3 text-decoration-none">
                  <Image src={FinPro} fluid alt="" className="d-none d-md-block zoom" />
                  <Image src={FinProMob} fluid alt="" className="d-block d-md-none zoom" />
                </Link>
              </Col>
            </>
          }
          <Col xs={12} data-aos="fade-down" data-aos-delay="400">
            <Link to="/profile" className="textcolor mr-3 text-decoration-none">
              <Image src={PerPro} fluid alt="profile-picture" className="d-none d-md-block zoom" />
              <Image src={PerProMob} fluid alt="profile-picture" className="d-block d-md-none zoom" />
            </Link>
          </Col>
          {
            user.type !== 1 && user.type !== 4 &&
            <Col xs={12} data-aos="fade-down" data-aos-delay="500">
              <Link to="/history" className=" textcolor mr-3 text-decoration-none">
                <Image src={HisAcPro} fluid alt="profile-picture" className="d-none d-md-block zoom" />
                <Image src={HisAcProMob} fluid alt="profile-picture" className="d-block d-md-none zoom" />
              </Link>
            </Col>
          }
        </Row>
      </Container>
    </>
  );
}