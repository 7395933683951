import React, { useState, useContext } from 'react';
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import { Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function TestSwitches({ file, onEdit, onDelete }) {
    const [visible, setVisible] = useState(file.is_visible),
    [available, setAvailable] = useState(file.is_available),
    { user } = useContext(userContext),

    handleChange = ({target}, type) => {
        let data = type ? { is_visible: !visible } : { is_available: !available };
        axios({
            method: 'post',
            url: `/tests/edit/${file._id}`, 
            headers: { 'x-auth-token': user.token },
            data
          }).then(response => {
            if(response.status === 200) {
                type ? setVisible(!visible) : setAvailable(!available);
                Swal.fire({
                    title: 'Hecho!', 
                    text: `El examen ${file.title} se encuentra ahora ${target.checked ? type ? 'visible' : 'habilitado' : type ? 'no visible' : 'deshabilitado'}`, 
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
            } else {
                Swal.fire({
                    title: 'No se pudo completar la acción',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
                throw new Error(response.data.msg);
            };
        }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
    };

    return (
        <>
            <Form className="col mt-2 mt-lg-0 text-left">
                <Form.Check 
                    type="switch"
                    checked={visible}
                    id={file._id + Math.random()}
                    onChange={e => handleChange(e, true)}
                    label="Visible"
                />
                <Form.Check 
                    type="switch"
                    checked={available}
                    id={file._id + Math.random() + 1}
                    onChange={e => handleChange(e, false)}
                    label="Habilitado"
                />
            </Form>
            <Button variant="light" className="mr-4 px-3 bg-transparent border-0 zoomuser" disabled={visible} onClick={() => onEdit(file)}>
                <FontAwesomeIcon icon={faEdit} className="text-warning fa-lg" />
            </Button>
            <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" onClick={() => onDelete(file)}>
                <FontAwesomeIcon icon={faTrashAlt} className="text-danger fa-lg" />
            </Button>
        </>
    );
}
