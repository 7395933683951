import React, { useEffect, useState } from "react";
import { faCheckCircle, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CountdownTimer from "react-component-countdown-timer";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import "./Review.css";

export default function Review({ result }) {
  const history = useHistory(),
  [check, setCheck] = useState([]),

  finish = () => history.push('/history');

  useEffect(() => {
    let checks = [];
    result.test.answers.forEach((a, idx) => {
      checks[idx] = false;
      if (a === result.answers[idx]) {
        checks[idx] = true;
      }
    });
    setCheck(checks);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="fondocolor">
      <div className="bgreview">
        <Container className="py-5 text-center mb-4">
          <div className="text-center py-2">
            <h2 className="textcolor font-weight-bold mb-3 text-capitalize">Revisión del Examen {result.test.title}</h2>
            <h5 className="textcolor font-weight-bold mb-2 d-inline-flex">
              <FontAwesomeIcon icon={faClock} className="textcolor mr-2 mt-1 " />
              <CountdownTimer count={900} responsive hideDay onEnd={finish} />
            </h5>
          </div>
          <h5 className="textcolor font-weight-bold">Nota del examen: <span className="font-weight-bold">{result.result}</span></h5>
            <Row className="justify-content-center mr-1 py-1 mb-2 textcolor font-weight-bold">
              {
                check.map((c, i) => c ?
                <FontAwesomeIcon key={i}
                  icon={faCheckCircle}
                  className="text-success mx-1 mt-1 fa-lg"
                /> :
                <FontAwesomeIcon key={i}
                  icon={faTimesCircle}
                  className="text-danger mx-1 mt-1 fa-lg"
                />)
              }
            </Row>
            <div className="mt-4 text-center border-">
              <Link to="/history" className="textcolor bg-white font-weight-bold borderreview text-decoration-none px-4 py-2">
                <FontAwesomeIcon icon={faCheck} className="mr-1" />Finalizar Revisión
              </Link>
            </div>
          </Container>
        </div>
        <Container>
        <Row className="justify-content-center">
          {
            result.test.questions.map((q, i) =>
            <Col xs={12} md={6} className="my-2" key={i}>
              <Card className="borderreview shadowcards">
                <Card.Header as="h5" className="textcolor py-3 ">
                  {q.question}
                </Card.Header>
                <Card.Body>
                  {
                    result.answers[i] === result.test.answers[i] ?
                    <Card className="pt-2 pb-1 my-2 text-center border-success shadowcards">
                      <Row>
                        <Col xs={2} className="mt-2">
                          <FontAwesomeIcon icon={faCheckCircle} className="text-success ml-1 fa-lg" />
                        </Col>
                        <Col xs={10} className="text-left pt-1">
                          <p className="textcolor">{result.answers[i]}</p>
                        </Col>
                      </Row>
                    </Card> :
                    <Card className="pt-2 pb-1 my-2 text-center border-danger shadowcards">
                      <Row>
                        <Col xs={2} className="mt-1">
                          <FontAwesomeIcon icon={faTimesCircle} className="text-danger ml-1 fa-lg" />
                        </Col>
                        <Col xs={10} className="text-left pt-1">
                          <p className="textcolor">{result.answers[i] || "No seleccionaste una respuesta"}</p>
                        </Col>
                      </Row>
                    </Card>
                  }
                  <p className="text-success font-weight-bold">
                    Respuesta Correcta: {result.test.answers[i]}
                  </p>
                </Card.Body>
              </Card>
            </Col>)
          }
        </Row>
        <div className="mt-4 py-5 text-center">
          <Link to="/history" className="textcolor bg-white font-weight-bold borderreview text-decoration-none px-4 py-2">
            <FontAwesomeIcon icon={faCheck} className="mr-1" />Finalizar Revisión
          </Link>
        </div>
      </Container>
    </div>
  );
}