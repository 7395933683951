import React, { useRef } from "react";
import loading from "../Images/loading.gif";
import "./Loading.css";

export default function Loading({ width = "100", height = "100" }) {
    const modal = useRef();


  return (
      <div
        className="modal loading"
        ref={modal}
        tabIndex="10"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <img width={width} height={height} src={loading} alt="loading" />
        </div>
      </div>
  );
}
