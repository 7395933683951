import React, { useState } from 'react';
import { Button, Form, Row, Col } from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ViewItem({ user, selectedUser, content }) {
  const [value, setValue] = useState(content.times),
  handleChange = ({target}) => setValue(target.value),
  handleSubmit = e => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `/users/edit_watched`,
      headers: { 'x-auth-token': user },
      data: { selectedUser, content_viewed: { id: content.file._id, times: parseInt(value) } }
    }).then(() => {
      Swal.fire({
        title: 'Hecho',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
    }).catch(error => {
      Swal.fire({
        title: 'Error',
        icon: 'error',
        text: 'Vuelva a intentarlo o contacte a soporte',
        timer: 2000,
        showConfirmButton: false
      });
      console.log('Hubo un problema con la petición: ' + error.message);
    });
  };

  return (
    <Row>
      <Col xs={6}>
        {content.file.title}
      </Col>
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Col>
            <Form.Group as={Col} xs={12}>
              <Form.Label className="font-weight-bold">Visualizaciones</Form.Label>
              <Form.Control value={value} type="number" className="borderinput input-view" onChange={handleChange} />
            </Form.Group>
          </Col>
          <Col>
            <Button type="submit" variant="link" className="textcolor font-weight-bold text-decoration-none mt-4">Actualizar</Button>
          </Col>
        </Form.Row>
      </Form>
    </Row>
  );
}