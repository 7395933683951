import React, { useState } from 'react';
import { faUserEdit, faTimes, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Form, Row, Col, Alert, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import student from "../Images/student.gif";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalChangesImage({ user }) {
  const [show, setShow] = useState(false),
  [errorMsg, setErrorMsg] = useState(''),
  [infoMsg, setInfoMsg] = useState(' '),
  [picture, setPicture] = useState(''),
  
  handleChange = async ({ target }) => {
    setErrorMsg('');
    if(target.files.length > 0) {
      if(target.files[0].size > 2097152) {
        target.value = null;
        setErrorMsg(`La imagen es demasiado grande.`);
        setPicture('');
        return;
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(target.files[0]);
        reader.onloadend = () => setPicture(reader.result);
      }
    } else setPicture('');    
  },

  handleSubmit = e => {
    e.preventDefault();
    if (picture === user.picture) {setErrorMsg('La nueva imagen no puede ser igual a la anterior'); return;};
    axios({
      method: 'post',
      url: `/users/edit/${user._id}`, 
      headers: { 'x-auth-token': user.token },
      data: { picture: picture }
    }).then(() => {
      Swal.fire({
        title: 'Hecho!', 
        text: `Imagen Actualizada!`, 
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
      handleClose();
    }).catch(() => 
      Swal.fire({
        title: 'Hubo un error', 
        text: `No se pudo actualizar la imagen`, 
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      })
    );
  },

  handleClose = () => {
    setShow(false);
    setPicture('');
    setErrorMsg('');
    setInfoMsg(' ');
  },
  handleShow = () => setShow(true);

  return (
    <>
      <Button variant="light" className="textcolor py-2 px-3 px-lg-5" onClick={handleShow}>
        <FontAwesomeIcon icon={faUserEdit} className="mr-2 mt-1 fa-lg" />Cambiar Imagen
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered
        className="backgroundlogin textcolor"
      >
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            Cambiar Imagen
          </Modal.Title>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Image src={picture || (user && user.picture) || student} className="imgProfile" alt="" />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Label className="font-weight-bold">Imagen</Form.Label>
                  <Form.Control type="file" className="borderinput" onChange={handleChange} accept="image/*" />
                </Form.Group>
              </Form.Row>
              <Alert variant="info" dismissible onClose={() => setInfoMsg('')} show={infoMsg !== ''}>
                El cambio de la foto de perfil se verá reflejado una vez que actualices la página
              </Alert>
              <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
                {errorMsg}
              </Alert>
              <Row className="text-center pt-4 pb-3 justify-content-center">
                <Button variant="light" type="submit" className="mr-5 shadowcards textcolor">
                  <FontAwesomeIcon icon={faEdit} className="mr-2 textcolor" />Editar
                </Button>
                <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
                </Button>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}