import React, { useState, useEffect, useContext } from "react";
import { faLaughBeam, faSmileBeam, faSmile, faFrown, faCheckCircle, faExclamation, faTrophy, faBookReader } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Image, ProgressBar, Card, Form } from "react-bootstrap";
import subjectContext from '../../context/subject/subjectContext';
import { faHandshake } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import noresult from "../Images/noresult.gif";
import student from "../Images/student.gif";
import Contact from "../Home/Contact";
import axios from 'axios';
import "./History.css";

export default function History() {
  const { user } = useContext(userContext),
  [filtered, setFiltered] = useState([]),
  {subject} = useContext(subjectContext),
  [history, setHistory] = useState(null),
  [average, setAverage] = useState(''),
  medium = 7, low = 4.5,

  userType = () => {
    switch(user.type) {
      case 1: return 'Admin';
      case 2: return 'CPN';
      case 3: return 'LIC';
      case 4: return 'Prof.';
      default: return '';
    }
  },

  historyFilter = e => {
    if (e.target.value) {
      let aux = history;
      setFiltered(aux.filter(h => h.test.section === e.target.value));
    } else setFiltered(history);
  },

  getHistory = () => {
    axios({
      method: 'get',
      url: `/history/all`, 
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current }
    }).then(response => {
      setHistory(response.data);
      setFiltered(response.data);
    }).catch(() => {
      getHistory();
    });
  };

  useEffect(() => {
		if (user && user.token) {
      if (history === null) getHistory();
      else {
        let avg = 0;
        if (history.length > 0) {
          history.forEach(h => avg += parseInt(h.result));
          setAverage(avg/history.length);
        }
      }
    }
    // eslint-disable-next-line
  }, [user, history]);

  if (user?.type !== 1 && user?.type !== 4)
  return (
    <>
      <div className="bghistory">
        <Container className="pb-5">
          <Row>
            <Col xs={12} lg={4} className="text-center">
              <Image src={(user && user.picture) || student} roundedCircle width="250" height="250" className="mt-4 px-1 py-1 borderprofile" />
            </Col>
            <Col xs={12} lg={8} className="text-center mt-4 mt-lg-0">
              <h1 className="textcolor mt-5 py-4 d-none d-lg-block"> {user && `${userType()} ${user.name} ${user.surname}`} </h1>
              <div className="px-5 py-1 mx-1 mx-lg-5 bg-white borderexamhistory">
                <h5 className="my-2 textcolor">Promedio de exámenes totales</h5>
                <Row className="d-flex justify-content-center">
                  <Col xs={6} md={4} className="text-right">
                    <ProgressBar
                      variant={average <= low ? 'danger' : average <= medium ? 'warning' : 'success'}
                      className="mt-1"
                      animated
                      now={average*10}
                    />
                  </Col>
                  <Col xs={3} md={2} className="text-left">
                    <p className="textcolor font-weight-bold">{String(average).slice(0,3)}</p>
                  </Col>
                </Row>
                {
                  average >= medium ? 
                  <p className="py-0 textcolor font-weight-bold">
                    Vas por un excelente camino. Continua así
                    <FontAwesomeIcon icon={faExclamation} className="textcolor ml-2 fa-lg" />
                    <FontAwesomeIcon icon={faExclamation} className="textcolor ml-1 fa-lg" />
                    <FontAwesomeIcon icon={faTrophy} className="text-success ml-5 fa-lg" />
                  </p>
                  : average >= low ? 
                  <p className="py-0 text-warning font-weight-bold">
                    Continua esforzándote para lograr un mejor desempeño
                    <FontAwesomeIcon icon={faExclamation} className="text-warning ml-2 fa-lg" />
                    <FontAwesomeIcon icon={faExclamation} className="text-warning ml-1 fa-lg" />
                    <FontAwesomeIcon icon={faHandshake} className="text-warning ml-5 fa-lg" />
                  </p>
                  : average === "" ? 
                  <p className="py-0 textcolor font-weight-bold">
                    Todavia no has rendido ningun examen
                    <FontAwesomeIcon icon={faExclamation} className="textcolor ml-2 fa-lg" />
                    <FontAwesomeIcon icon={faExclamation} className="textcolor ml-1 fa-lg" />
                  </p>
                  :
                  <p className="py-0 text-danger font-weight-bold">
                    Esfuerzate para lograr un mejor desempeño
                    <FontAwesomeIcon icon={faExclamation} className="text-danger ml-2 fa-lg" />
                    <FontAwesomeIcon icon={faExclamation} className="text-danger ml-1 fa-lg" />
                    <FontAwesomeIcon icon={faBookReader} className="text-danger ml-5 fa-lg" />
                  </p>

                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Container className=" mt-1 py-5 heightcontainer">
        <h3 className="text-center textcolor font-weight-bold pb-4">
          Historial Academico
        </h3>
        <Row className="justify-content-end">
          <Col xs={12} lg={6} className="mt-1 text-center text-lg-right">
            <p className="textcolor font-weight-bold">Filtrar por examen</p>
          </Col>
          <Col xs={12} lg={3}>
            <Form>
              <Form.Group controlId="type">
                <Form.Control as="select" custom className="shadowcards textcolor font-weight-bold" onChange={historyFilter}>
                  <option value="" className="textcolor font-weight-bold">Todos</option>
                  <option value="partial1" className="textcolor font-weight-bold">Parcial 1</option>
                  <option value="partial2" className="textcolor font-weight-bold">Parcial 2</option>
                  <option value="partial3" className="textcolor font-weight-bold">Parcial 3</option>
                  <option value="final" className="textcolor font-weight-bold">Final</option>
                  <option value="final" className="textcolor font-weight-bold">Libre</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        {
          filtered && filtered.length > 0 ?
          filtered.map(h =>
          <Card className="pt-3 pb-2 px-3 my-2 text-center shadowcards" key={h._id}>
            <Row>
              <Col xs={8} md={3} className="mt-1 mt-md-2 mt-lg-1">
                <h5 className="textcolor font-weight-bold">{h.test.title}</h5>
              </Col>
              <Col xs={4} md={3} className="mt-0 mt-md-3 mt-lg-0">
                <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2 mt-1 mt-1 fa-lg" />
              </Col>
              <Col xs={8} md={4} className="mt-4 mt-lg-1 text-right">
                <Row className="justify-content-center">
                  <Col xs={6} className="text-right">
                    <ProgressBar
                      variant={h.result <= low ? 'danger' : h.result <= medium ? 'warning' : 'success'}
                      className="mt-1"
                      animated
                      now={h.result*10}
                    />
                  </Col>
                  <Col xs={3} className="text-left">
                    <p className={`${h.result <= low ? 'text-danger' : h.result <= medium ? 'text-warning' : h.result < 9 ? 'text-success' : 'colorgreen'} font-weight-bold`}>{String(h.result).slice(0,3)}</p>
                  </Col>
                </Row>
              </Col>
              <Col xs={4} md={2} className="mt-3 mt-lg-1">
                <FontAwesomeIcon
                  icon={h.result <= low ? faFrown : h.result <= medium ? faSmile : h.result < 9 ? faSmileBeam : faLaughBeam}
                  className={`${h.result <= low ? 'text-danger' : h.result <= medium ? 'text-warning' : h.result < 9 ? 'text-success' : 'colorgreen'} mr-2 fa-2x`}
                />
              </Col>
            </Row>
          </Card>) :
          <div className="text-center mt-4">
              <Image src={noresult} alt="result"  className="borderimage"/>
          </div>
        }
      </Container>
    </>
  );
  return (
    <>
      <div className="bghistory">
        <Container className="pb-5">
          <Row>
            <Col xs={12} lg={4} className="text-center">
              <Image src={(user && user.picture) || student} roundedCircle width="250" height="250" className="mt-4 px-1 py-1 borderprofile" />
            </Col>
            <Col xs={12} lg={8} className="text-center mt-4 mt-lg-0">
              <h1 className="textcolor mt-5 py-4 d-none d-lg-block"> {user && `${userType()} ${user.name} ${user.surname}`} </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="text-center py-5">
        <h3>No Tienes Exámenes que rendir ya que eres el profesor</h3>
        <h4>Dirígete a Profesor, Exámenes para revisar el progreso de tus alumnos</h4>
        <h5>Cualquier duda no dudes en contactarnos</h5>
        <Contact />
      </div>
    </>
  );
}
