import React, { useState, useEffect, useContext } from 'react';
import { faCaretRight, faEye } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Button, Card, Image, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import SearchBar from '../SearchBar/SearchBar';
import noresult from "../Images/noresult.gif";
import ModalHomepage from "./ModalHomepage";
import Paged from './Paged';
import axios from 'axios';

export default function AdminHomepage() {
  const [itemsPerPage, setItemsPerPage] = useState(15),
  [selectedSubject, setSelectedSubject] = useState({_id: ''}),
  [filtered, setFiltered] = useState([]),
  [subjects, setSubjects] = useState([]),
  [lastPage, setLastPage] = useState(0),
  source = axios.CancelToken.source(),
  [show, setShow] = useState(false),
  {user} = useContext(userContext),
  [page, setPage] = useState(0),
  
  getSubjects = () => {
    axios({
      method: 'get',
      url: `/subjects/all/auth`,
      cancelToken: source.token,
      headers: { 'x-auth-token': user.token }
    }).then(response => {
      if(response.status === 200) setSubjects(response.data);
      else throw new Error(response.data.msg);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  },

  onEdit = s => {
    setSelectedSubject(s);
    setShow(true);
  };

  useEffect(() => {
		if (user && user.token && user.type === 4 && !show) getSubjects();
    return () => source.cancel();
    // eslint-disable-next-line
	}, [user, show]);

  useEffect(() => {
    setPage(0);
		if (filtered && filtered.length > 0) setLastPage(Math.floor(filtered.length / itemsPerPage));
    else setLastPage(-1);
    // eslint-disable-next-line
	}, [filtered, itemsPerPage]);

  return (
    <>
      <Row className="py-2">
        <SearchBar content={subjects} setFiltered={setFiltered} fields={["name"]} />
        <Col xs={12} md={3} className="py-3 py-md-0">
          <ModalHomepage show={show} setShow={setShow} selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} />
        </Col>
      </Row>
      <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">Mis Materias</h4>
        </Card.Header>
        <Card.Body>
        <Row className="mt-2 d-none d-lg-flex">
          <Col xs={5} className="d-flex flex-row">
            <h5 className="ml-2">Nombre</h5>
          </Col>
          <Col xs={4}>
            <h5 className="ml-2">Tipo</h5>
          </Col>
          <Form.Group as={Col} xs={3} controlId="item" className="d-inline-flex">
            <Form.Label>Materias por página</Form.Label>
            <Form.Control value={itemsPerPage} as="select" className="borderinput" onChange={({target}) => setItemsPerPage(target.value)}>
              <option value={15}>15</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
            </Form.Control>
          </Form.Group>
        </Row>
        {
          filtered && filtered.length > 0 ?
          <>
            {
            filtered.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage).map(subject =>
              <Row key={subject._id} className="pt-3">
                <Col lg={5} className="d-none d-lg-flex flex-row">
                  <FontAwesomeIcon icon={faCaretRight} className="ml-2 mt-1" />
                  <h5 className="ml-2">{subject.name}</h5>
                </Col>
                <Col xs={4}>
                  <h5 className="ml-2">{subject.type ? 'Completa' : 'Libre'}</h5>
                </Col>
                <Col lg={3} className="d-none d-lg-flex flex-row text-right">
                  <Button variant="light" className="px-3 bg-transparent border-0 zoomuser" onClick={() => onEdit(subject)}>
                    <FontAwesomeIcon icon={faEye} className="text-warning fa-lg" />
                  </Button>
                </Col>
                <Col xs={12} className="d-inline-flex d-lg-none">
                  <h5 className="pr-2">Nombre:</h5>
                  <FontAwesomeIcon icon={faCaretRight} className="ml-2 mt-1" />
                  <h5 className="ml-2">{subject.name}</h5>
                </Col>
                <Col xs={9} className="d-inline-flex d-lg-none">
                  <h5>Tipo: {subject.type ? 'Completa' : 'Libre'}</h5>
                </Col>
                <Col xs={3} className="d-inline-flex d-lg-none justify-content-end">
                  <Button variant="light" className=" px-3 bg-transparent border-0 zoomuser" onClick={() => onEdit(subject)}>
                    <FontAwesomeIcon icon={faEye} className="text-warning fa-lg" />
                  </Button>
                </Col>
              </Row>
            )}
            <Paged page={page} setPage={setPage} lastPage={lastPage} />
          </> :  
          <div className="text-center">
            <Image src={noresult} fluid alt="" className="shadow-lg rounded" />
          </div>
        }
        </Card.Body>
      </Card>
    </>
  );
}