import React, { useState, useEffect, useContext } from 'react';
import { Alert, Modal, Button, Form, Row, Col } from "react-bootstrap";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalSubject({ show, setShow, selectedSubject, setSelectedSubject }) {
  const emptyForm = { name: '', teacher: '', category: '', description: '', sections: [{ section: 'Libre', text: ''}, { section: '', text: ''}, { section: '', text: ''}], students: [], type: false },
  [form, setForm] = useState(emptyForm),
  [sections, setSections] = useState([0]),
  [students, setStudents] = useState([]),
  [teachers, setTeachers] = useState([]),
  [errorMsg, setErrorMsg] = useState(''),
  {update} = useContext(subjectContext),
  {user} = useContext(userContext),

  getTeachers = () => {
    axios({
      method: 'get',
      url: `/users/teachers`, 
      headers: { 'x-auth-token': user.token }
    }).then(response => {
      if (response.status === 200) setTeachers(response.data.filter(u => !u.is_deleted));
      else throw new Error(response.data.msg);
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  },

  getStudents = () => {
    axios({
      method: 'post',
      url: `/subjects/students`,
      headers: { 'x-auth-token': user.token },
      data: { subject: selectedSubject._id }
    }).then(response => {
      if (response.status === 200) setStudents(response.data.sort((a, b) => a.name.localeCompare(b.name)));
      else throw new Error(response.data.msg);
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  },

  setFullType = () => {
    let sections = form.sections;
    sections[0].section = "Parcial";
    sections[1].section = "Final";
    sections[2].section = "Libre";
    setSections([0, 1, 2]);
    setForm({ ...form, type: true, sections });
  },

  setFreeType = () => {
    let {sections} = form;
    sections[0].section = "Libre";
    setSections([0]);
    setForm({ ...form, type: false, sections });
  },

  handleChange = ({target}) => setForm({...form, [target.id]: target.value}),

  handleChangeText = ({value}, i) => {
    let {sections} = form;
    sections[parseInt(i)].text = value;
    setForm({...form, sections});
  },

  handleClose = () => {
    setShow(false);
    setErrorMsg('');
    setForm(emptyForm);
    setSelectedSubject({_id: ''});
  },

  handleShow = () => setShow(true),

  handleSubmit = e => {
    e.preventDefault();
    if (form.name.length < 3)  {setErrorMsg('El nombre es demasiado corto'); return;};
    if (form.name.length > 40) {setErrorMsg('El nombre es demasiado largo'); return;};
    if (form.teacher.length < 3)  {setErrorMsg('El profesor es obligatorio'); return;};
    if (form.category.length < 3)  {setErrorMsg('La categoría es demasiado corta'); return;};
    let sections = form.type ? form.sections : [form.sections[0]];
    axios({
      method: 'post',
      url: `/subjects/${selectedSubject._id ? `edit/${selectedSubject._id}` : 'create'}`, 
      headers: { 'x-auth-token': user.token },
      data: { ...form, sections }
    }).then(response => {
      if(response.status === 200) {
        update({ update: true });
        Swal.fire({
          title: 'Hecho!', 
          text: `Materia ${selectedSubject._id ? 'Modificada' : 'Creada'}!`,
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        handleClose();
      } else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  };

  useEffect(() => {
    if (show) {
      if (selectedSubject._id) {
        let {sections} = selectedSubject;
        if (sections.length < 3) for (let i = sections.length; i < 4; i++) sections.push({ section: '', text: '' });
        if (selectedSubject.type) setSections([0, 1, 2]);
        setForm({ ...selectedSubject, teacher: selectedSubject.teacher._id, sections });
        getStudents();
      } else {
        setForm(emptyForm);
        setStudents([]);
      }
    }
    // eslint-disable-next-line
  }, [show, selectedSubject]);

  useEffect(() => getTeachers() // eslint-disable-next-line
    , []);

  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} className="textcolor mr-1 fa-lg" />
        Agregar
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        centered
        className="backgroundlogin textcolor"
      >
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            { selectedSubject._id ? 'Modificar ' : 'Crear ' }
            Materia
          </Modal.Title>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="teacher">
                  <Form.Label className="font-weight-bold">Profesor</Form.Label>
                  <Form.Control value={form.teacher} as="select" className="borderinput" onChange={handleChange}>
                    <option value=''>Seleccioná uno . . .</option>
                    { teachers.map(t => <option key={t._id} value={t._id}>{`${t.name} ${t.surname}`}</option>) }
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} controlId="name">
                  <Form.Label className="font-weight-bold">Materia</Form.Label>
                  <Form.Control
                    value={form.name}
                    type="text"
                    placeholder="Nombre de la materia"
                    className="borderinput"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row className="pt-3">
                <Form.Group as={Col} xs={12} lg={6} controlId="category">
                  <Form.Label className="font-weight-bold">Categoría</Form.Label>
                  <Form.Control
                    required
                    value={form.category}
                    type="text"
                    placeholder="Ingresa categoría"
                    className="borderinput"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} controlId="description">
                  <Form.Label className="font-weight-bold">Descripción</Form.Label>
                  <Form.Control
                    required
                    value={form.description}
                    type="text"
                    placeholder="Ingresa descripción"
                    className="borderinput"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Label className="font-weight-bold">Alumnos</Form.Label>
                  {
                    students.length > 0 ?
                    students.map(s => <p key={s._id}>{s.name}</p>) :
                    <h4>Aún no hay alumnos registrados</h4>
                  }
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6}>
                  <Form.Label className="font-weight-bold mr-2">Tipo</Form.Label>
                  <Form.Check inline checked={form.type} label="Completa" type="radio" onChange={setFullType} />
                  <Form.Check inline checked={!form.type} label="Libre" type="radio" onChange={setFreeType} />
                </Form.Group>
              </Form.Row>
              <Form.Label className="font-weight-bold mt-3">Secciones</Form.Label>
              {
                sections.map(s =>
                <Form.Row key={s}>
                  <Form.Group as={Col} xs={12}>
                    <Form.Label className="font-weight-bold">{form.sections[s].section}</Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={12}>
                    <Form.Control
                      value={form.sections[s].text}
                      type="text"
                      placeholder='Ingresá el texto. Cada "-" indica un nuevo ítem en la lista'
                      className="borderinput"
                      onChange={({target}) => handleChangeText(target, s)}
                    />
                  </Form.Group>
                </Form.Row>
                )
              }
              <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
                {errorMsg}
              </Alert>
              <Row className="text-center pt-4 pb-3 justify-content-center">
                <Button variant="light" type="submit" className="mr-5 shadowcards textcolor">
                  <FontAwesomeIcon icon={faPlus} className="mr-2 textcolor" />{ selectedSubject._id ? 'Editar' : 'Agregar' }
                </Button>
                <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
                </Button>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}