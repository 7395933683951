export default (state, action) => {
    switch(action.type) {
        case 'INICIAR_SESION':
            localStorage.setItem('CampusEnzoUser', JSON.stringify(action.payload));
            return {...action.payload};
        case 'ACTUALIZAR_USUARIO': return {...state, ...action.payload};
        case 'CERRAR_SESION':
            localStorage.removeItem('CampusEnzoUser');
            localStorage.removeItem('campus-subject');
            return { name: '', username: '', token: '', type: 0, surname: '' };
        default: return state;
    }
}