import React from "react";
import { Image } from "react-bootstrap";
import logo from "../Images/logo.png"
import './Footer.css'

export default function Footer() {
  return (
    <footer className="text-center py-4 font-weight-bold bordertop textcolor">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h5 className="textcolor d-inline-flex">
          <Image src={logo} width="80" fluid className="mr-2 fa-lg mt-1" /> <span className="mt-3 mr-1">Mi <span className="mt-3 mr-1 orange">Aula</span> &copy; Todos los derechos reservados</span>
        </h5>
        <div>
          <h5 className="textcolor d-inline-flex">
            Design by
          </h5>
          <a href="https://www.sd-software.com" target="_blank" rel="noopener noreferrer" className="text-decoration-none textcolor ml-1">SD Software</a>
        </div>
      </div>
    </footer>
  );
}
