import React, { useState, useEffect, useContext } from 'react';
import { Alert, Modal, Button, Form, Row, Col } from "react-bootstrap";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import Student from './Student';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalUser({ show, setShow, selectedUser, setSelectedUser }) {
  const emptyForm = { name: '', username: '', surname: '', password: '', note: '', type: 0, email: '', enabled: true, phone: '' },
  [subjects, setSubjects] = useState({ available: [], at: [], new: false }),
  [disabled, setDisabled] = useState(false),
  [errorMsg, setErrorMsg] = useState(''),
  [form, setForm] = useState(emptyForm),
  {user} = useContext(userContext),

  getSubjects = () => {
    axios({
      method: 'post',
      url: `/subjects/student_data`,
      headers: { 'x-auth-token': user.token },
      data: { user: selectedUser._id }
    }).then(response => {
      if (response.status === 200) setSubjects(response.data);
      else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        handleClose();
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  },

  handleChange = ({target}) => setForm({...form, [target.id]: target.value}),

  handleClose = () => {
    setShow(false);
    setErrorMsg('');
    setForm(emptyForm);
    setSelectedUser({_id: ''});
  },

  handleShow = () => setShow(true),

  handleSubmit = e => {
    e.preventDefault();
    if (form.username.length < 3)  {setErrorMsg('El nombre de usuario es demasiado corto'); return;}
    if (form.username.length > 15) {setErrorMsg('El nombre de usuario es demasiado largo'); return;}
    if (form.name.length < 3)  {setErrorMsg('El nombre es demasiado corto'); return;}
    if (form.name.length > 15) {setErrorMsg('El nombre es demasiado largo'); return;}
    if (form.surname.length < 3)  {setErrorMsg('El apellido es demasiado corto'); return;}
    if (form.surname.length > 15) {setErrorMsg('El apellido es demasiado largo'); return;}
    if (form.password.length < 8)  {setErrorMsg('La contraseña es demasiado corta'); return;}
    if (form.password.length > 15) {setErrorMsg('La contraseña es demasiado larga'); return;}
    if (form.phone.length < 5) {setErrorMsg('El número de teléfono es demasiado corto'); return;}
    if (form.email.length < 5) {setErrorMsg('El email es demasiado corto'); return;}
    if (form.email.length < 5) {setErrorMsg('El email es demasiado corto'); return;}
    axios({
      method: 'post',
      url: `/users/${selectedUser._id ? `edit/${selectedUser._id}` : 'create'}`, 
      headers: { 'x-auth-token': user.token },
      data: form
    }).then(response => {
      if(response.status === 200) {
        Swal.fire({
          title: 'Hecho!', 
          text: `Usuario ${selectedUser._id ? 'Modificado' : 'Creado'}!`, 
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        handleClose();
      } else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  };

  useEffect(() => {
    if (show) {
      if (selectedUser._id) {
        setForm({ ...selectedUser, password: '********' });
        if (selectedUser.type !== 1) getSubjects();
      } else setForm(emptyForm);
    }
  }, [show, selectedUser]); // eslint-disable-line

  useEffect(() => {
    if (user && user.type === 4 && selectedUser._id) setDisabled(true);
  }, [user.type]); // eslint-disable-line

  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} className="textcolor mr-1 fa-lg" />
        Agregar
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        backdrop="static"
        centered
        className="backgroundlogin textcolor"
      >
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            { selectedUser._id ? 'Modificar ' : 'Crear ' }
            Usuario
          </Modal.Title>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={disabled ? 12 : 6} controlId="username">
                  <Form.Label className="font-weight-bold">Usuario</Form.Label>
                  <Form.Control
                    disabled={disabled}
                    required
                    value={form.username}
                    type="text"
                    placeholder="Ingresa el nombre de usuario"
                    className="borderinput"
                    onChange={handleChange}                   
                  />
                </Form.Group>
                {
                  !disabled &&
                  <Form.Group as={Col} xs={12} lg={6} controlId="password">
                    <Form.Label className="font-weight-bold">Contraseña</Form.Label>
                    <Form.Control
                      required
                      value={form.password}
                      type="password"
                      placeholder="Ingresa tu contraseña"
                      className="borderinput"
                      onChange={handleChange}                   
                    />
                  </Form.Group>
                }
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="name">
                  <Form.Label className="font-weight-bold">Nombre</Form.Label>
                  <Form.Control
                    disabled={disabled}
                    required
                    value={form.name}
                    type="text"
                    placeholder="Ingresa nombre"
                    className="borderinput"
                    onChange={handleChange}                   
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} controlId="surname">
                  <Form.Label className="font-weight-bold">Apellido</Form.Label>
                  <Form.Control
                    disabled={disabled}
                    required
                    value={form.surname}
                    type="text"
                    placeholder="Ingresa apellido"
                    className="borderinput"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="phone">
                  <Form.Label className="font-weight-bold">Teléfono</Form.Label>
                  <Form.Control
                    disabled={disabled}
                    required
                    value={form.phone}
                    type="number"
                    placeholder="Ingresa el numero"
                    className="borderinput"
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} controlId="email">
                  <Form.Label className="font-weight-bold">Email</Form.Label>
                  <Form.Control
                    disabled={disabled}
                    required
                    value={form.email}
                    type="email"
                    placeholder="Ingresa el Email"
                    className="borderinput"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Form.Row>
              {
                (!selectedUser._id || selectedUser?.type !== 1) &&
                <Form.Row>
                  {
                    !disabled &&
                    <>
                      <Form.Group as={Col} xs={12} lg={6} controlId="type">
                        <Form.Label className="font-weight-bold">Título</Form.Label>
                        <Form.Control value={form.type} as="select" className="borderinput" onChange={handleChange}>
                          <option value={0}>Seleccioná uno . . .</option>
                          <option value={2}>CPN</option>
                          <option value={3}>LIC</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group as={Col} xs={12} lg={6} controlId="enabled">
                        <Form.Label className="bold">Habilitado</Form.Label>
                        <Form.Control value={form.enabled} as="select" className="borderinput" onChange={handleChange}>
                          <option value={true}>Habilitado</option>
                          <option value={false}>Deshabilitado</option>
                        </Form.Control>
                      </Form.Group>
                    </>
                  }
                  {
                    selectedUser._id ?
                    <>
                      {subjects.at.map((_s, i) => <Student student={selectedUser} subjects={subjects} setSubjects={setSubjects} index={i} key={i} />)}
                      { form.enabled && subjects.new ? <Student student={selectedUser} subjects={subjects} setSubjects={setSubjects} /> :
                        subjects.available.length > 0 ?
                        <Button variant="light" className="my-3 mx-auto shadow zoomcampus textcolor bold" onClick={() => setSubjects({...subjects, new: true})}><FontAwesomeIcon icon={faPlus} className="mr-2 textcolor" />Agregar Materia</Button> :
                        <Form.Label className="my-3 mx-auto">No hay materias disponibles para inscribir al usuario</Form.Label>
                      }
                    </> :
                    <Form.Label className="my-3 mx-auto">Luego de registrar el usuario podrás inscribirlo a materias</Form.Label>
                  }
                </Form.Row>
              }
              <Form.Row>
                <Form.Group as={Col} xs={12} controlId="note">
                  <Form.Control className="bordercolor" placeholder="Agregar observación" value={form.note} as="textarea" rows="4" onChange={handleChange} />
                </Form.Group>
              </Form.Row>
              <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
                {errorMsg}
              </Alert>
              <Row className="text-center pt-4 pb-3 justify-content-center">
                <Button variant="light" type="submit" className="mr-5 shadowcards textcolor">
                  <FontAwesomeIcon icon={faPlus} className="mr-2 textcolor" />{ selectedUser._id ? 'Editar' : 'Agregar' }
                </Button>
                <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
                </Button>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}