import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Modal, Row, Col, Button } from "react-bootstrap";
import { faPlayCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import userContext from '../../context/user/userContext';
import loading from "../Images/loading.gif";
import axios from 'axios';
import './Video.css';

export default function Video({ show, handleClose, file }) {
  const [loadingState, setLoadingState] = useState(true),
  {user, actualizarUsuario} = useContext(userContext),
  [viewed, setViewed] = useState(false),

  updateViews = () => {
    if (user.type !== 1 && user.type !== 4) {
      if (!viewed && file && file.id) {
        axios({
          method: 'post',
          url: `/users/watch_content`, 
          headers: { 'x-auth-token': user.token },
          data: { content_viewed: file.id }
        }).then(({data: content_viewed}) => actualizarUsuario({...user, content_viewed}))
        .catch(error => console.log('Hubo un problema con la petición: ' + error.message));
        setViewed(true);
      }
    } else setViewed(true);
  };

  useEffect(() => {
    let mounted = true;
    if (show) setTimeout(() => {
      if (mounted) setLoadingState(false);
    }, 1500);
    else setLoadingState(true);
    setViewed(false);

    return () => mounted = false;
  }, [show]);

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size="lg" centered className="backgroundlogin">
      <div className="p-1 bg-info">
        <Container fluid className="videobackground">
          <div className="text-center py-2 bold textcolor">
            <h4>Cuenta: {`${user.name}, ${user.surname}`}</h4>
          </div>
          <Row className="py-1">
            <Col xs={10} lg={11} className="text-center">
              <h3 className="pl-5 textcolor text-capitalize d-none d-lg-block">
                {file.title}
              </h3>
              <h5 className="pl-0 pl-md-5 textcolor text-capitalize d-block d-lg-none">
                {file.title}
              </h5>
            </Col>
            <Col xs={2} lg={1} className="text-right">
              <FontAwesomeIcon icon={faTimes} className="fa-2x text-danger toclick d-none d-lg-block" onClick={handleClose} />
              <FontAwesomeIcon icon={faTimes} className="fa-lg text-danger toclick d-block d-lg-none" onClick={handleClose} />
            </Col>
          </Row>
          <Modal.Body className="text-center pt-1 pb-2 d-flex justify-content-center ">
            {
              loadingState && 
              <div className="d-block video pt5">
                <img src={loading} alt="loading"  height={150}/> 
              </div>
            }
            {
              file?.video ?
              viewed ? <iframe title={file.title} src={file.video.replace("vimeo.com" , "player.vimeo.com/video") + "?byline=0&amp;portrait=0&amp;speed=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} width="1920" height="1080" frameBorder="0" allowFullScreen className={loadingState ? "d-none" : "video"}></iframe> :
              !loadingState && 
              <Button variant="link" onClick={updateViews} className="video"><FontAwesomeIcon icon={faPlayCircle} className="textcolor fa-9x"/></Button> :
              <h4 className={loadingState ? "d-none" : "d-block textcolor text-capitalize py-5"}>Contacta con el administrador</h4>
            }
            </Modal.Body>
          </Container>
       </div>
    </Modal>
  );
}