import React from "react";
import { Pagination } from 'react-bootstrap';

export default function Paged({ page, setPage, lastPage }) {
	let ellipsis = lastPage > 5, pages = [];
	for (let index = 0; index <= lastPage; index++) {
		pages.push(index);      
	}
	return (
		<>
			{
				lastPage > 0 &&
				<Pagination  className="py-4 justify-content-center">
				{
					page < 1 ? 
					<>
						<Pagination.First disabled />
						<Pagination.Prev disabled />
						<Pagination.Item active>{1}</Pagination.Item>
						{
							ellipsis ?
							<>
                                <Pagination.Item onClick={() => setPage(1)}>2</Pagination.Item>
                                <Pagination.Item onClick={() => setPage(2)}>3</Pagination.Item>
                                <Pagination.Ellipsis />
                                <Pagination.Item onClick={() => setPage(lastPage)}>{lastPage + 1}</Pagination.Item>
							</>
							:
							<>
							    { pages.map(i => i > page && <Pagination.Item key={i} onClick={() => setPage(i)}>{i + 1}</Pagination.Item>) }
							</>
						}
						<Pagination.Next onClick={() => setPage((prevPage) => prevPage + 1)} />
						<Pagination.Last onClick={() => setPage(lastPage)} />
					</>
					: (
					page === lastPage ? 
						<>
							<Pagination.First onClick={() => setPage(0)} />
							<Pagination.Prev onClick={() => setPage((prevPage) => prevPage - 1)} />
							{
								ellipsis ?
								<>
                                    <Pagination.Item onClick={() => setPage(0)}>1</Pagination.Item>
                                    <Pagination.Ellipsis />
                                    <Pagination.Item onClick={() => setPage(prevPage => prevPage - 2)}>{page - 1}</Pagination.Item>
                                    <Pagination.Item onClick={() => setPage(prevPage => prevPage - 1)}>{page}</Pagination.Item>
                                    <Pagination.Item active>{page + 1}</Pagination.Item>
								</>
								:
								<>
                                    { pages.map(i => i < page && <Pagination.Item key={i} onClick={() => setPage(i)}>{i + 1}</Pagination.Item>) }
                                    <Pagination.Item active>{page + 1}</Pagination.Item>
								</>
							}
							<Pagination.Next disabled />
							<Pagination.Last disabled />
						</>
						:
						<>
							<Pagination.First onClick={() => setPage(0)} />
							<Pagination.Prev onClick={() => setPage((prevPage) => prevPage - 1)} />
							{
								ellipsis ?
								<>
									{ page > 2 && <Pagination.Ellipsis /> }
									<Pagination.Item onClick={() => setPage(prevPage => prevPage - 1)}>{page}</Pagination.Item>
									<Pagination.Item active>{page + 1}</Pagination.Item>
									<Pagination.Item onClick={() => setPage(prevPage => prevPage + 1)}>{page + 2}</Pagination.Item>
									{ page < lastPage - 2 && <Pagination.Ellipsis /> }
								</>
								:
								<>
									{ 
										pages.map(i => i === page ? <Pagination.Item active key={i}>{page + 1}</Pagination.Item>
										: <Pagination.Item key={i} onClick={() => setPage(i)}>{i + 1}</Pagination.Item>) 
									}
								</>
							}
							<Pagination.Next onClick={() => setPage((prevPage) => prevPage + 1)} />
							<Pagination.Last onClick={() => setPage(lastPage)} />
						</>
					)
				}
				</Pagination>
			}
		</>		
	);
}