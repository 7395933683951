import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col, Image } from "react-bootstrap";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import loading from "../Images/loading.gif";
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function OurSubjects({_ref}) {
  const [subjects, setSubjects] = useState([]),
  source = axios.CancelToken.source(),
  getSubjects = () => {
    axios({
      method: 'get',
      url: `/subjects/all`,
      cancelToken: source.token
    }).then(response => {
      if (response.status === 200) setSubjects(response.data);
      else throw new Error(response.data.msg);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  };

  useEffect(() => {
    getSubjects();
    return () => source.cancel();
    // eslint-disable-next-line
  }, []);

  return (
    <Container ref={_ref} className=" my-5 py-5">
      <div className="text-center my-4" data-aos="fade-down" data-aos-delay="200">
        <h1 className="textcolor bold">Nuestras Materias</h1>
      </div>
      <Row className="justify-content-center align-items-center">
        {
          subjects.length < 1 ?
          <><Image src={loading} alt="loading" /> </> :
          subjects.map(s =>
            <Col key={s._id} xs={12} md={4} className="my-4 text-center" data-aos="zoom-in" data-aos-delay="200">
              <Link to={`/subject/${s._id}`} className="text-decoration-none shadow borderhome pxb py-3">
                <div className=" mb-0 textcolor d-inline-flex bold">
                  <FontAwesomeIcon icon={faBook} className="fa-lg mr-2 mt-1" />
                  <p className="pt-1 mb-0">{s.name}</p>
                </div>
              </Link>
            </Col>
          )
        }
      </Row>
    </Container>
  );
}