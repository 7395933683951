import React from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import aulavirtual1 from "../Images/Home/aulavirtual1.jpg";
import aulavirtual3 from "../Images/Home/aulavirtual3.jpg";
import aulavirtual from "../Images/Home/aulavirtual.jpg";

export default function AboutsUs({_ref}) {
    return (
      <>
        <Container ref={_ref} className="my-0 py-0 my-lg-5 py-lg-5">
            <Row className="justify-content-center align-items-center">
            <Col xs={12} >
                <div className="text-center mt-3" data-aos="fade-down" data-aos-delay="200">
                <Image src={aulavirtual1} fluid  className="mr-2"  />
                <h1 className="textcolor">Quiénes Somos</h1>
                </div>
            </Col>
            <Col xs={12} className="text-center">
                <h4 className="text-dark px-1 px-lg-5 mx-5" data-aos="fade-up" data-aos-delay="200">Un equipo de profesores enfocados en explicarte la materia a través de un contenido prolijo, sencillo y organizado. Buscamos tu facilidad y que te lleves la mejor experiencia utilizando nuestra plataforma.</h4>
            </Col>
            </Row>
        </Container>
        <Container className="heighthome px-4 px-lg-0 py-5">
        <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6} className="d-flex d-lg-none">
                <Image src={aulavirtual3} fluid alt="aulavirtual" width="500" data-aos="fade-down" data-aos-delay="200" />
            </Col>
            <Col xs={12} md={6}>
                <div className="text-center mt-3 px-1 px-lg-5" data-aos="fade-up" data-aos-delay="200">
                    <h1 className="textcolor bold">Metodologia Virtual</h1>
                    <h5 className="text-dark ">La pandemia nos puso de cabeza con las clases en linea. PERO! Con un contenido bien organizado creemos que los beneficios de las clases virtuales hicieron que lleguen para quedarse.</h5>
                </div>
            </Col>
            <Col xs={12} md={6} className="d-none d-lg-flex">
            <Image src={aulavirtual3} fluid alt="aulavirtual" width="500" data-aos="fade-down" data-aos-delay="200" />
            </Col>
        </Row>
        </Container>
        <Container className="heighthome px-1 px-lg-4 px-lg-0">
        <Row className="justify-content-center align-items-center">
        <Col xs={12} md={6}>
            <Image src={aulavirtual} fluid alt="aulavirtual" width="500" data-aos="fade-down" data-aos-delay="200" />
            </Col>
            <Col xs={12} md={6}>
            <div className="text-center mt-3 px-5" data-aos="fade-up" data-aos-delay="200">
                <h1 className="textcolor bold">Organizá tus Horarios</h1>
                <h5 className="text-dark ">Queremos facilitar el acceso a las clases y olvidarnos de los horarios. Vas a tener videos explicativos en diapositivas para que puedas reproducirlos en cualquier momento</h5>
            </div>
            </Col>
        </Row>
      </Container>
    </>
    )
}
