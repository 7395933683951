import React, { useState, useEffect, useContext } from "react";
import { faCircle, faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import { Card, Form, Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function AdminLiveVideo() {
  const {subject, update} = useContext(subjectContext),
  [isLive, setIsLive] = useState(false),
  source = axios.CancelToken.source(),
  {user} = useContext(userContext),
  [live, setLive] = useState(''),

  updateLive = () => {
    axios({
      method: 'post',
      url: `/subjects/live/start/${subject.current}`, 
      headers: { 'x-auth-token': user.token },
      data: { live },
      cancelToken: source.token
    }).then(response => {
      if (response.status === 200) {
        update({ update: true });
        setIsLive(true);
        Swal.fire({
          title: 'Hecho',
          icon: 'success',
          text: 'Video en vivo establecido',
          timer: 2000,
          showConfirmButton: false
        });
      } else throw new Error(response.data.msg);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  },

  removeLive = () => {
    axios({
      method: 'post',
      url: `/subjects/live/remove/${subject.current}`, 
      headers: { 'x-auth-token': user.token },
      cancelToken: source.token
    }).then(response => {
      if(response.status === 200) {
        update({ update: true });
        setIsLive(false);
        setLive('');
        Swal.fire({
          title: 'Hecho',
          icon: 'success',
          text: 'Video en vivo eliminado',
          timer: 2000,
          showConfirmButton: false
        });
      } else throw new Error(response.data.msg);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  };

  useEffect(() => {
    if (subject.current) {
      let sub = subject.all.find(s => s._id === subject.current);
      if (sub.live.length > 0) {
        setLive(sub.live);
        setIsLive(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">Video en Vivo</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={e => e.preventDefault()}>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>Link</Form.Label>
              <Form.Control type="text" value={live} onChange={({target}) => setLive(target.value)} placeholder="Ingresar link del video" />
            </Form.Group>
            <Button variant="light" className="textcolor bold my-2" onClick={updateLive}>
              <FontAwesomeIcon icon={faSave} className="mr-2" />Actualizar Link
            </Button>
          </Form>
          {
            isLive ?
            <>
              <h4><FontAwesomeIcon icon={faCircle} className="text-success mr-2" />Video Actual en Vivo </h4>
              <Button variant="light" className="textcolor bold mb-2" onClick={removeLive}>
                <FontAwesomeIcon icon={faTimes} className="mr-2" />Dejar de Transmitir
              </Button>
            </> :
            <h4><FontAwesomeIcon icon={faCircle} className="text-danger mr-2" />Ningun video en Vivo </h4>
          }
        </Card.Body>
      </Card>
  )
}
