import React, { useState, useEffect, useContext } from "react";
import { faCaretRight, faMarker } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import { Row, Col, Card, Image } from "react-bootstrap";
import SearchBar from '../SearchBar/SearchBar';
import noresult from "../Images/noresult.gif";
import SectionSelect from "./SectionSelect";
import TestSwitches from "./TestSwitches";
import { Link } from "react-router-dom";
import ModalTest from './ModalTest';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function AdminTests() {
  const [selectedItem, setSelectedItem] = useState({_id: ''}),
  [section, setSection] = useState('Libre'),
  [filtered, setFiltered] = useState([]),
  {subject} = useContext(subjectContext),
  source = axios.CancelToken.source(),
  {user} = useContext(userContext),
  [show, setShow] = useState(false),
  [files, setFiles] = useState([]),

  translate = () => {
    switch(section) {
      case "Parcial 1": return 'partial1';
      case "Parcial 2": return 'partial2';
      case "Parcial 3": return 'partial3';
      case "Libre":     return 'free';
      default:          return 'final';
    }
  },
  
  getFiles = () => {
    axios({
      method: 'post',
      url: `/tests/section/${translate()}`, 
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current },
      cancelToken: source.token
    }).then(response => {
      if(response.status === 200) {
        setFiles(response.data);
      } else throw new Error(response.data.msg);
    }).catch(error => {
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  },

  onEdit = item => {
    setSelectedItem(item);
    setShow(true);
  },
  
  onDelete = item => {
    Swal.fire({
      title: 'Seguro?',
      text: "Esta acción es irreversible",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonColor: '#3085d6',
      confirmButtonColor: '#d33',
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        axios({
          method: 'post',
          url: `/tests/delete/${item._id}`, 
          headers: { 'x-auth-token': user.token }
        }).then(response => {
          if(response.status === 200) {
            Swal.fire({
              title: 'Evaluación Eliminada',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            });
            getFiles();
          } else {
            Swal.fire({
              title: 'No se pudo eliminar',
              icon: 'error',
              timer: 2000,
              showConfirmButton: false
            });
            throw new Error(response.data.msg);
          };
        }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
      }
    });
  };

  useEffect(() => {
    if (user && user.token && (user.type === 1 || user.type === 4) && !show) getFiles();
    return () => source.cancel();
    // eslint-disable-next-line
	}, [user, show, section]);

  return (
    <>
      <SectionSelect title="Pruebas" section={section} setSection={setSection} />
      <Row className="py-2">
        <SearchBar content={files} setFiltered={setFiltered} fields={["title"]} />
        <Col xs={12} md={3} className="py-3 py-md-0">
          <ModalTest show={show} setShow={setShow} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        </Col>
      </Row>
      <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">{section} Pruebas</h4>
        </Card.Header>
        <Card.Body>
          {
            filtered && filtered.length > 0 ?
            filtered.map(file =>
            <div key={file._id}>
              <Row className="mt-2 text-left">
                  <Col xs={12} md={6} lg={3} className="my-2">
                    <h5 className="ml-0 pl-0 text-capitalize">{file.title}</h5>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="my-2"> 
                    <FontAwesomeIcon icon={faCaretRight} className="mr-2 mt-1" />
                    <Link to= {`/admin/results/${file._id}`}  className="textmaterial">
                      <FontAwesomeIcon icon={faMarker} className="ml-2 mt-1 fa-lg" /> Resultados
                    </Link>
                  </Col>
                  <Col xs={12} lg={3} className="text-right d-inline-flex justify-content-end">
                    <TestSwitches file={file} onEdit={onEdit} onDelete={onDelete} />
                  </Col>
              </Row>
            </div>) : 
            <div className="text-center">
              <Image src={noresult} fluid alt="" className="shadow-lg rounded" />
            </div>
          }
        </Card.Body>
      </Card>
    </>
  );
}