import React, { useContext, useEffect, useState } from "react";
import { faHome, faFileAlt, faBook, faKey, faSignOutAlt, faUserGraduate, faFile, faCircle } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar, Nav, Form, Image } from "react-bootstrap";
import userContext from '../../context/user/userContext';
import { Link, useHistory } from "react-router-dom";
import logo from "../Images/logo.png";
import axios from 'axios';
import "./Header.css";

export default function Header() {
  const { user, cerrarSesion, actualizarUsuario } = useContext(userContext),
  { subject, update } = useContext(subjectContext),
  [currentType, setCurrentType] = useState(false),
  [live, setLive] = useState(false),
  history = useHistory(),

  handleChange = ({target}) => {
    update({ current: target.value });
    if (user.type !== 1 && user.type !== 4) history.push(target.value ? `/subject/${target.value}` : '/');
  },

  subjectSections = () => {
    if (subject.current)
    return (
      <>
        {
          currentType &&
          <>
            <Link to="/parcial" className="textcolor mr-3 text-decoration-none">
              <FontAwesomeIcon icon={faBook} className="mr-1 fa-lg" /> Parcial
            </Link>
            <Link to="/final" className="textcolor mr-3 text-decoration-none ">
              <FontAwesomeIcon icon={faFileAlt} className="mr-1 fa-lg" /> Finales
            </Link>
          </>
        }
        <Link to="/free" className="textcolor mr-3 text-decoration-none ">
          <FontAwesomeIcon icon={faFile} className="mr-1 fa-lg" /> Libre
        </Link>
        {
          live && 
          <Link to="/live" className="textcolor mr-3 text-decoration-none ">
            <FontAwesomeIcon icon={faCircle} className="text-success mr-1 fa-lg" />
            Vivo
          </Link>
        }
      </>
    );
    return (<></>);
  },

  getPlan = () =>
    axios({
      method: 'get',
      url: `/subjects/id/${subject.current}/auth`,
      headers: { 'x-auth-token': user.token }
    }).then(({data}) => actualizarUsuario({plan: data}))
    .catch(error => console.log('Hubo un problema con la petición: ' + error.message)),

  signOut = () => {
    cerrarSesion();
    history.push('/');
  };

  useEffect(() => {
    if (user && user.token) {
      if (subject.update)
        axios({
          method: 'get',
          url: `/subjects/all/auth`,
          headers: { 'x-auth-token': user.token }
        }).then(response => {
          if (response.status === 200) update({ all: response.data, update: false });
          else throw new Error(response.data.msg);
        }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
      if (subject.current) {
        let sub = subject.all.find(s => s._id === subject.current);
        setCurrentType(sub?.type);
        setLive(sub?.live?.length > 0);
        getPlan();
      } else {
        if (subject.all.length > 0) {
          let current = localStorage.getItem('campus-subject') || '';
          if (current && subject.all.find(s => s._id === current)) update({current});
          else localStorage.removeItem('campus-subject');
        } else history.push('/');
      }
    }
    // eslint-disable-next-line
  }, [user?.token, subject.current, subject.update]);
  
  if (user && user.token)
  return (
    <>
      <Navbar bg="light" expand="lg" className="borderbottom d-none d-lg-flex" sticky="top">
        <Navbar.Brand>
          <Link to='/' className="text-decoration-none">
            <h5 className="textcolor d-inline-flex">
              <Image src={logo} width="80" fluid className="mr-2 fa-lg mt-1" /> <span className="mt-3 mr-1">Mi</span><span className="mt-3 orange">Aula</span>
            </h5>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <Nav className="ml-lg-auto">
            {
              user.type === 1 ?
              <>
                <div className="mt-4">
                  <Link to="/admin" className="textcolor mr-3 text-decoration-none ">
                    <FontAwesomeIcon icon={faKey} className="mr-1 fa-lg" />Admin
                  </Link> 
                </div>
                <Form onSubmit={e => e.preventDefault()} className="mr-2 mt-3 textcolor">
                  <Form.Group>
                    <div className="d-inline-flex">
                      <FontAwesomeIcon icon={faBook} className="mr-2 mt-2 fa-lg" />
                      <Form.Control as="select" value={subject.current} custom onChange={handleChange}>
                        <option value="">Todas</option>
                        {subject.all.map(s => <option key={s._id} value={s._id}>{s.name}</option>)}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Form>
              </> :
              user.type === 4 ?
              <>
                <div className="mt-4">
                  <Link to="/" className="textcolor mr-3 text-decoration-none">
                    <FontAwesomeIcon icon={faHome} className="mr-1 fa-lg" /> Home
                  </Link>
                  {subjectSections()}
                  <Link to="/profile" className="textcolor mr-3 text-decoration-none ">
                    <FontAwesomeIcon icon={faUserGraduate} className="mr-1 fa-lg" />
                    Perfil
                  </Link>
                  <Link to="/teacher" className="textcolor mr-3 text-decoration-none ">
                    <FontAwesomeIcon icon={faKey} className="mr-1 fa-lg" />Profesor
                  </Link>
                </div>
                <Form onSubmit={e => e.preventDefault()} className="mr-2 mt-3 textcolor">
                  <Form.Group>
                    <div className="d-inline-flex">
                      <FontAwesomeIcon icon={faBook} className="mr-2 mt-2 fa-lg" />
                      <Form.Control as="select" value={subject.current} custom onChange={handleChange}>
                        <option value="">Todas</option>
                        {subject.all.map(s => <option key={s._id} value={s._id}>{s.name}</option>)}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Form>
              </> :
              <>
                <div className="mt-4">
                  <Link to="/" className="textcolor mr-3 text-decoration-none">
                    <FontAwesomeIcon icon={faHome} className="mr-1 fa-lg" /> Home
                  </Link>
                  <Link to="/profile" className="textcolor mr-3 text-decoration-none ">
                    <FontAwesomeIcon icon={faUserGraduate} className="mr-1 fa-lg" />
                    Perfil
                  </Link>
                  {subjectSections()}
                </div>
                <Form onSubmit={e => e.preventDefault()} className="mr-2 mt-3 textcolor">
                  <Form.Group>
                    <div className="d-inline-flex">
                      <FontAwesomeIcon icon={faBook} className="mr-2 mt-2 fa-lg" />
                      <Form.Control as="select" value={subject.current} custom onChange={handleChange}>
                        <option value="">Todas</option>
                        {subject.all.map(s => <option key={s._id} value={s._id}>{s.name}</option>)}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Form>
              </>
            }
          </Nav>
          <div className="textcolor font-weight-bold"> 
            Hola {user.name}!
            <div className="btn btn-light textcolor p-0 m-0">
              <FontAwesomeIcon icon={faSignOutAlt} onClick={() => signOut()} className="ml-2 fa-lg" />
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
      <Navbar bg="light" expand="lg" className="borderbottom d-flex d-lg-none" sticky="top">
        <Navbar.Toggle aria-controls="navbar-mobile" />
        <Navbar.Brand>
          <h5 className="textcolor d-inline-flex">
            <Image src={logo} width="80" fluid className="mr-2 fa-lg mt-1" /> <span className="mt-3 mr-1">Mi</span><span className="mt-3 orange">Aula</span>
          </h5>
        </Navbar.Brand>
        <div className="text-right textcolor font-weight-bold"> Hola {user.name}!
          <div className="btn btn-light textcolor p-0 m-0">
            <FontAwesomeIcon icon={faSignOutAlt} onClick={cerrarSesion} className="ml-2 fa-lg" />
          </div>
        </div>
        <Navbar.Collapse id="navbar-mobile">
          <Nav className="text-left">
            {
              user.type === 1 ?
              <>
                <div className="mt-4">
                  <Link to="/admin" className="textcolor mr-3 text-decoration-none ">
                    <FontAwesomeIcon icon={faKey} className="mr-1 fa-lg" />Admin
                  </Link> 
                </div>
                <Form onSubmit={e => e.preventDefault()} className="mr-2 mt-3 textcolor">
                  <Form.Group>
                    <div className="d-inline-flex">
                      <FontAwesomeIcon icon={faBook} className="mr-2 mt-2 fa-lg" />
                      <Form.Control as="select" value={subject.current} custom onChange={handleChange}>
                        <option value="">Todas</option>
                        {subject.all.map(s => <option key={s._id} value={s._id}>{s.name}</option>)}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Form>
              </> :
              user.type === 4 ?
              <>
                <Link to="/" className="textcolor mr-3 text-decoration-none py-1">
                  <FontAwesomeIcon icon={faHome} className="mr-1 fa-lg" /> Home
                </Link>
                {subjectSections()}
                <Link to="/profile" className="textcolor mr-3 text-decoration-none py-1">
                  <FontAwesomeIcon icon={faUserGraduate} className="mr-1 fa-lg" />
                  Perfil
                </Link>
                <Link to="/teacher" className="textcolor mr-3 text-decoration-none ">
                  <FontAwesomeIcon icon={faKey} className="mr-1 fa-lg" />Profesor
                </Link>
                <Form onSubmit={e => e.preventDefault()} className="mr-2 mt-3 textcolor">
                  <Form.Group>
                    <div className="d-inline-flex">
                      <FontAwesomeIcon icon={faBook} className="mr-2 mt-2 fa-lg" />
                      <Form.Control as="select" value={subject.current} custom onChange={handleChange}>
                        <option value="">Todas</option>
                        {subject.all.map(s => <option key={s._id} value={s._id}>{s.name}</option>)}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Form>
              </> :
              <>
                <Link to="/" className="textcolor mr-3 text-decoration-none py-1">
                  <FontAwesomeIcon icon={faHome} className="mr-1 fa-lg" /> Home
                </Link>
                {subjectSections()}
                <Link to="/profile" className="textcolor mr-3 text-decoration-none py-1">
                  <FontAwesomeIcon icon={faUserGraduate} className="mr-1 fa-lg" />
                  Perfil
                </Link>
                <Form onSubmit={e => e.preventDefault()} className="mr-2 mt-3 textcolor">
                  <Form.Group>
                    <div className="d-inline-flex">
                      <FontAwesomeIcon icon={faBook} className="mr-2 mt-2 fa-lg" />
                      <Form.Control as="select" value={subject.current} custom onChange={handleChange}>
                        <option value="">Todas</option>
                        {subject.all.map(s => <option key={s._id} value={s._id}>{s.name}</option>)}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Form>
              </>
            }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
  return <></>;
}