import React, { useState, useEffect, useContext } from 'react';
import subjectContext from '../../context/subject/subjectContext';
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import { Button, Card, Form } from "react-bootstrap";
import Swal from 'sweetalert2';
import axios from 'axios';

export default function AdminEmail() {
  const {user} = useContext(userContext),
  [form, setForm] = useState({ affair: '', text: '' }),
  {subject} = useContext(subjectContext),
  source = axios.CancelToken.source(),
  handleChange = ({target}) => setForm({...form, [target.id]: target.value}),
  handleSubmit = e => {
    e.preventDefault();
    axios({
      method: 'post',
      url: `/emails/notify_students/${subject.current}`, 
      headers: { 'x-auth-token': user.token },
      data: { ...form },
      cancelToken: source.token
    }).then(response => {
      if(response.status === 200)
        Swal.fire({
          title: 'Hecho!',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
      else throw new Error(response.data.msg);
    }).catch(error => {
      Swal.fire({
        title: 'Hubo un error',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
      if (!axios.isCancel(error)) console.log('Hubo un problema con la petición: ' + error.message);
    });
  };

  useEffect(() => {
    return () => source.cancel();
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="shadowcards">
      <Card.Header className="m-0 pb-1 fondotitle">
        <h4 className="text-white rounded-pill">Email</h4>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="affair">
            <Form.Label>Asunto</Form.Label>
            <Form.Control type="text" placeholder="Ingresar Asunto" value={form.affair} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="text">
            <Form.Label>Mensaje</Form.Label>
            <Form.Control as="textarea" rows={3} value={form.text} onChange={handleChange} />
          </Form.Group>
          <Button variant="light" className="textcolor bold" type="submit">
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />Enviar
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}
