import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { Row, Card,  Col, Image } from "react-bootstrap";
import noresult from '../Images/noresult.gif'

export default function Complement({ files }) {
  return (
    <>
      <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">Complementos</h4>
        </Card.Header>
        <Card.Body>
          {
            files && files.length > 0 ?  files.map(file => file.is_visible &&
            <div key={file._id}>
              <Row className="mt-2 text-left d-none d-md-flex">
                <Col xs={12}  className="d-inline-flex">
                  <h5 className="ml-1 ml-md-4 pl-0">{file.title}</h5>
                  <FontAwesomeIcon icon={faCaretRight} className="ml-2 ml-md-4 mr-1 mr-md-2 mt-1" />
                  <a download target="_blank" rel="noopener noreferrer" href={file.link} className="textmaterial pr-5" >
                    Enlace del material
                  </a>
                </Col>
              </Row>
              <Row className="mt-2 text-left d-flex d-md-none">
                <Col xs={12}>
                  <h5 className="ml-1 ml-md-4 pl-0">{file.title}</h5>
                </Col>
                <Col xs={12}> 
                  <FontAwesomeIcon icon={faCaretRight} className="mr-1 mt-1" />
                  <a download target="_blank" rel="noopener noreferrer" href={file.link} className="textmaterial pr-5" >
                    Enlace
                  </a>
                </Col>
              </Row>
            </div>
            ) 
            :
            <div className="text-center " >
              <Image src={noresult} fluid alt="" className="shadow-lg rounded" />
            </div>
          }
        </Card.Body>
      </Card>
    </>
  );
}
