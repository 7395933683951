import React, { useState, useContext } from 'react';
import userContext from '../../context/user/userContext';
import { Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function VisibleSwitch({ file, type = 'files' }) {
    const { user } = useContext(userContext),
    [visible, setVisible] = useState(file.is_visible),
    handleChange = ({target}) => {
        axios({
            method: 'post',
            url: `/${type}/edit/${file._id}`, 
            headers: { 'x-auth-token': user.token },
            data: { is_visible: !visible }
          }).then(() => {
            setVisible(!visible);
            Swal.fire({
                title: 'Hecho!', 
                text: `${type === 'files' ? 'Archivo' : 'Notificación'} ${file.title} es ahora ${target.checked ? 'visible' : 'no visible'}`,
                icon: 'success',
                timer: 2000,
                showConfirmButton: false
            });
        }).catch(() =>
            Swal.fire({
                title: 'No se pudo completar la acción',
                icon: 'error',
                timer: 2000,
                showConfirmButton: false
            })
        );
    };

    return (
        <Form className="col mt-2 mt-lg-0">
            <Form.Check 
                type="switch"
                checked={visible}
                id={file._id + Math.random()}
                onChange={handleChange}
                label="Visible"
            />
        </Form>
    );
}
