import React, { useState, useEffect, useContext } from 'react';
import { Alert, Modal, Button, Form, Row, Col } from "react-bootstrap";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalHomepage({ show, setShow, selectedSubject, setSelectedSubject }) {
  const emptyForm = { name: '', category: '', description: '', sections: [{ section: 'Libre', text: ''}, { section: '', text: ''}, { section: '', text: ''}], students: [], type: false },
  [sections, setSections] = useState([0]),
  [students, setStudents] = useState([]),
  [errorMsg, setErrorMsg] = useState(''),
  [form, setForm] = useState(emptyForm),
  {update} = useContext(subjectContext),
  {user} = useContext(userContext),

  getStudents = () => {
    axios({
      method: 'post',
      url: `/subjects/students`,
      headers: { 'x-auth-token': user.token },
      data: { subject: selectedSubject?._id }
    }).then(response => {
      if (response.status === 200) setStudents(response.data.sort((a, b) => a.name.localeCompare(b.name)));
      else throw new Error(response.data.msg);
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  },

  setFullType = () => {
    let {sections} = selectedSubject;
    if (sections.length < 3) for (let i = sections.length; i < 4; i++) sections.push({ section: '', text: ''});
    sections[0].section = "Parcial";
    sections[1].section = "Final";
    sections[2].section = "Libre";
    setSections([0, 1, 2]);
    setForm({ ...form, ...selectedSubject, sections });
  },

  setFreeType = () => {
    let {sections} = selectedSubject;
    if (sections.length < 3) for (let i = sections.length; i < 4; i++) sections.push({ section: '', text: ''});
    sections[0].section = "Libre";
    setSections([0]);
    setForm({ ...form, ...selectedSubject, sections });
  },

  handleChange = ({target}) => setForm({...form, [target.id]: target.value}),

  handleChangeText = ({value}, i) => {
    let {sections} = form;
    sections[parseInt(i)].text = value;
    setForm({...form, sections});
  },

  handleClose = () => {
    setShow(false);
    setErrorMsg('');
    setForm(emptyForm);
    setSelectedSubject({_id: ''});
  },

  handleSubmit = e => {
    e.preventDefault();
    if (form.name.length < 3)  {setErrorMsg('El nombre es demasiado corto'); return;};
    if (form.name.length > 15) {setErrorMsg('El nombre es demasiado largo'); return;};
    if (form.category.length < 3)  {setErrorMsg('La categoría es demasiado corta'); return;};
    let sections = form.type ? form.sections : [form.sections[0]];
    axios({
      method: 'post',
      url: `/subjects/edit/${selectedSubject?._id}`, 
      headers: { 'x-auth-token': user.token },
      data: { ...form, sections }
    }).then(response => {
      if(response.status === 200) {
        update({ update: true });
        Swal.fire({
          title: 'Hecho!', 
          text: `Materia Modificada!`,
          icon: 'success',
          timer: 2000,
          showConfirmButton: false
        });
        handleClose();
      } else {
        Swal.fire({
          title: 'Hubo un error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false
        });
        throw new Error(response.data.msg);
      };
    }).catch(error => console.log('Hubo un problema con la petición: ' + error.message));
  };

  useEffect(() => {
    if (show) {
      if (selectedSubject?._id) {
        if (selectedSubject?.type) setFullType();
        else setFreeType();
        getStudents();
      } else handleClose();
    }
    // eslint-disable-next-line
  }, [show, selectedSubject]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      backdrop="static"
      centered
      className="backgroundlogin textcolor"
    >
      <div className="text-center container">
        <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
          Modificar Materia
        </Modal.Title>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <Form.Group as={Col} xs={12} lg={6} controlId="name">
                <Form.Label className="font-weight-bold">Materia</Form.Label>
                <Form.Control
                  value={form.name}
                  type="text"
                  placeholder="Nombre de la materia"
                  className="borderinput"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} lg={6}>
                <Form.Label className="font-weight-bold mr-2">Tipo: {selectedSubject?.type ? 'Completa' : 'Solo Libre'}</Form.Label>
              </Form.Group>
            </Form.Row>
            <Form.Row className="pt-3">
              <Form.Group as={Col} xs={12} lg={6} controlId="category">
                <Form.Label className="font-weight-bold">Categoría</Form.Label>
                <Form.Control
                  required
                  value={form.category}
                  type="text"
                  placeholder="Ingresa categoría"
                  className="borderinput"
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} xs={12} lg={6} controlId="description">
                <Form.Label className="font-weight-bold">Descripción</Form.Label>
                <Form.Control
                  required
                  value={form.description}
                  type="text"
                  placeholder="Ingresa descripción"
                  className="borderinput"
                  onChange={handleChange}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} xs={12}>
                <Form.Label className="font-weight-bold">Alumnos</Form.Label>
                {
                  students.length > 0 ?
                  students.map(s => <p key={s._id}>{s.name}</p>) :
                  <h4>Aún no hay alumnos registrados</h4>
                }
              </Form.Group>
            </Form.Row>
            <Form.Label className="font-weight-bold mt-3">Secciones</Form.Label>
            {
              sections.map(s =>
              <Form.Row key={s}>
                <Form.Group as={Col} xs={12}>
                  <Form.Label className="font-weight-bold">{form.sections[s].section}</Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={12}>
                  <Form.Control
                    value={form.sections[s].text}
                    type="text"
                    placeholder='Ingresá el texto. Cada "." indica un nuevo ítem en la lista'
                    className="borderinput"
                    onChange={({target}) => handleChangeText(target, s)}
                  />
                </Form.Group>
              </Form.Row>
              )
            }
            <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
              {errorMsg}
            </Alert>
            <Row className="text-center pt-4 pb-3 justify-content-center">
              <Button variant="light" type="submit" className="mr-5 shadowcards textcolor">
                <FontAwesomeIcon icon={faPlus} className="mr-2 textcolor" />Editar
              </Button>
              <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
              </Button>
            </Row>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
}