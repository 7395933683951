import React, { useReducer } from 'react';
import subjectContext from './subjectContext';
import subjectReducer from './subjectReducer';

export default function SubjectState(props) {
  const initialState = { all: [], current: JSON.parse(localStorage.getItem('campus-subject')) || '', update: true },
  [state, dispatch] = useReducer(subjectReducer, initialState),

  update = data => 
    dispatch({
      type: 'UPDATE',
      payload: data
    });

  return (
    <subjectContext.Provider
      value={{
        subject: state,
        update
      }}
    >
      { props.children }
    </subjectContext.Provider>
  );
}