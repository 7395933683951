import React, { useState, useContext } from "react";
import { faCaretRight, faFilePdf,  faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Card, Col, Image, Button } from "react-bootstrap";
import userContext from '../../context/user/userContext';
import noresult from '../Images/noresult.gif';
import Video from "../Video/Video";

export default function Slide({ files }) {
  const { user } = useContext(userContext),
  [show, setShow] = useState(false),
  [video, setVideo] = useState(''),

  handleShow = video => {
    setVideo(video);
    setShow(true);
  },

  handleClose = () => {
    setShow(false);
    setVideo('');
  },

  check_views = (id, playable_times) => {
    if (!user.content_viewed || user.content_viewed.length < 1) return true;
    let reg = user.content_viewed.find(c => c.id === id);
    if (reg) {
      console.log(`reg`, reg)
      console.log(`playable_times`, playable_times)
      if (reg.times >= (playable_times || 2)) return false;
    }
    return true;
  },

  generateContent = ({ title, link, _id: id, playable_times }) => {
    let idx = link.indexOf('video=');
    if (idx > -1) {
      let link1 = link.slice(0, idx), video = link.slice(idx + 6),
      buttons = (
        <Col xs={6} md={9} lg={9} className="d-inline-flex my-2 text-right">
          <h5 className="pl-0 d-none d-md-flex">{title}</h5>
          <FontAwesomeIcon icon={faCaretRight} className="ml-4 mr-2 mt-2" />
          <a download target="_blank" rel="noopener noreferrer" href={link1} className="textmaterialdiap">
            <FontAwesomeIcon icon={faFilePdf} className="ml-2 mt-1 fa-lg" />
          </a>
          <Button variant="link" className="textmaterialdiap ml-4 p-0 mt-0 " onClick={() => handleShow(check_views(id, playable_times) ? {video, title, id} : {video: '', title: 'Superaste la cantidad máxima de visualizaciones de este video'})}>
            <FontAwesomeIcon icon={faVideo} className="fa-lg"/>
          </Button>
        </Col>
      );
      return (
        <>
          <Row className="mt-2 text-left d-none d-md-flex">
            {buttons}
          </Row>
          <Row className="mt-2 my-2 text-left d-flex d-md-none">
            <Col xs={12}>
              <h5 className="pl-0">{title}</h5>
            </Col>
            {buttons}
          </Row>
        </>
      );
    }
    return (
      <>
        <Row className="mt-2 text-left d-none d-md-flex">
          <Col xs={6} md={9} lg={9} className="d-inline-flex my-2 text-right">
            <h5 className="pl-0 d-none d-md-flex">{title}</h5>
            <FontAwesomeIcon icon={faCaretRight} className="ml-4 mr-2 mt-2" />
            <a download target="_blank" rel="noopener noreferrer" href={link} className="textmaterialdiap">
              <FontAwesomeIcon icon={faFilePdf} className="ml-2  mt-1 fa-lg" />
            </a>
          </Col>
        </Row>
        <Row className="mt-2 my-2 text-left d-flex d-md-none">
          <Col xs={12}>
            <h5 className="pl-0">{title}</h5>
          </Col>
          <Col xs={6} md={9} lg={9} className="d-inline-flex my-2 text-right">
            <h5 className="pl-0 d-none d-md-flex">{title}</h5>
            <FontAwesomeIcon icon={faCaretRight} className="ml-4 mr-2 mt-2" />
            <a download target="_blank" rel="noopener noreferrer" href={link} className="textmaterialdiap">
              <FontAwesomeIcon icon={faFilePdf} className="ml-2  mt-1 fa-lg" />
            </a>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">Diapositivas</h4>
        </Card.Header>
        <Card.Body>
          {
            files && files.length > 0 ? files.map(file => file.is_visible &&
              <div key={file._id}>
                {generateContent(file)}
              </div>
            ) : 
            <div className="text-center" >
              <Image src={noresult} fluid alt="" className="shadow-lg rounded" />
            </div>
          }
        </Card.Body>
      </Card>
      <Video show={show} handleClose={handleClose} file={video} />
    </>
  );
}