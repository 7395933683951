import React, { useState, useEffect, useContext } from 'react';
import { Alert, Modal, Button, Form, Row, Col } from "react-bootstrap";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import Swal from 'sweetalert2';
import axios from 'axios';

export default function ModalNotification({ show, setShow, selectedNotification, setSelectedNotification }) {
  const emptyForm = { title: '', content: '', is_visible: false, order: 1 },
  [form, setForm] = useState(emptyForm),
  [errorMsg, setErrorMsg] = useState(''),
  {subject} = useContext(subjectContext),
  {user} = useContext(userContext),

  handleChange = ({target}) => setForm({...form, [target.id]: target.id === 'is_visible' ? target.checked : target.value}),

  handleClose = () => {
    setShow(false);
    setErrorMsg('');
    setForm(emptyForm);
    setSelectedNotification({_id: ''});
  },

  handleShow = () => setShow(true),

  handleSubmit = (e) => {
    e.preventDefault();
    if (form.title.length < 3) {setErrorMsg('El título es demasiado corto'); return;};
    if (form.content.length < 3) {setErrorMsg('El mensaje es demasiado corto'); return;};
    if (form.order < 1) {setErrorMsg('El orden es demasiado bajo'); return;};

    axios({
      method: 'post',
      url: `/notifications/${selectedNotification._id ? `edit/${selectedNotification._id}` : 'create'}`, 
      headers: { 'x-auth-token': user.token },
      data: { ...form, subject: subject.current }
    }).then(() => {
      Swal.fire({
        title: 'Hecho!', 
        text: `Notificación ${selectedNotification._id ? 'Modificada' : 'Creada'}!`, 
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
      });
      handleClose();
    }).catch(() => 
      Swal.fire({
        title: 'Hubo un error',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      })
    );
  };

  useEffect(() => {
    if (show) setForm(selectedNotification._id ? selectedNotification : emptyForm);
    // eslint-disable-next-line
  }, [show, selectedNotification]);

  return (
    <>
      <Button variant="light" onClick={handleShow}>
        <FontAwesomeIcon icon={faPlus} className="textcolor mr-1 fa-lg" />
        Agregar
      </Button>
      <Modal
        backdrop="static"
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className="backgroundlogin textcolor"
      >
        <div className="text-center container">
          <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
            { selectedNotification._id ? 'Modificar ' : 'Crear ' }
            Notificación
          </Modal.Title>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} xs={12} lg={6} controlId="title">
                  <Form.Label className="font-weight-bold">Título</Form.Label>
                  <Form.Control
                    value={form.title}
                    type="text"
                    placeholder="Ingresa el título"
                    className="borderinput"
                    onChange={handleChange}                   
                  />
                </Form.Group>
                <Form.Group as={Col} xs={12} lg={6} controlId="content">
                  <Form.Label className="font-weight-bold">Mensaje</Form.Label>
                  <Form.Control
                    value={form.content}
                    type="text"
                    placeholder="Ingresa el mensaje"
                    className="borderinput"
                    onChange={handleChange}                   
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row className="justify-content-center">
                <Form.Group as={Col} xs={6} controlId="order">
                  <Form.Label className="font-weight-bold">Orden</Form.Label>
                  <Form.Control
                    type="number"
                    min={1}
                    value={form.order}
                    placeholder="Ingresa el orden"
                    onChange={handleChange}
                    className="borderinput"
                  />
                </Form.Group>
                <Form.Group as={Col} xs={6} className="mt-4 pt-3 justify-content-center" controlId="is_visible">
                  <Form.Check 
                    type="switch"
                    checked={form.is_visible}
                    onChange={handleChange}
                    id="is_visible"
                    label="Visible"
                  />
                </Form.Group>
              </Form.Row>
              <Alert variant="danger" dismissible onClose={() => setErrorMsg('')} show={errorMsg !== ''}>
                {errorMsg}
              </Alert>
              <Row className="text-center pt-4 pb-3 justify-content-center">
                <Button variant="light" type="submit" className="mr-5 shadowcards textcolor">
                  <FontAwesomeIcon icon={faPlus} className="mr-2 textcolor" />{ selectedNotification._id ? 'Editar' : 'Agregar' }
                </Button>
                <Button variant="light" className="text-danger shadowcards" onClick={handleClose}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />Cerrar
                </Button>
              </Row>
            </Form>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

        // <div className="text-center container">
        //   <Modal.Title className="p-3 borderTitleLogIn font-weight-bold">
        //     Crear Noticia
        //   </Modal.Title>
        //   <Modal.Body>
        //     <Form>
        //       <Form.Row>
        //         <Form.Group as={Col} xs={12} controlId="title">
        //           <Form.Label className="font-weight-bold">Título</Form.Label>
        //           <Form.Control
        //             type="text"
        //             placeholder="Ingresa el título"
        //             className="borderinput"
        //           />
        //         </Form.Group>
        //       </Form.Row>
        //       <Form.Row>
        //         <Form.Group as={Col} xs={12} controlId="link">
        //           <Form.Label className="font-weight-bold">Mensaje</Form.Label>
        //           <Form.Control
        //             type="text"
        //             placeholder="Ingresa el mensaje"
        //             className="borderinput"
        //           />
        //         </Form.Group>
        //       </Form.Row>
        //       <Form.Row className="my-3 justify-content-center">
        //         <Form.Check type="switch" id="is_visible" label="Visible" />
        //       </Form.Row>
        //       <Alert variant="danger" dismissible></Alert>
        //       <Row className="text-center pt-4 pb-3 justify-content-center">
        //         <Button
        //           variant="light"
        //           type="submit"
        //           className="mr-5 shadowcards textcolor"
        //         >
        //           <FontAwesomeIcon icon={faPlus} className="mr-2 textcolor" />{" "}
        //           Agregar
        //         </Button>
        //         <Button
        //           variant="light"
        //           className="text-danger shadowcards"
        //           onClick={handleClose}
        //         >
        //           <FontAwesomeIcon icon={faTimes} className="mr-2" />
        //           Cerrar
        //         </Button>
        //       </Row>
        //     </Form>
        //   </Modal.Body>
        // </div>