import React, { useState, useEffect, useContext } from "react";
import { faLaughBeam, faSmileBeam, faSmile, faFrown, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, ProgressBar, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import { useParams } from "react-router-dom";
import Loading from '../Loading/Loading';
import axios from 'axios';

export default function TestResult() {
  const { user } = useContext(userContext),
  [result, setResult] = useState({ results: [], test: { title: '' }}),
  [loading, setLoading] = useState(true),
  { id } = useParams(),
  medium = 7, low = 4.5,

  getResult = () => {
    axios({
      method: 'get',
      url: `/tests/results/${id}`, 
      headers: { 'x-auth-token': user.token }
    }).then(response => {
      setResult(response.data);
      setLoading(false);
    }).catch(() => {
      setLoading(true);
      getResult();
    });
  };

  useEffect(() => {
    if (user && user.token && user.type === 1) getResult();
    // eslint-disable-next-line
  }, [user]);

  return (
    <>
      {
        loading ? <Loading /> :
        <>
          <div className="bghistory py-5">
          <Container className="py-5">
            <Row className="d-flex  justify-content-center">
              <Col xs={12} >
                <div className="px-5 py-1 text-center bg-white borderexamhistory mt-2">
                  <h1 className="textcolor"> Resultado del Examen</h1>
                </div>
              </Col>
            </Row>
                  
          </Container>
          </div>
          <Container className="mt-2 py-5 heightcontainer">
            <h1 className="textcolor text-center py-4">{result[0] && result[0].test}</h1>
            {
              result && result.length > 0 ?
              result.map(h =>
              <div key={h._id}>
                <Card className="pt-3 pb-2 px-3 my-2 text-center shadowcards" >
                  
                  <Row>
                    <Col xs={8} md={3} className="mt-1 mt-md-2 mt-lg-1">
                      <h5 className="textcolor font-weight-bold">{`${h.user.name} ${h.user.surname}`}</h5>
                    </Col>
                    <Col xs={4} md={3} className="mt-0 mt-md-3 mt-lg-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success mr-2 mt-1 mt-1 fa-lg" />
                    </Col>
                    <Col xs={8} md={4} className="mt-4 mt-lg-1 text-right">
                      <Row className="justify-content-center">
                        <Col xs={6} className="text-right">
                          <ProgressBar
                            variant={h.result <= low ? 'danger' : h.result <= medium ? 'warning' : 'success'}
                            className="mt-1"
                            animated
                            now={h.result*10}
                          />
                        </Col>
                        <Col xs={3} className="text-left">
                          <p className={`${h.result <= low ? 'text-danger' : h.result <= medium ? 'text-warning' : h.result < 9 ? 'text-success' : 'colorgreen'} font-weight-bold`}>{String(h.result).slice(0,3)}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={4} md={2} className="mt-3 mt-lg-1">
                      <FontAwesomeIcon
                        icon={h.result <= low ? faFrown : h.result <= medium ? faSmile : h.result < 9 ? faSmileBeam : faLaughBeam}
                        className={`${h.result <= low ? 'text-danger' : h.result <= medium ? 'text-warning' : h.result < 9 ? 'text-success' : 'colorgreen'} mr-2 fa-2x`}
                      />
                    </Col>
                  </Row>
                </Card>
              </div>) :
              <h4 className="text-center textcolor px-5 py-2 mt-5 bg-white borderexamhistory">Nadie ha realizado este examen aún !!</h4>
            }
          </Container>
        </>
      }
    </>
  );
}