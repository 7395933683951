import React from 'react'
import {faPhoneAlt, faEnvelope, faUserCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import contactus from "../Images/Home/contactus.png";
import { Container, Row, Col,  Image  } from "react-bootstrap";

export default function Contact({_ref}) {
    return (
        <Container ref={_ref} className="py-2">
        <Row className="justify-content-center align-items-center">
        <Col xs={12} md={5}>
            <Image src={contactus} fluid alt="contactus"  data-aos="fade-up" data-aos-delay="200" />
          </Col>
          <Col xs={12} md={5}>
            <div className="text-center mt-3 px-5" data-aos="fade-down" data-aos-delay="200">
              <h1 className="textcolor bold">Contactanos</h1>
              <Row className="flex-column justify-content-start align-items-start px-1 px-lg-5">
                <div className="py-2">
                  <FontAwesomeIcon icon={faEnvelope} className="fa-lg textcolor mr-2" /> vmiaula@gmail.com
                </div>
                <div className="py-2">
                  <FontAwesomeIcon icon={faPhoneAlt} className="fa-lg textcolor mr-2" /> +54 3815691977
                </div>
                <div className="py-2">
                 <FontAwesomeIcon icon={faUserCog} className="fa-lg textcolor mr-2" /> sdsoftwarecontact@gmail.com
                </div>
                
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    )
}
