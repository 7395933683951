import React, { useRef } from "react";
import { faHome, faBook, faMailBulk, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navbar, Nav, Image } from "react-bootstrap";
import OurSubjects from "./OurSubjects";
import logo from "../Images/logo.png";
import LogIn from "../Header/LogIn";
import AboutUs from "./AboutUs";
import Contact from "./Contact";
import Begin from "./Begin";
import "./HomeCampus.css";

export default function HomeCampus() {
  const btn = useRef(null),
  SubjectsSectionRef = useRef(null),
  AboutUsSectionRef = useRef(null),
  ContactSectionRef = useRef(null),
  HomeSectionRef = useRef(null),
  
  scroll = section => {
    let heigth = 0;
    switch (section) {
      case "Home": heigth = HomeSectionRef?.current.offsetTop - 80; break;
      case "About": heigth = AboutUsSectionRef?.current.offsetTop - 80; break;
      case "Subjects": heigth = SubjectsSectionRef?.current.offsetTop -80; break;
      case "Contact": heigth = ContactSectionRef?.current.offsetTop - 80; break;
      default: heigth = 0;
    }
    if (window.innerWidth < 770) btn.current.click();
    window.scrollTo(0, heigth);
  };
  
  return (
    <>
      <Navbar bg="light" expand="lg" className="borderbottom" sticky="top">
        <Navbar.Brand >
          <h5 className="textcolor d-inline-flex">
            <Image src={logo} width="80" fluid className="mr-2 fa-lg mt-1" /> <span className="mt-3 mr-1">Mi</span><span className="mt-3 orange">Aula</span>
          </h5>
        </Navbar.Brand>
        <div className="d-flex d-lg-none">
          <LogIn />
        </div>
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <Nav className="ml-auto ">
            <Nav.Link onClick={() => scroll("Home")} className="bg-transparent"><FontAwesomeIcon icon={faHome} className="fa-lg mr-2"/>Home</Nav.Link>
            <Nav.Link onClick={() => scroll("About")} className="bg-transparent"><FontAwesomeIcon icon={faUserTie} className="fa-lg mr-2"/>Quiénes somos</Nav.Link>
            <Nav.Link onClick={() => scroll("Subjects")} className="bg-transparent"><FontAwesomeIcon icon={faBook} className="fa-lg mr-2"/>Nuestras Materias</Nav.Link>
            <Nav.Link onClick={() => scroll("Contact")} className="bg-transparent"><FontAwesomeIcon icon={faMailBulk} className="fa-lg mr-2"/>Contactanos</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <div className="d-none d-lg-flex">
          <LogIn />
        </div>
      </Navbar>
      <Begin _ref={HomeSectionRef}/>
      <AboutUs  _ref={AboutUsSectionRef} />
      <OurSubjects  _ref={SubjectsSectionRef}/>
      <Contact _ref={ContactSectionRef}/>
    </>
  );
}
