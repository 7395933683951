import React, { useState, useEffect, useContext } from "react";
import { faFileAlt, faPenSquare, faFilePdf, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import ExcercisesAndSolutions from "../Material/ExcercisesAndSolutions";
import { Container, Row, Col, Image, Tab, Nav } from "react-bootstrap";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userContext from '../../context/user/userContext';
import LibreMobile from "../Images/libremobile.png";
import Complement from "../Material/Complement";
import Evaluation from "../Material/Evaluation";
import noaccess from '../Images/noaccess.gif';
import Librep from "../Images/libre.png";
import Loading from '../Loading/Loading';
import Slide from "../Material/Slide";
import axios from 'axios';

export default function Free() {
  const [showNoAccess, setShowNoAccess] = useState(false),
  [showNoSubject, setShowNoSubject] = useState(false),
  { subject } = useContext(subjectContext),
  [loading, setLoading] = useState(true),
  [type, setType] = useState('slide'),
  { user } = useContext(userContext),
  [files, setFiles] = useState([]),

  getFiles = () => {
    axios({
      method: 'post',
      url: type !== 'test' ? `/files/section/free-${type}` : `/tests/section/free`,
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current }
    }).then(response => {
      if(response.status === 200) {
        setFiles(response.data);
        setLoading(false);
      } else throw new Error(response.data.msg);
    }).catch(() => {
      setLoading(true);
      setShowNoAccess(true);
      getFiles();
    });
  };

  useEffect(() => {
    setLoading(true);
    if (subject && subject.current) {
      if (user && user.token && user.plan) {
        if (user.plan.free > (type !== 'test' ? 0 : 1) && new Date(user.plan.free_down).getTime() > new Date().getTime()) {
          setShowNoAccess(false);
          getFiles(); 
        } else {
          setShowNoAccess(true);
          setLoading(false);
        }
      }
    } else {
      setShowNoSubject(true);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [user?.token, type, subject.current]);

  return (
    <>
      <Image src={Librep} fluid alt="" className="d-none d-md-block" />
      <Image src={LibreMobile} fluid alt="" className="d-block d-md-none" />
      <Container className="text-center text-lg-left py-5 ">
        <Tab.Container id="left-tabs-example" defaultActiveKey="slide">
          <Row>
            <Col xs={12} lg={3} className="mb-4 mb-md-0">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="slide" className="textblue" onClick={() => setType("slide")}>
                    <FontAwesomeIcon icon={faFilePdf} className="mr-2 mt-1 fa-lg" />
                    Diapositivas
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="excercise" onClick={() => setType("excercise")}>
                    <FontAwesomeIcon icon={faPenSquare} className="mr-2 mt-1 fa-lg" />
                    Ejercicios y Soluciones
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="complement" onClick={() => setType("complement")}>
                    <FontAwesomeIcon icon={faFileAlt} className="mr-2 mt-1 fa-lg" />
                    Complementos
                  </Nav.Link>
                </Nav.Item>
                {
                  user && user.plan?.free === 2 &&
                  <Nav.Item>
                    <Nav.Link eventKey="test" onClick={() => setType("test")}>
                      <FontAwesomeIcon icon={faCalendarCheck} className="mr-2 mt-1 fa-lg" />Evaluación
                    </Nav.Link>
                  </Nav.Item>
                }
              </Nav>
            </Col>

            <Col md={9} className="text-center border-0">
              {
                loading ? <Loading /> :
                showNoAccess ?
                <div className="text-center">
                  <Image src={noaccess} fluid alt="" className="shadow-lg rounded" />
                </div> :
                showNoSubject ?
                <div className="text-center">
                  Primero Seleccioná una materia
                </div> :
                <Tab.Content>
                  <Tab.Pane eventKey="slide">
                    {type === "slide" && <Slide files={files} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="excercise">
                    {type === "excercise" && <ExcercisesAndSolutions files={files} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="complement">
                    {type === "complement" && <Complement files={files} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="test">
                    {type === "test" && <Evaluation files={files} />}
                  </Tab.Pane>
                </Tab.Content>
              }
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}
