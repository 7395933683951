import React from "react";
import { faCaretRight, faCheckCircle, faPenAlt, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Card, Col, Image } from "react-bootstrap";
import noresult from '../Images/noresult.gif';
import { Link } from "react-router-dom";

export default function Evaluation({ files }) {
  return (
    <>
      <Card className="shadowcards">
        <Card.Header className="m-0 pb-1 fondotitle">
          <h4 className="text-white rounded-pill">Evaluaciones</h4>
        </Card.Header>
        <Card.Body>
          {
            files && files.length > 0 ? files.map(file => file.is_visible &&
              <div key={file._id}>
                <Row className="mt-2 text-left">
                  <Col xs={12} md={3} className="d-inline-flex">
                    <h5 className="ml-1 ml-md-4 pl-0">{file.title}</h5>
                  </Col>
                  <Col xs={12} md={8} className="text-left">
                    <FontAwesomeIcon icon={faCaretRight} className="ml-2 ml-md-4 mr-1 mr-md-2 mt-1" />
                    {
                      file.is_available ?
                      file.status ?
                      <>
                      Examen Rendido:
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success ml-2 mt-1 mt-1 fa-lg" />
                      <Link to={`/history`} className="textcolor text-decoration-none font-weight-bold ml-1">
                        <FontAwesomeIcon icon={faUserCircle} className="ml-2 mr-1 mt-1" />Tu Historial 
                      </Link>
                      </> :
                      <Link to={`/test/${file._id}`} className="textcolor text-decoration-none font-weight-bold">
                        <FontAwesomeIcon icon={faPenAlt} className="ml-2 mr-1 mr-md-2 mt-1" /> Rendir
                      </Link> :
                      <>
                      Examen no disponible: 
                      <Link to={`/history`} className="textcolor text-decoration-none font-weight-bold ml-1">
                        <FontAwesomeIcon icon={faUserCircle} className="mr-1 mt-1" /> Ir al historial 
                      </Link>
                      </>
                    }
                  </Col>
                </Row>
              </div>
            ) :
            <div className="text-center">
              <Image src={noresult} fluid alt="" className="shadow-lg rounded" />
            </div>
          }
        </Card.Body>
      </Card>
    </>
  );
}