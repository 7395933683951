import React, { useState, useEffect, useContext } from "react";
import { faFileAlt, faPenSquare, faFilePdf, faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Image, Button, Tab, Nav } from "react-bootstrap";
import ExcercisesAndSolutions from "../Material/ExcercisesAndSolutions";
import subjectContext from '../../context/subject/subjectContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ParcialesMobile from "../Images/parcialesmobile.png";
import userContext from '../../context/user/userContext';
import Complement from "../Material/Complement";
import Evaluation from "../Material/Evaluation";
import Parciales from "../Images/parciales.png";
import noaccess from '../Images/noaccess.gif';
import Loading from '../Loading/Loading';
import Slide from "../Material/Slide";
import axios from 'axios';
import "./Partial.css";

export default function Partial() {
  const [showNoAccess, setShowNoAccess] = useState(false),
  [showNoSubject, setShowNoSubject] = useState(false),
  [section, setSection] = useState('partial1'),
  { subject } = useContext(subjectContext),
  [loading, setLoading] = useState(true),
  [type, setType] = useState('slide'),
  { user } = useContext(userContext),
  [files, setFiles] = useState([]),

  getFiles = () => {
    axios({
      method: 'post',
      url: type !== 'test' ? `/files/section/${section}-${type}` : `/tests/section/${section}`,
      headers: { 'x-auth-token': user.token },
      data: { subject: subject.current }
    }).then(response => {
      setFiles(response.data);
      setLoading(false);
    }).catch(() => {
      setLoading(true);
      setShowNoAccess(true);
      getFiles();
    });
  };

  useEffect(() => {
    setLoading(true);
    if (subject && subject.current && subject.all.find(s => s._id === subject.current)?.type) {
      if (user && user.token && user.plan) {
        if (user.plan[section] > (type !== 'test' ? 0 : 1) && new Date(user.plan[`${section}_down`]).getTime() > new Date().getTime()) {
          setShowNoAccess(false);
          getFiles(); 
        } else {
          setShowNoAccess(true);
          setLoading(false);
        }
      }
    } else {
      setShowNoSubject(true);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [user?.token, section, type, subject.current]);
  
  return (
    <>
      <Image src={Parciales} fluid alt="banner" className="d-none d-md-block" />
      <Image src={ParcialesMobile} fluid alt="banner" className="d-block d-md-none" />
      <Container className="text-center py-5">
        <Row>
          <Col>
            <Button variant={section === 'partial1' ? "info" : "light"} size="lg" className="px-5 my-2 my-lg-0  font-weight-bold" onClick={() => setSection('partial1')}>
              Parcial 1
            </Button>
          </Col>
          <Col>
            <Button variant={section === 'partial2' ? "info" : "light"} size="lg" className="px-5  my-2 my-lg-0  font-weight-bold" onClick={() => setSection('partial2')}>
              Parcial 2
            </Button>
          </Col>
          <Col>
            <Button variant={section === 'partial3' ? "info" : "light"} size="lg" className="px-5 my-2 my-lg-0  font-weight-bold" onClick={() => setSection('partial3')}>
              Parcial 3
            </Button>
          </Col>
        </Row>
      </Container>

      <div className="borderseparetion"></div>

      <Container className="text-center text-lg-left py-5 ">
        <Tab.Container id="left-tabs-example" defaultActiveKey="slide">
          <Row>
            <Col xs={12} lg={3} className="mb-4 mb-md-0">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="slide" className="textblue" onClick={() => setType("slide")}>
                    <FontAwesomeIcon icon={faFilePdf} className="mr-2 mt-1 fa-lg" />
                    Diapositivas
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="excercise" onClick={() => setType("excercise")}>
                    <FontAwesomeIcon icon={faPenSquare} className="mr-2 mt-1 fa-lg" />
                    Ejercicios y Soluciones
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="complement" onClick={() => setType("complement")}>
                    <FontAwesomeIcon icon={faFileAlt} className="mr-2 mt-1 fa-lg" />
                    Complementos
                  </Nav.Link>
                </Nav.Item>
                {
                  user?.plan && user.plan[section] === 2 &&
                  <Nav.Item>
                    <Nav.Link eventKey="test" onClick={() => setType("test")}>
                      <FontAwesomeIcon icon={faCalendarCheck} className="mr-2 mt-1 fa-lg" />Evaluación
                    </Nav.Link>
                  </Nav.Item>
                }
              </Nav>
            </Col>

            <Col xs={12} lg={9} className=" mt-2 mt-lg-0 text-center border-0">
              {
                loading ?
                  <Loading /> :
                showNoAccess ?
                <div className="text-center">
                  <Image src={noaccess} fluid alt="" className="shadow-lg rounded" />
                </div> :
                showNoSubject ?
                <div className="text-center">
                  Primero Seleccioná una materia
                </div> :
                <Tab.Content>
                  <Tab.Pane eventKey="slide">
                    {type === "slide" && <Slide files={files} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="excercise">
                    {type === "excercise" && <ExcercisesAndSolutions files={files} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="complement">
                    {type === "complement" && <Complement files={files} />}
                  </Tab.Pane>
                  <Tab.Pane eventKey="test">
                    {type === "test" && <Evaluation files={files} />}
                  </Tab.Pane>
                </Tab.Content>
              }
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
}