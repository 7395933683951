import React, { useState } from 'react';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Card } from "react-bootstrap";

export default function Option({ options, changeAnswer, visible }) {
    const [icon, setIcon] = useState(false),
    [icon1, setIcon1] = useState(false),
    [icon2, setIcon2] = useState(false),
    [icon3, setIcon3] = useState(false),

    handleClick = (o, i) => {
        let aux = [false, false, false, false];
        aux[i] = true;
        setIcon(aux[0]);
        setIcon1(aux[1]);
        setIcon2(aux[2]);
        setIcon3(aux[3]);
        changeAnswer(o);
    };

    return (
        <div className={visible ? "" : "d-none"}>
            <Card className="pt-2 pb-1 my-2 text-center">
                <Row onClick={() => handleClick(options[0], 0)}>
                    <Col xs={2} className="mt-1">
                        <FontAwesomeIcon
                            icon={icon ? faCheckCircle : faCircle}
                            className="textcolor ml-1 fa-lg"
                        />
                    </Col>
                    <Col xs={10} className="text-left pt-1">
                        <p className="textcolor">{options[0]}</p>
                    </Col>
                </Row>
            </Card>
            <Card className="pt-2 pb-1 my-2 text-center">
                <Row onClick={() => handleClick(options[1], 1)}>
                    <Col xs={2} className="mt-1">
                        <FontAwesomeIcon
                            icon={icon1 ? faCheckCircle : faCircle}
                            className="textcolor ml-1 fa-lg"
                        />
                    </Col>
                    <Col xs={10} className="text-left pt-1">
                        <p className="textcolor">{options[1]}</p>
                    </Col>
                </Row>
            </Card>
            <Card className="pt-2 pb-1 my-2 text-center">
                <Row onClick={() => handleClick(options[2], 2)}>
                    <Col xs={2} className="mt-1">
                        <FontAwesomeIcon
                            icon={icon2 ? faCheckCircle : faCircle}
                            className="textcolor ml-1 fa-lg"
                        />
                    </Col>
                    <Col xs={10} className="text-left pt-1">
                        <p className="textcolor">{options[2]}</p>
                    </Col>
                </Row>
            </Card>
            <Card className="pt-2 pb-1 my-2 text-center">
                <Row onClick={() => handleClick(options[3], 3)}>
                    <Col xs={2} className="mt-1">
                        <FontAwesomeIcon
                            icon={icon3 ? faCheckCircle : faCircle}
                            className="textcolor ml-1 fa-lg"
                        />
                    </Col>
                    <Col xs={10} className="text-left pt-1">
                        <p className="textcolor">{options[3]}</p>
                    </Col>
                </Row>
            </Card>            
        </div>
    );
}